export const UnitsOfMeasureEnum = {
  IMPERIAL: "imperial",
  METRIC: "metric",
}

// @todo: Remove all uses of non-enum
export const IMPERIAL = "imperial"
export const METRIC = "metric"

// @todo: No longer needed as both units (imperial and metric) are now provided from the backend, then rename this file as units-of-measure
const UnitsConversionService = {
  convertDimension(value, unitFrom, unitTo) {
    if (unitFrom === unitTo) return value
    if (unitTo === UnitsOfMeasureEnum.IMPERIAL) {
      return parseFloat(value * 0.3937007874)
    }
    return parseFloat(value * 2.54)
  },
  convertWeight(value, unitFrom, unitTo) {
    if (unitFrom === unitTo) return value
    if (unitTo === UnitsOfMeasureEnum.IMPERIAL) {
      return parseFloat(value * 2.2046226218488)
    }
    return parseFloat(value * 0.45359237)
  },
}

export default UnitsConversionService
