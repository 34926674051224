// REACT
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AppConfig from "../../utils/app-config"
import AppInfo from "./components/app-info"
import AppBarMenu from "./components/app-bar-menu"
import InexBusinessLogo from "../../soe-theme/src/components/inex-business-logo"
import LanguageSelector from "../../soe-theme/src/components/language-selector"
import WebSocketService from "../../services/websocket"

import SoeAccountCircleIcon from "../../soe-theme/src/icons/SoeAccountCircleIcon"
import SoeKeyboardArrowDownIcon from "../../soe-theme/src/icons/SoeKeyboardArrowDownIcon"
import SoeConfirmationDialog from "../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"

import { createAlert } from "../../reducers/alertReducer"
import { currentAuthenticatedUser } from "../../reducers/authenticatorReducer"
import { isDev, isProd } from "../../utils/get-environment"
import { LANGUAGES, ROUTES } from "../../utils/constants"
import { useDeleteUserWebsocketMutation, useGetUserProfileQuery } from "../../services/user/slice"
import { useGetVersionCheckQuery } from "../../services/version-check/slice"
import {
  clearRates,
  updateInternationalOptions,
  updatePackages,
  updateQuote,
  updateRecipient,
  updateShipper,
  updateShippingOptions,
  updateBillingOptions,
  updateCommercialInvoice,
  updateFreightOptions,
  updateBroker,
  updateRateRequestId,
  updateBuyer,
} from "../../reducers/quotationsReducer"

// Custom style
import { SecondaryCustomButton, MuiWrapper, MainCustomButton, CustomButton, ClickableText } from "./style"
import AUTHENTICATION_STATE from "./constant"
import ImpersonateClientSelectionDrawer from "./components/impersonate-client-selection-drawer"
import ImpersonateService from "../../services/impersonate"

function InexAppBar({ setAccountConfiguration, setShowAuthenticator, ...muiBoxProps }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const customTheme = useTheme();
  const { i18n, t } = useTranslation("appBar");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [decisionConfirmationDialog, setDecisionConfirmationDialog] = useState(false);
  const [showImpersonateClientSelectionDrawer, setShowImpersonateClientSelectionDrawer] = useState(false);

  const { formState } = useSelector((state) => state.authenticator);
  const currentUser = useSelector((state) => state.user);

  useGetUserProfileQuery(undefined, { skip: !userLoggedIn || formState !== "signedIn" });
  const { data: versionCheck } = useGetVersionCheckQuery(AppConfig.app.version, { skip: !userLoggedIn || formState !== "signedIn" });
  const [deleteUserWebsocket] = useDeleteUserWebsocketMutation();

  const { freightOptions, internationalOptions, packages, shipper, shippingOptions, billingOptions, recipient, commercialInvoice, broker, buyer, quoteInformation, rates, rateRequestId } = useSelector((state) => state.quotations);

  useEffect(() => {
    dispatch(currentAuthenticatedUser()).then((response) => {
      if (response.payload) {
        setUserLoggedIn(true);
      } else {
        setUserLoggedIn(false);
      }
    });
  }, [formState, dispatch]);

  useEffect(() => {
    if (userLoggedIn && currentUser.emailAddress && !currentUser.websocket.connectionId) {
      dispatch(setShowAuthenticator(false));
      WebSocketService.init(dispatch, currentUser);
    }
  }, [userLoggedIn, currentUser, dispatch]);

  useEffect(() => {
    if (currentUser && !currentUser.termsAcceptedDate && !ImpersonateService.currentImpersonation() && currentUser.emailAddress && (!currentUser.companyId || !currentUser.company?.billing)) {
      setAccountConfiguration(true);
    } else if (currentUser && !ImpersonateService.currentImpersonation() && currentUser.companyId) {
      ImpersonateService.currentImpersonation();
    }
  }, [currentUser, setAccountConfiguration]);

  useEffect(() => {
    if (versionCheck?.updateRequired) {
      dispatch(createAlert(t("newVersion")));
    }
  }, [versionCheck, dispatch, t]);

  useEffect(() => {
    if (decisionConfirmationDialog) {
      if (rateRequestId) dispatch(updateRateRequestId(undefined));
      if (!shipper?.id || shipper.id !== currentUser?.defaultShipperId) dispatch(updateShipper(undefined));
      if (recipient) dispatch(updateRecipient(undefined));
      if (packages) dispatch(updatePackages(undefined));
      if (shippingOptions) dispatch(updateShippingOptions(undefined));
      if (billingOptions) dispatch(updateBillingOptions({ type: "myAccount" }));
      if (internationalOptions) dispatch(updateInternationalOptions(undefined));
      if (commercialInvoice) dispatch(updateCommercialInvoice(undefined));
      if (freightOptions) dispatch(updateFreightOptions(undefined));
      if (broker) dispatch(updateBroker(undefined));
      if (buyer) dispatch(updateBuyer(undefined));
      if (quoteInformation) dispatch(updateQuote(undefined));
      if (rates) dispatch(clearRates());
      setDecisionConfirmationDialog(false);
    }
  }, [decisionConfirmationDialog, dispatch, rateRequestId, shipper, currentUser, recipient, packages, shippingOptions, billingOptions, internationalOptions, commercialInvoice, freightOptions, broker, buyer, quoteInformation, rates]);

  const handleUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleAppBarMenuOnClose = () => {
    setIsMenuOpen(false);
  };

  const handleNavigateQuotation = () => {
    navigate(ROUTES.QUOTATIONS);
  };

  const handleClickShipNow = () => {
    if (location.pathname === "/quotations") {
      setShowConfirmationDialog(true);
    } else {
      setDecisionConfirmationDialog(true);
      handleNavigateQuotation();
    }
  };

  const handleClose = () => {
    setDecisionConfirmationDialog(false);
    setShowConfirmationDialog(false);
    handleNavigateQuotation();
    setAnchorEl(null);
  };

  const handleConfirm = () => {
    setDecisionConfirmationDialog(true);
    setShowConfirmationDialog(false);
    handleNavigateQuotation();
  };

  const handleNavigateSignIn = () => {
    navigate(ROUTES.LOG_IN, { replace: true });
  };

  const handleNavigateSignUp = () => {
    navigate(ROUTES.SIGN_UP, { replace: true });
  };

  return (
    <MuiWrapper {...muiBoxProps}>
      <AppBar position="static" elevation={0} sx={(theme) => ({ boxShadow: "0px 3px 6px #00000029", borderBottom: `1px solid ${theme.palette.strokeDefault.main}` })}>
        <Toolbar variant="regular" sx={(theme) => ({ marginTop: theme.utils.pxToRem(8), marginBottom: theme.utils.pxToRem(8) })}>
          <Link to="/">
            <InexBusinessLogo />
          </Link>
          {!isProd() && <AppInfo />}
          {isDev() && (
            <>
              {currentUser.emailAddress && (
                <SecondaryCustomButton onClick={() => deleteUserWebsocket(currentUser.websocket.connectionId)} variant="outlined" size="medium" sx={(theme) => ({ ml: theme.utils.pxToRem(16) })}>
                  <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
                    Delete WS
                  </Typography>
                </SecondaryCustomButton>
              )}
              <Box component="div" ml={customTheme.utils.pxToRem(16)}>
                <LanguageSelector i18n={i18n} languages={LANGUAGES} />
              </Box>
            </>
          )}
          <Box component="div" sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: customTheme.utils.pxToRem(20), marginLeft: "auto", textAlign: "right"}}>
          {formState === AUTHENTICATION_STATE.SIGNED_IN && currentUser?.emailAddress && (
            <>
              <ClickableText onClick={()=>{
                window.location.href="mailto:product@inex.ca"
              }} variant="bodyMedium200">
                {t("getSupport")}
              </ClickableText>
              <MainCustomButton onClick={() => handleClickShipNow()} variant="outlined" size="medium" disabled={!currentUser.emailAddress}>
                <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                  {t("shipNow")}
                </Typography>
              </MainCustomButton>
              <CustomButton
                onClick={handleUserMenu}
                variant="outlined"
                size="medium"
                aria-controls={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                color="inherit"
                startIcon={<SoeAccountCircleIcon color="contentActionDefault" />}
                endIcon={<SoeKeyboardArrowDownIcon color="contentNeutralPrimary" sx={(theme) => ({ ml: theme.utils.pxToRem(12), visibility: "visible" })} />}
                sx={(theme) => ({ padding: theme.utils.pxToRem(8), borderColor: theme.palette.backgroundNeutralTransparent.main, textTransform: "none" })}
              >
                <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                  {currentUser?.emailAddress}
                </Typography>
              </CustomButton>
            </>
          )}
          {formState !== AUTHENTICATION_STATE.SIGNED_IN && !currentUser.emailAddress && (
            <>
              <MainCustomButton onClick={() => handleNavigateSignUp()} variant="outlined" size="medium" disabled={currentUser?.emailAddress}>
                <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                  {t("getStarted")}
                </Typography>
              </MainCustomButton>
              <SecondaryCustomButton onClick={handleNavigateSignIn} variant="outlined" size="medium" disabled={currentUser?.emailAddress}>
                <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                  {t("connection")}
                </Typography>
              </SecondaryCustomButton>
            </>
          )}
          </Box>
          <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
            <SoeConfirmationDialogContainer>
              <SoeConfirmationDialogHeader
                title={t("confirmationDialog.title", { ns: "appBar" })}
                setShowConfirmationDialog={() => {
                  setShowConfirmationDialog(false);
                }}
              />
              <SoeConfirmationDialogContent>
                <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
                  {t("confirmationDialog.content.label")}
                </Typography>
              </SoeConfirmationDialogContent>
              <SoeConfirmationDialogActions
                buttons={[
                  {
                    action: handleClose,
                    label: t("confirmationDialog.action.cancel.label", { ns: "appBar" }),
                    variant: "outlined",
                  },
                  {
                    action: handleConfirm,
                    label: t("confirmationDialog.action.confirm.label", { ns: "appBar" }),
                    variant: "contained",
                  },
                ]}
                setDecisionConfirmationDialog={setDecisionConfirmationDialog}
              />
            </SoeConfirmationDialogContainer>
          </SoeConfirmationDialog>
          <ImpersonateClientSelectionDrawer showImpersonateClientSelectionDrawer={showImpersonateClientSelectionDrawer} setShowImpersonateClientSelectionDrawer={setShowImpersonateClientSelectionDrawer} />
          <AppBarMenu open={isMenuOpen} onClose={handleAppBarMenuOnClose} anchorEl={anchorEl} setShowImpersonateClientSelectionDrawer={setShowImpersonateClientSelectionDrawer} />
        </Toolbar>
      </AppBar>
    </MuiWrapper>
  );
}

InexAppBar.propTypes = {
  setAccountConfiguration: PropTypes.func.isRequired,
  setShowAuthenticator: PropTypes.func.isRequired,
};

export default InexAppBar;
