// REACT
import PropTypes from "prop-types"
import React from "react"

// Theme
import SoeArrowDropDownIcon from "../../../../soe-theme/src/icons/SoeArrowDropDownIcon"
import SoeArrowDropUpIcon from "../../../../soe-theme/src/icons/SoeArrowDropUpIcon"

// Custom style
import StyledSortButton from "./style"
import "./style.scss"

function SortButton({ title, value, changeSort, ...others }) {
  return (
    <StyledSortButton
      variant="contained"
      size="medium"
      // eslint-disable-next-line no-nested-ternary
      endIcon={value ? <SoeArrowDropDownIcon color="contentActionDefault" /> : value === false ? <SoeArrowDropUpIcon color="contentActionDefault" /> : undefined}
      onClick={() => changeSort(!value)}
      {...others}
    >
      {title}
    </StyledSortButton>
  )
}

SortButton.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool,
  changeSort: PropTypes.func.isRequired,
}

SortButton.defaultProps = {
  value: null,
}
export default SortButton
