// eslint-disable-next-line prefer-regex-literals
export const PATTERN_ISO_3166_ALPHA_2 = new RegExp(
  "/^A[^ABCHJKNPVY]|B[^CKPUX]|C[^BEJPQST]|D[EJKMOZ]|E[CEGHRST]|F[IJKMOR]|G[^CJKOVXZ]|H[KMNRTU]|I[DEL-OQ-T]|J[EMOP]|K[EGHIMNPRWYZ]|L[ABCIKR-VY]|M[^BIJ]|N[ACEFGILOPRUZ]|OM|P[AE-HK-NRSTWY]|QA|R[EOSUW]|S[^FPQUW]|T[^ABEIPQSUXY]|U[AGMSYZ]|V[ACEGINU]|WF|WS|YE|YT|Z[AMW]$/ix"
)

export const isCountryCodeValid = (countryCode) => {
  if (countryCode && countryCode.length === 2) return true
  return false
  // TODO: Some countries do not satisfy the regex.
  // return countryCode && PATTERN_ISO_3166_ALPHA_2.test(countryCode)
}

export const isAddressFormatsValid = (addressFormats) => {
  return !!addressFormats
}

export const isCountriesValid = (countries) => {
  return Array.isArray(countries) && !!countries[0]?.code
}

export const findCountryByCountryCode = ({ countries, countryCode }) => {
  if (!(isCountryCodeValid(countryCode) && isCountriesValid(countries))) return null
  return countries.find((country) => {
    if (country?.code === countryCode) return true
    return false
  })
}

export const getAddressFormat = ({ addressFormats, countries, countryCode, type = "edit" }) => {
  if (isAddressFormatsValid(addressFormats) && isCountriesValid(countries) && isCountryCodeValid(countryCode)) {
    const country = findCountryByCountryCode({ countryCode, countries })
    const addressFormatId = country?.addressFormat?.[type]
    if (addressFormatId && addressFormats?.[addressFormatId]) {
      return addressFormats?.[addressFormatId]
    }
  }
  return undefined
}

export const isRequiredField = ({ addressFormat, fieldName }) => {
  const currentField = addressFormat.find((field) => field.name === fieldName)
  return currentField?.validations?.isRequired ? currentField.validations.isRequired : false
}
