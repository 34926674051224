// REACT
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import EnhancedTableHead from "../enhanced-table-head"
import RateRequest from "./components/rate-request"
import getComparator from "../../utils/sorting"

import { useGetRateRequestHistoryQuery } from "./slice"

function RateRequestHistory() {
  const { t } = useTranslation("rateRequestHistory")
  const customTheme = useTheme()

  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdDate")

  const { data: rateRequestHistory, isFetching } = useGetRateRequestHistoryQuery()

  const headCells = [
    {
      id: "shipper.personName",
      disablePadding: false,
      label: t("headers.rateRequest.shipper.label"),
    },
    {
      id: "recipient.personName",
      disablePadding: false,
      label: t("headers.rateRequest.recipient.label"),
    },
    {
      id: "parts",
      disablePadding: false,
      label: t("headers.rateRequest.parts.label"),
    },
    {
      id: "totalWeight",
      disablePadding: false,
      label: t("headers.rateRequest.totalWeight.label"),
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("headers.rateRequest.createdDate.label"),
    },
    {
      id: "shippingOptions.shippingDate",
      disablePadding: false,
      label: t("headers.rateRequest.shippingDate.label"),
    },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Box component="div">
      <Card variant="outlined" square sx={{ height: "auto" }}>
        <CardHeader title={t("title")} style={{ pl: customTheme.spacing(4), pb: customTheme.spacing(2) }} />
      </Card>
      <Box sx={(theme) => ({ ml: theme.utils.pxToRem(14), mr: theme.utils.pxToRem(14), width: "auto" })}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table size="small" sx={(theme) => ({ borderCollapse: "separate", borderSpacing: "0px 10px", backgroundColor: theme.palette.backgroundNeutralUnderground1.main })}>
              <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {isFetching ? (
                  <>
                    {Array(3)
                      .fill()
                      .map((_, index) => {
                        return (
                          <TableRow key={"".concat(index)}>
                            <TableCell colSpan={12}>
                              <Skeleton variant="rectangular" height={50} width="100%" />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </>
                ) : (
                  rateRequestHistory
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((rateRequest, index) => {
                      return <RateRequest key={"".concat(index)} rateRequest={rateRequest} />
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  )
}

export default RateRequestHistory
