import restApiSlice from "../rest-api/slice"

const boxDimensionRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBoxes: builder.query({
      query: (companyId) => ({ url: `/packaging-type/${companyId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    createBox: builder.mutation({
      query: (payload) => ({ url: `/packaging-type`, method: "POST", body: payload}),
      transformResponse: (response) => response.data,
    }),
    updateBox: builder.mutation({
      query: ({ packagingTypeId, payload }) => ({ url: `/packaging-type/${packagingTypeId}`, method: "PATCH", body: payload }),
      transformResponse: (response) => response.data,
    }),
    deleteBox: builder.mutation({
      query: (companyId) => ({ url: `/packaging-type/${companyId}`, method: "DELETE" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetBoxesQuery, useCreateBoxMutation, useUpdateBoxMutation, useDeleteBoxMutation } = boxDimensionRestApiSlice

export default boxDimensionRestApiSlice.reducer
