import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import {
  Box,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { CustomSelectField } from "../../style"
import { ReactComponent as CheckIcon } from "../../../../assets/images/check.svg"
import { ReactComponent as CrossIcon } from "../../../../assets/images/cross.svg"
import { useCreateUserInvitationMutation } from "../../../../services/invitation/slice"
import {
  useUpdateOrgUserMutation,
  useDisableUserMutation,
  useEnableUserMutation
} from "../../../../services/user/slice"
import { MainCustomButton } from "../../../inex-home/style"

function UserItem({ user, inviteUserProgess, companyName, setInviteUserProgess, onRoleChange, onStatusChange }) {
  const { t, i18n } = useTranslation("company")
  const [companyRole, setCompanyRole] = useState(user.companyRole || "ADMIN")
  const [status, setStatus] = useState("Pending")
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isUserDisabled, setIsUserDisabled] = useState(user.disabled || false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const [createInvitation] = useCreateUserInvitationMutation()
  const [updateOrgUser] = useUpdateOrgUserMutation()
  const [disableUser] = useDisableUserMutation()
  const [enableUser] = useEnableUserMutation()
  const customTheme = useTheme()
  const currentUser = useSelector((state) => state.user)

  const formik = useFormik({
    initialValues: {
      email: user.emailAddress || "",
      personName: user.personName || ""
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.email) {
        errors.email = t("form.email.error.required")
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
      ) {
        errors.email = t("form.email.error.invalid")
      }
      if (!formValues.personName) {
        errors.personName = t("form.personName.error.required")
      }
      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async () => {
      const { email, personName } = formik.values
      createInvitation({ companyName, email, personName, status: "sent" })
      setInviteUserProgess("")
      setTimeout(() => setStatus("sent"), 300)
    }
  })

  const handleCompanyRoleChange = async (event) => {
    const newRole = event.target.value
    setCompanyRole(newRole)
    await updateOrgUser({
      companyRole: newRole,
      companyUser: { userId: user.id },
    })
    onRoleChange(user.pk, newRole) // notify parent component of the role change
  }

  const handleEnableDisableUser = () => {
    setDialogOpen(true)
  }

  const handleCancelDialogAction = () => {
    setDialogOpen(false)
    setButtonClicked(false)
  }

  const confirmEnableDisableUser = async () => {
    setDialogOpen(false)
    if (isUserDisabled) {
      await enableUser({ userId: user.id })
      setIsUserDisabled(false)
      onStatusChange(t("snackMessage.roleChangeSuccess"))
    } else {
      await disableUser({ userId: user.id })
      setIsUserDisabled(true)
      onStatusChange(t("snackMessage.roleChangeSuccess"))
    }
    setButtonClicked(false) // reset the button click state after action
  }

  const toLocaleDateString = (date) => {
    const newDate = date ?? new Date()
    return new Date(newDate).toLocaleDateString(i18n.language, {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  return (
    <>
      <TableRow
        sx={(theme) => ({
          backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
        })}
      >
        <TableCell component="td" scope="row">
          {inviteUserProgess === "show_input" ? (
            <TextField
              style={{ width: "inherit" }}
              id="personName"
              inputProps={{
                style: {
                  fontSize: customTheme.utils.pxToRem(16),
                  fontWeight: customTheme.typography.fontWeightRegular,
                  lineHeight: customTheme.utils.pxToRem(24),
                },
              }}
              label={t("form.personName.label")}
              variant="outlined"
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.personName || ""}
              error={
                formik.touched.personName &&
                formik.errors.personName !== undefined
              }
              helperText={
                (formik.touched.personName && formik.errors.personName) || " "
              }
              required
              autoFocus
            />
          ) : (
            user.personName || formik.values.personName || "-"
          )}
        </TableCell>
        <TableCell component="td" scope="row">
          {inviteUserProgess === "show_input" ? (
            <TextField
              style={{ width: "inherit" }}
              id="email"
              inputProps={{
                style: {
                  fontSize: customTheme.utils.pxToRem(16),
                  fontWeight: customTheme.typography.fontWeightRegular,
                  lineHeight: customTheme.utils.pxToRem(24),
                },
              }}
              label={t("form.email.label")}
              variant="outlined"
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email || ""}
              error={
                formik.touched.email && formik.errors.email !== undefined
              }
              helperText={
                (formik.touched.email && formik.errors.email) || " "
              }
              required
              autoFocus
            />
          ) : (
            user.emailAddress || formik.values.email || "-"
          )}
        </TableCell>
        <TableCell component="td" scope="row">
          <CustomSelectField
            id="demo-simple-select"
            value={companyRole}
            label="companyRole"
            onChange={handleCompanyRoleChange}
            disabled={user.id === currentUser.id} // disable select if it's the current user
          >
            <MenuItem value="ADMIN">Admin</MenuItem>
            <MenuItem value="SHIP">Shipping User</MenuItem>
            <MenuItem value="BILL">Billing User</MenuItem>
          </CustomSelectField>
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">
            {toLocaleDateString(user.modifiedDate || user.createdDate)}
          </Box>
        </TableCell>
        <TableCell component="td" scope="row">
          {currentUser?.id !== user.id && (
            <MainCustomButton 
              onClick={() => {
                if (!buttonClicked) {
                  setButtonClicked(true)
                  handleEnableDisableUser()
                }
              }} 
              variant="outlined" 
              size="medium" 
              style={{ marginBottom: "10px", marginTop: "10px", width: "auto" }}
              disabled={buttonClicked}
            >
              <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                {isUserDisabled ? t("statusButton.enableUser") : t("statusButton.disableUser")}
              </Typography>
            </MainCustomButton>
          )}
        </TableCell>
      </TableRow>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{isUserDisabled ? t("dialog.enableTitle") : t("dialog.disableTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isUserDisabled ? t("dialog.confirmEnableUser") : t("dialog.confirmDisableUser")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip title={t("confirm")} placement="top">
            <span>
              <IconButton onClick={confirmEnableDisableUser} color="primary">
                <CheckIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t("cancel")} placement="top">
            <span>
              <IconButton onClick={handleCancelDialogAction} color="primary">
                <CrossIcon />
              </IconButton>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  )
}

UserItem.defaultProps = {
  user: {},
  inviteUserProgess: "",
  companyName: "",
  setInviteUserProgess: () => {}
}

UserItem.propTypes = {
  user: PropTypes.shape({
    pk: PropTypes.string,
    sk: PropTypes.string,
    emailAddress: PropTypes.string,
    personName: PropTypes.string,
    companyRole: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedDate: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  inviteUserProgess: PropTypes.string,
  companyName: PropTypes.string,
  setInviteUserProgess: PropTypes.func,
  onRoleChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
}

export default UserItem
