/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
// React
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"

// MUI
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import InputAdornment from "@mui/material/InputAdornment"
import Tooltip from "@mui/material/Tooltip"

// Formik
import { useFormik } from "formik"

// Custom
import { CustomTextField, CustomSelectField, MainCustomButton } from "./style"

import companyIcon from "../../../../assets/images/company.svg"
import emailIcon from "../../../../assets/images/envelope.svg"

// Other

import { useEditCompanyProfileMutation } from "../../../../services/company/slice"

function CompanyDetails({ companyId }) {
  const { t } = useTranslation("company")
  const company = useSelector((state) => state.company)
  const [editCompany] = useEditCompanyProfileMutation()

  const formik = useFormik({
    initialValues: {
      companyName: company?.companyName || "",
      allowedDomain: company?.allowedDomain || "",
    },
    validate: (formValues) => {
      const errors = {}
      const domainsTest = /^[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9].[a-z]{2,3}(.[a-z]{2,3})?(.[a-z]{2,3})?$/i
      if (!domainsTest.test(formValues.allowedDomain) && formValues.allowedDomain !== "") {
        errors.allowedDomain = t("companyDetails.fields.allowedDomain.error.invalid")
      }
      if (!formValues.companyName) {
        errors.companyName = t("companyDetails.fields.companyName.error.required")
      }

      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (formValues, submitProps) => {
      const updateValues = {}
      if (company.companyName !== formValues.companyName?.trim()) updateValues.companyName = formValues.companyName?.trim()
      if (company.allowedDomain !== formValues.allowedDomain?.trim()) updateValues.allowedDomain = formValues.allowedDomain?.trim()

      if ((updateValues.companyName && updateValues.companyName?.trim() !== "") || updateValues.allowedDomain || updateValues.allowedDomain === "") {
        editCompany({ ...updateValues, companyId }).then((res) => {
          submitProps.resetForm({
            values: {
              companyName: res.data?.companyName,
              allowedDomain: res.data?.allowedDomain || "",
            },
          })
        })
      }
    },
  })

  const handleFormikValuesChange = () => {
    formik.setFieldValue("companyName", company?.companyName)
    formik.setFieldValue("allowedDomain", company?.allowedDomain)
  }

  useEffect(() => {
    handleFormikValuesChange()
  }, [company])

  return (
    <Box sx={(theme) => ({ width: "60%" })}>
      <Typography sx={(theme) => ({ fontWeight: "600", color: theme.palette.inexTertiary.main, fontSize: theme.utils.pxToRem(22), fontFamily: "Poppins" })}>{t("companyDetails.title")}</Typography>
      <Box sx={(theme) => ({ gap: theme.utils.pxToRem(20), display: "flex", height: theme.utils.pxToRem(55), marginBottom: theme.utils.pxToRem(15) })}>
        <CustomTextField
          id="companyName"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={t("companyDetails.fields.companyName.tooltip")}>
                  <img src={companyIcon} alt="company-icon" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          placeholder={t("companyDetails.fields.companyName.label")}
          variant="outlined"
          size="small"
          onChange={formik.handleChange}
          value={formik.values.companyName}
          error={formik.touched.companyName && formik.errors.companyName !== undefined}
          helperText={(formik.touched.companyName && formik.errors.companyName) || " "}
          required
          autoFocus
        />
        <CustomSelectField id="companyName" value={1} onChange={formik.handleChange} error={formik.touched.companyName && formik.errors.companyName !== undefined} disabled>
          <MenuItem value={1}>Admin</MenuItem>
        </CustomSelectField>
      </Box>
      <CustomTextField
        id="allowedDomain"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={emailIcon} alt="allowedDomain-icon" />
            </InputAdornment>
          ),
        }}
        placeholder={t("companyDetails.fields.allowedDomain.label")}
        variant="outlined"
        size="small"
        onChange={formik.handleChange}
        value={formik.values.allowedDomain}
        error={formik.touched.allowedDomain && formik.errors.allowedDomain !== undefined}
        helperText={(formik.touched.allowedDomain && formik.errors.allowedDomain) || " "}
        autoFocus
      />
      <Typography component="h2" sx={(theme) => ({ marginBottom: theme.utils.pxToRem(10), lineHeight: "20px", color: theme.palette.inexTertiary.main, fontSize: theme.utils.pxToRem(16), fontFamily: "Poppins" })}>
        {t("companyDetails.subtitle")}
      </Typography>
      <MainCustomButton onClick={formik.submitForm} type="submit" variant="outlined" size="medium" disabled={company.companyName === formik.values?.companyName?.trim() && company.allowedDomain === formik.values?.allowedDomain?.trim()}>
        <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
          {t("companyDetails.fields.saveButton.label")}
        </Typography>
      </MainCustomButton>
    </Box>
  )
}

CompanyDetails.propTypes = {
  companyId: PropTypes.string,
}

CompanyDetails.defaultProps = {
  companyId: "",
}

export default CompanyDetails
