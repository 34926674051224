// React
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

// Stripe
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"

// Mui
import Card from "@mui/material/Card"
import Skeleton from "@mui/material/Skeleton"
import { CustomCardContent, MuiWrapper } from "./style"

// others
import AppConfig from "../../../../../../../../utils/app-config"
import PaymentElementForm from "./components/payment-element"

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.

function Stripe({ paymentIntent, ...muiBoxProps }) {
  const [stripePromise] = useState(() => loadStripe(AppConfig.stripe.publicKey))
  const { i18n } = useTranslation()
  const theme = useTheme()

  const [options, setOptions] = useState()

  useEffect(() => {
    if (i18n?.resolvedLanguage && paymentIntent?.client_secret && theme?.stripe && theme.stripe.appearance) {
      setOptions({
        locale: i18n.resolvedLanguage,
        appearance: theme.stripe.appearance,
        clientSecret: paymentIntent.client_secret,
      })
    }
  }, [paymentIntent])

  // @link: https://stripe.com/docs/stripe-js/react#element-components // Options param for creating the Payment Element. // @link: https://stripe.com/docs/js/elements_object/create_payment_element#payment_element_create-options
  return (
    <MuiWrapper {...muiBoxProps}>
      <Card variant="outlined" sx={{ width: "100%", mb: 2 }}>
        <CustomCardContent>
          {stripePromise && options ? (
            <Elements stripe={stripePromise} options={options}>
              <PaymentElementForm paymentIntent={paymentIntent} />
            </Elements>
          ) : (
            <Skeleton variant="rectangular" width="100%" height={294} />
          )}
        </CustomCardContent>
      </Card>
    </MuiWrapper>
  )
}

Stripe.propTypes = {
  paymentIntent: PropTypes.shape({
    client_secret: PropTypes.string,
    metadata: PropTypes.shape({}),
  }).isRequired,
}

export default Stripe
