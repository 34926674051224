// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"

// Custom
import CardContentNoPaddingBottom from "../../../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import DateTimeService from "../../../../../../services/date-time"

function TrackingCard({ trackingData }) {
  const { t } = useTranslation("shipments")

  return (
    <Card
      sx={(theme) => ({
        mb: theme.spacing(1),
      })}
    >
      <CardContentNoPaddingBottom
        sx={(theme) => ({
          "&:last-child": {
            pt: 0,
            my: theme.utils.pxToRem(12),
          },
        })}
      >
        <Box>
          {trackingData?.status && (
            <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("trackingDrawer.trackingCard.status.label")}: <b>{t(`trackingDrawer.trackingCard.status.${trackingData.status}`)}</b>
            </Typography>
          )}
        </Box>
        <Box>
          {trackingData?.signedBy && (
            <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("trackingDrawer.trackingCard.signedBy")}: <b>{trackingData.signedBy}</b>
            </Typography>
          )}
        </Box>
        <Box>
          {trackingData?.lastUpdate && (
            <Typography sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })} variant="bodySmall400" gutterBottom>
              {t("trackingDrawer.trackingCard.lastUpdate")}: <b>{DateTimeService.toLocaleDateString(trackingData.lastUpdate)}</b> <b>{DateTimeService.toLocaleTimeString(trackingData.lastUpdate)}</b>
            </Typography>
          )}
        </Box>
      </CardContentNoPaddingBottom>
    </Card>
  )
}

TrackingCard.propTypes = {
  trackingData: PropTypes.instanceOf(Object).isRequired,
}

export default TrackingCard
