import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createdDate: null,
  defaultLanguage: null,
  emailAddress: null,
  entityType: null,
  isAdmin: false,
  modifiedDate: null,
  personName: null,
  pricingGroupCode: null,
  pk: null,
  sk: null,
  termsAcceptedDate: null,
  websocket: {
    connectionId: null,
    previousConnectionId: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setInitialState: () => initialState,
    fetchUserDetailsSuccess: (state, action) => {
      return { ...initialState, ...action.payload };
    },
    updateUserDetailsSuccess: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setInitialState, updateUserDetailsSuccess, fetchUserDetailsSuccess } = userSlice.actions;

export default userSlice.reducer;
