import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import FormHelperText from "@mui/material/FormHelperText"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Select as MuiSelect } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import TranslationService from "../../../../../../../../../../services/translation"

function Select({ formik, fieldFormat }) {
  const { i18n } = useTranslation()
  const theme = useTheme()

  const SPACING = 10
  const size = fieldFormat?.grid || 12
  const label = fieldFormat?.label && TranslationService.getLabelFromLocale(i18n.language, fieldFormat.label)
  const name = fieldFormat?.name

  useEffect(() => {
    if (formik.values.additionalFields?.[name] === undefined) formik.setFieldValue(`additionalFields.${name}`, fieldFormat?.form?.options?.[0]?.value)
  }, [formik.values.additionalFields]?.[name])

  return (
    <Grid item xs={12} md={size} my={theme.utils.pxToThemeSpacing(SPACING)}>
      <FormControl fullWidth>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <MuiSelect
          name={name}
          labelId={`${name}-label`}
          value={formik.values.additionalFields?.[name] || fieldFormat?.form?.options?.[0]?.value}
          error={formik.touched.additionalFields?.[name] && formik.errors[name] !== undefined}
          variant="outlined"
          label={label}
          onChange={(event) => formik.setFieldValue(`additionalFields.${name}`, event.target.value)}
          size="small"
        >
          {fieldFormat.form?.options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {TranslationService.getLabelFromLocale(i18n.language, option.label)}
            </MenuItem>
          ))}
        </MuiSelect>
        <FormHelperText>{(formik.touched.additionalFields?.[name] && formik.errors[name]) || ""}</FormHelperText>
      </FormControl>
    </Grid>
  )
}

Select.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  fieldFormat: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string.isRequired,
      fr: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
    grid: PropTypes.number.isRequired,
    form: PropTypes.shape({
      attributes: PropTypes.shape({
        inputType: PropTypes.string,
        maxlength: PropTypes.string,
      }),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.instanceOf(Object),
        })
      ),
    }),
    validations: PropTypes.shape({
      isRequired: PropTypes.bool,
      maxLength: PropTypes.number,
      minLength: PropTypes.number,
      regex: PropTypes.shape({
        error: PropTypes.shape({
          code: PropTypes.string,
          messages: PropTypes.shape({
            en: PropTypes.string.isRequired,
            fr: PropTypes.string,
          }),
        }),
        test: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default Select
