// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"

function GeneralInfoPasswordCard({ handleGeneralInfoPasswordCardClick }) {
  const { t } = useTranslation("userProfile")

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return <UserProfileSummaryCard title={t("userProfile.generalInfoPasswordCard.changePassword.title")} handleClick={handleGeneralInfoPasswordCardClick} summaryStatusProps={summaryStatusProps} />
}

GeneralInfoPasswordCard.propTypes = {
  handleGeneralInfoPasswordCardClick: PropTypes.func,
}

GeneralInfoPasswordCard.defaultProps = {
  handleGeneralInfoPasswordCardClick: () => {},
}

export default GeneralInfoPasswordCard
