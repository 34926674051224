import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

export const MuiWrapper = styled(Box, { name: "SoeAppBar", slot: "Root" })(() => ({
  flexGrow: 1,
}))

export const MainCustomButton = styled(Button, { label: "MainCustomButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: theme.palette.inexPrimary.main,
  color: "white",
  textTransform: "uppercase",
  boxShadow: "0px 3px 6px #00000029",
  borderColor: theme.palette.inexPrimary.main,
  width: "150px",
  fontWeight: "500",
  height: "47.2px",
  "&:hover": {
    backgroundColor: theme.palette.inexSecondary.main,
    borderColor: theme.palette.inexSecondary.main,
    color: "white",
  },
}))

export const ClickableText = styled(Typography, { label: "ClickableText", slot: "Root" })(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "400",
  color: "gray",
  alignItems: "center",
  "&:hover": {
    textDecoration: "underline",
    cursor: "pointer"
  },
}))

export const SecondaryCustomButton = styled(Button, { label: "SecondaryCustomButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.inexPrimary.main,
  textTransform: "uppercase",
  boxShadow: "0px 3px 6px #00000029",
  borderColor: theme.palette.inexPrimary.main,
  width: "150px",
  fontFamily: "Poppins",
  height: "47.2px",
  "&:hover": {
    backgroundColor: "white",
    borderColor: "white",
    color: theme.palette.inexSecondary.main,
  },
}))

export const CustomButton = styled(Button, { label: "CustomButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: "success",
  fontFamily: "Poppins",
  fontWeight: "600",
  color: "#001F22",
  "& .MuiButton-startIcon": {
    "& .MuiSvgIcon-root": {
      fontSize: theme.utils.pxToRem(24),
      color: theme.palette.inexPrimary.main,
    },
  },
  "&:hover": {
    backgroundColor: "white",
    borderColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    color: "#001F22",
    "& .MuiButton-startIcon": {
      "& .MuiSvgIcon-root": {
        fontSize: theme.utils.pxToRem(24),
        color: theme.palette.inexPrimary.main,
      },
    },
  },
}))
