// REACT
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"

// MUI
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Tooltip from "@mui/material/Tooltip"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import ModalAlert from "../../../modal/components/modal-alert"
import { SecondaryCustomButton, MainCustomButton, CustomTextField, CustomModalContainerBox } from "./style"
import ConfirmSignUp from "../confirm-sign-up"
import CompanyExists from "../company-exists"

import { signUp } from "../../../../reducers/authenticatorReducer"

import emailIcon from "../../../../assets/images/envelope.svg"
import passwordIcon from "../../../../assets/images/padlock.svg"
import companyIcon from "../../../../assets/images/company.svg"
import personNameIcon from "../../../../assets/images/personName.svg"
import passwordEyeIcon from "../../../../assets/images/password-eye.svg"

import AppConfig from "../../../../utils/app-config"

function SignUp() {
  const [isCompanyExistsDialogOpen, setIsCompanyExistsDialogOpen] = useState(false)
  const [isDomainMatch, setIsDomainMatch] = useState(false)
  const customTheme = useTheme()
  const { t } = useTranslation("authenticator")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [passwordFieldType, setPasswordFieldType] = useState("password")
  const [confPasswordFieldType, setConfPasswordFieldType] = useState("password")

  const handleEyeClick = (field, setField) => {
    if (field === "password") {
      setField("text")
    } else {
      setField("password")
    }
  }

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false)
  }

  const handleOpenConfirmDialog = () => {
    setIsConfirmDialogOpen(true)
  }

  const handleTermsAndConditionsLinkClick = () => {
    return window.open("https://inex.ca/terms/", "_blank") || window.location.replace("https://inex.ca/terms/")
  }

  const handlePrivacyPolicyLinkClick = () => {
    return window.open("https://inex.ca/privacy-policy/", "_blank") || window.location.replace("https://inex.ca/privacy-policy/")
  }

  const handleCloseCompanyExistDialog = () => {
    setIsCompanyExistsDialogOpen(false)
    setIsDomainMatch(false)
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      companyName: "",
      personName: "",
      password: "",
      confirmPassword: "",
      termsConditionsChecked: false,
      recaptchaCheck: false,
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.email) {
        errors.email = t("inexSignUp.fields.email.error.required")
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = t("inexSignUp.fields.email.error.invalid")
      }
      if (!formValues.password) {
        errors.password = t("inexSignUp.fields.password.error.required")
      }
      if (!formValues.confirmPassword) {
        errors.confirmPassword = t("inexSignUp.fields.confirmPassword.error.required")
      }
      if (formValues.password !== formValues.confirmPassword) {
        errors.confirmPassword = t("inexSignUp.fields.confirmPassword.error.passwordMatch")
      }
      if (!formValues.companyName) {
        errors.companyName = t("inexSignUp.fields.companyName.error.required")
      }
      if (!formValues.termsConditionsChecked) {
        errors.termsConditionsChecked = t("inexSignUp.fields.password.error.required")
      }
      if (!formValues.recaptchaCheck) {
        errors.recaptchaCheck = t("inexSignUp.fields.recaptcha.error.required")
      }
      if (!formValues.personName) {
        errors.personName = t("inexSignUp.fields.personName.error.required")
      }

      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(signUp(formValues)).then((response) => {
        if (!response.error) handleOpenConfirmDialog()
        if (response.error?.message?.includes("Organization with this name already exists")) {
          setIsCompanyExistsDialogOpen(true)
        } else if (response.error?.message?.includes("Organization with this domain already exists")) {
          setIsCompanyExistsDialogOpen(true)
          setIsDomainMatch(true)
        }
      })
    },
  })

  return (
    <Box className="signUp" component="div" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(425) })}>
          <ModalAlert />
          <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <CustomTextField
                id="companyName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title={t("inexSignUp.fields.companyName.tooltip")}>
                        <img src={companyIcon} alt="company-icon" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignUp.fields.companyName.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                error={formik.touched.companyName && formik.errors.companyName !== undefined}
                helperText={(formik.touched.companyName && formik.errors.companyName) || " "}
                required
                autoFocus
              />
              <CustomTextField
                id="personName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={personNameIcon} alt="person-name-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignUp.fields.personName.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.personName}
                error={formik.touched.personName && formik.errors.personName !== undefined}
                helperText={(formik.touched.personName && formik.errors.personName) || " "}
                required
                autoFocus
              />
              <CustomTextField
                id="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={emailIcon} alt="email-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignUp.fields.email.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email !== undefined}
                helperText={(formik.touched.email && formik.errors.email) || " "}
                required
                autoFocus
              />
              <CustomTextField
                id="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => handleEyeClick(passwordFieldType, setPasswordFieldType)}>
                      <img src={passwordEyeIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignUp.fields.password.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password !== undefined}
                helperText={(formik.touched.password && formik.errors.password) || " "}
                fullWidth
                required
                type={passwordFieldType}
              />
              <CustomTextField
                id="confirmPassword"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => handleEyeClick(confPasswordFieldType, setConfPasswordFieldType)}>
                      <img src={passwordEyeIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignUp.fields.confirmPassword.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={formik.touched.confirmPassword && formik.errors.confirmPassword !== undefined}
                helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || " "}
                fullWidth
                required
                color={customTheme.palette.inexTertiary.main}
                type={confPasswordFieldType}
              />
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.termsConditionsChecked}
                      onChange={() => formik.setFieldValue("termsConditionsChecked", !formik.values.termsConditionsChecked)}
                      sx={{ color: customTheme.palette.inexTertiary.lighter, "&.Mui-checked": { color: customTheme.palette.inexPrimary.main } }}
                    />
                  }
                  label={
                    <Box style={{ color: customTheme.palette.inexTertiary.main, fontFamily: "Poppins", fontSize: customTheme.utils.pxToRem(18) }}>
                      {t("inexSignUp.confirmationText")}
                      <Link onClick={handleTermsAndConditionsLinkClick} style={{ color: customTheme.palette.inexPrimary.main }}>
                        {t("inexSignUp.termsAndConditions")}
                      </Link>
                      {t("inexSignUp.and")}
                      <Link onClick={handlePrivacyPolicyLinkClick} style={{ color: customTheme.palette.inexPrimary.main }}>
                        {t("inexSignUp.privacyPolicy")}
                      </Link>
                      .
                    </Box>
                  }
                />
                {formik.touched.termsConditionsChecked && formik.errors.termsConditionsChecked !== undefined && <FormHelperText sx={{ color: "#FF5252" }}>{t("inexSignUp.termsAndConditionsError")}</FormHelperText>}
              </Box>
              <CardContent sx={{ p: customTheme.utils.pxToRem(20) }} />
              <div className="captcha" style={{ transform: "scale(1.31)", transformOrigin: "0 0" }}>
                <ReCAPTCHA sitekey={AppConfig.recaptcha.siteId} onChange={(value) => formik.setFieldValue("recaptchaCheck", !!value)} />
              </div>
              <CardContent sx={{ p: customTheme.utils.pxToRem(10) }} />
              {formik.touched.recaptchaCheck && formik.errors.recaptchaCheck !== undefined && <FormHelperText sx={{ color: "#FF5252" }}>{formik.errors.recaptchaCheck}</FormHelperText>}
              <CardContent sx={{ p: customTheme.utils.pxToRem(20) }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <MainCustomButton type="submit" variant="outlined" size="medium" disabled={!formik.values.termsConditionsChecked || !formik.values.recaptchaCheck}>
                  <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                    {t("inexSignUp.fields.signUpButton.label")}
                  </Typography>
                </MainCustomButton>
                <Divider orientation="vertical" style={{ height: "0px", margin: `0 ${customTheme.utils.pxToRem(10)} 0 ${customTheme.utils.pxToRem(10)}` }} />
                <SecondaryCustomButton onClick={() => navigate("/log-in", { replace: true })} variant="outlined" size="medium">
                  <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                    {t("inexSignUp.fields.signInButton.label")}
                  </Typography>
                </SecondaryCustomButton>
              </Box>
              <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(16) })} />
            </form>
          </CardContentNoPaddingBottom>
        </Box>
      </Grid>

      <Modal open={isConfirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <CustomModalContainerBox className="authContainer" component="div">
          <ConfirmSignUp isConfirmDialogOpen={isConfirmDialogOpen} />
        </CustomModalContainerBox>
      </Modal>

      <Modal
        open={isCompanyExistsDialogOpen}
        onClose={() => {
          setIsCompanyExistsDialogOpen(false)
          setIsDomainMatch(false)
        }}
      >
        <CustomModalContainerBox className="authContainer" component="div">
          <CompanyExists handleCloseCompanyExistDialog={handleCloseCompanyExistDialog} isDomainMatch={isDomainMatch} values={formik.values} />
        </CustomModalContainerBox>
      </Modal>
    </Box>
  )
}

export default SignUp
