// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import CardContent from "@mui/material/CardContent"
import TextField from "@mui/material/TextField"
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"
import BoxCard from './components/box-card'
import { useGetBoxesQuery, useCreateBoxMutation, useUpdateBoxMutation, useDeleteBoxMutation } from "../../../../../../services/box-dimension/slice"

function BoxDimensionDrawer({ onSubmitEvent, setShowBoxDimensionDrawer }) {
  const { t } = useTranslation("userProfile")
  const currentCompany = useSelector((state) => state.user?.company)
  const customTheme = useTheme()
  const SPACING = 10

  const [createBox] = useCreateBoxMutation()
  const [updateBox] = useUpdateBoxMutation()
  const [deleteBox] = useDeleteBoxMutation()
  const { data: boxes, refetch: refetchBoxes, isFetching } = useGetBoxesQuery(currentCompany.id, {
    skip: !currentCompany.id, // Skip the query if companyId is not available
  })
  const [editBox, setEditBox] = useState(null)
  
  let customBoxes = [];
  if(boxes) {
    customBoxes = boxes.filter(item => item.companyId !== null && item.companyId !== undefined);
  }

  const formik = useFormik({
    initialValues: {
      height: editBox?.height || "",
      width: editBox?.width || "",
      length: editBox?.length || "",
      name: editBox?.name || "",
      weight: editBox?.weight || "",
      isMetric: editBox?.isMetric ? "metric" : "imperial",
    },
    validate: async (formValues) => {
      const errors = {}
      if (!formValues.height) {
        errors.height = "Required"
      }
      if (!formValues.width) {
        errors.width = "Required"
      }
      if (!formValues.length) {
        errors.length = "Required"
      }
      if (!formValues.name) {
        errors.name = "Required"
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const newBox = {
        height: formValues.height,
        width: formValues.width,
        length: formValues.length,
        name: formValues.name,
        companyId: currentCompany.id,
        weight: formValues.weight ? formValues.weight : "",
        isMetric: formValues.isMetric === "metric",
      }
      
      if (editBox) {
        await updateBox({
          packagingTypeId: editBox.id,
          payload: newBox
        })
      } else {
        await createBox(newBox)
      }

      formik.resetForm()
      setShowBoxDimensionDrawer(false)
      if (!isFetching) {
        refetchBoxes()
      }
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  const handleRemoveBox = async (boxId) => {
    await deleteBox(boxId)
    setShowBoxDimensionDrawer(false)
    if (!isFetching) {
      refetchBoxes()
    }
  }

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <CardContent style={{ border: `solid 1px ${customTheme.palette.inexPrimary.main}`, borderRadius: SPACING }}>
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <TextField
              id="name"
              label={t('userProfile.boxDimensionDrawer.fields.name.label')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name || ""}
              error={formik.touched.name && formik.errors.name !== undefined}
              type="text"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>

          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <TextField
              id="height"
              label={t('userProfile.boxDimensionDrawer.fields.height.label')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.height || ""}
              error={formik.touched.height && formik.errors.height !== undefined}
              type="number"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>

          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <TextField
              id="width"
              label={t('userProfile.boxDimensionDrawer.fields.width.label')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.width || ""}
              error={formik.touched.width && formik.errors.width !== undefined}
              type="number"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>

          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <TextField
              id="length"
              label={t('userProfile.boxDimensionDrawer.fields.length.label')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.length || ""}
              error={formik.touched.length && formik.errors.length !== undefined}
              type="number"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>

          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <TextField
              id="weight"
              label={t('userProfile.boxDimensionDrawer.fields.weight.label')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.weight || ""}
              error={formik.touched.weight && formik.errors.weight !== undefined}
              type="number"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>

          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="isMetric">Unit</InputLabel>
              <Select
                labelId="isMetric"
                id="isMetric"
                name="isMetric"
                label={t('userProfile.boxDimensionDrawer.fields.unit.label')}
                onChange={formik.handleChange}
                value={formik.values.isMetric}
              >
                <MenuItem value="metric">Metric</MenuItem>
                <MenuItem value="imperial">Imperial</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </form>

     {/* Added box dimensions list (if it's not edit mode */}
     {!editBox &&
        customBoxes &&
        customBoxes.map((box, i) => (
          <BoxCard key={i} box={box} index={i} setEditBox={setEditBox} handleRemoveBox={handleRemoveBox} />
        ))}
    </Box>
  )
}

BoxDimensionDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

BoxDimensionDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowBoxDimensionDrawer: PropTypes.func.isRequired,
}

export default BoxDimensionDrawer
