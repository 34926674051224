import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { styled } from "@mui/material/styles"

export const CustomDialogContent = styled(DialogContent, { label: "CustomDialogContent" })(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  "&.MuiDialogContent-root ": {
    paddingTop: theme.spacing(2),
  },
}))

export const CustomDialogActions = styled(DialogActions, { label: "CustomDialogActions" })(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: theme.utils.pxToRem(66),
  gap: theme.utils.pxToRem(12),
  paddingTop: theme.utils.pxToRem(12),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.utils.pxToRem(12),
  paddingLeft: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.strokeDefault.main}`,
}))

export const CustomDialogTitle = styled(DialogTitle, { label: "CustomDialogTitle" })(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: theme.utils.pxToRem(66),
  paddingTop: theme.utils.pxToRem(12),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.utils.pxToRem(12),
  paddingLeft: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.strokeDefault.main}`,
}))
