// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import CardContent from "@mui/material/CardContent"
import TextField from "@mui/material/TextField"
import { useTheme } from "@mui/material/styles"
import Alert from "@mui/material/Alert"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

import { useGetCountriesQuery } from "../../../../../../services/address-form/slice"
import { useGetCarriersQuery } from "../../../../../../services/carriers/slice"
import { useEditCompanyProfileMutation } from "../../../../../../services/company/slice"
import AccountCard from "./components/account-card"
import { getRandId } from "../../../../../../utils/generate-random-id"
import { useValidateAddressMutation } from "../../../../../full-address-form/slice"
import { TRANSACTION_STATUS_ENUM } from "../../../../../transaction-status"
import FullAddressForm from "../../../../../full-address-form"
import getComparator from "../../../../../../utils/sorting"

function ThirdPartyBillingAccountsDrawer({ onSubmitEvent, setShowThirdPartyBillingAccountsDrawer, refetchUserProfile }) {
  const { t, i18n } = useTranslation("userProfile")
  const customTheme = useTheme()
  const SPACING = 10

  const [editAccount, setEditAccount] = useState(null)
  const [selectedAddressFromAutoComplete, setSelectedAddressFromAutoComplete] = useState({})

  const { data: carriers } = useGetCarriersQuery()
  const { data: countries } = useGetCountriesQuery()
  const currentCompany = useSelector((state) => state.user?.company)
  const [editCompany] = useEditCompanyProfileMutation()
  const [, { data: validationAddress }] = useValidateAddressMutation()

  const supportedCarrierCodes = ["federal-express", "purolator-express", "purolator-freight", "fedex-freight", "dhl-express", "ups-parcel"]
  const supportedCarriers = carriers?.length > 0 ? carriers.filter((carrier) => supportedCarrierCodes.includes(carrier.code)) : []

  const formik = useFormik({
    initialValues: {
      carrierCode: editAccount?.carrierCode || "",
      thirdPartyShipperNumber: editAccount?.thirdPartyShipperNumber || "",
      name: editAccount?.name || "",
      companyName: editAccount?.companyName || "",
      personName: editAccount?.personName || "",
      addressLine1: editAccount?.addressLine1 || "",
      addressLine2: editAccount?.addressLine2 || "",
      city: editAccount?.city || "",
      provinceName: editAccount?.provinceName || "",
      provinceCode: editAccount?.provinceCode || "",
      countryCode: editAccount?.countryCode || "CA",
      postalCode: editAccount?.postalCode || "",
      phoneNumber: editAccount?.phoneNumber || "",
      emailAddress: editAccount?.emailAddress || "",
    },
    validate: async (formValues) => {
      const errors = {}
      if (!formValues.carrierCode) {
        errors.carrierCode = t("userProfile.thirdPartyBillingAccountsDrawer.fields.carrierCode.error.required")
      }

      if (!formValues.thirdPartyShipperNumber) {
        errors.thirdPartyShipperNumber = t("userProfile.thirdPartyBillingAccountsDrawer.fields.thirdPartyShipperNumber.error.required")
      }

      if (!formValues.postalCode) {
        errors.postalCode = t("userProfile.thirdPartyBillingAccountsDrawer.fields.postalCode.error.required")
      }

      if (!formValues.countryCode) {
        errors.countryCode = t("userProfile.thirdPartyBillingAccountsDrawer.fields.countryCode.error.required")
      }

      if (!formValues.name) {
        errors.name = t("userProfile.thirdPartyBillingAccountsDrawer.fields.name.error.required")
      }

      if (formValues.carrierCode === "fedex-freight") {
        if (!formValues.companyName) errors.companyName = "Required"
        if (!formValues.addressLine1) errors.addressLine1 = "Required"
        if (!formValues.city) errors.city = "Required"
        if (!formValues.countryCode) errors.countryCode = "Required"
        if (!formValues.postalCode) errors.postalCode = "Required"
        if (!formValues.phoneNumber) errors.phoneNumber = "Required"
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const addNew = !editAccount
      let accountNumbers = currentCompany?.billing?.accountNumbers?.length > 0 ? [...currentCompany.billing.accountNumbers] : []
      if (addNew) {
        accountNumbers = accountNumbers.concat({ ...formValues, id: getRandId() })
      } else {
        const idxToEdit = accountNumbers.findIndex((num) => num.id === editAccount.id)
        if (idxToEdit !== -1) {
          accountNumbers[idxToEdit] = { ...editAccount, ...formValues }
        }
      }

      editCompany({ billing: { accountNumbers }, companyId: currentCompany.id })

      formik.resetForm()
      setShowThirdPartyBillingAccountsDrawer(false)
      setTimeout(() => refetchUserProfile(), 300)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  useEffect(() => {
    if (validationAddress?.status === TRANSACTION_STATUS_ENUM.SUCCESS) {
      if ((selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.origin === "CanadaPost") || (selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.canadaPostId) || formik.values.canadaPostId) {
        if (selectedAddressFromAutoComplete.origin === "CanadaPost") formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.id }
        if (selectedAddressFromAutoComplete.canadaPostId) formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.canadaPostId }
        if (formik.values.canadaPostId) formik.values = { ...formik.values, canadaPostId: formik.values.canadaPostId }
      }
    }
  }, [validationAddress])

  const handleRemoveAccount = (indexToRemove) => {
    const accountNumbers = currentCompany?.billing?.accountNumbers?.length > 0 ? [...currentCompany.billing.accountNumbers] : []
    const idxToRemove = accountNumbers.findIndex((num) => num.id === indexToRemove)
    if (idxToRemove !== -1) accountNumbers.splice(idxToRemove, 1)

    editCompany({ billing: { accountNumbers }, companyId: currentCompany.id })

    setShowThirdPartyBillingAccountsDrawer(false)
    setTimeout(() => refetchUserProfile(), 300)
  }

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <CardContent style={{ border: `solid 1px ${customTheme.palette.inexPrimary.main}`, borderRadius: SPACING }}>
          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            {supportedCarriers && (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="carrierCode">{t("userProfile.thirdPartyBillingAccountsDrawer.fields.carrierCode.label")}</InputLabel>
                <Select
                  labelId="carrierCode"
                  id="carrierCode"
                  name="carrierCode"
                  label={t("userProfile.thirdPartyBillingAccountsDrawer.fields.carrierCode.label")}
                  error={formik.touched.carrierCode && formik.errors.carrierCode !== undefined}
                  onChange={formik.handleChange}
                  value={formik.values.carrierCode}
                  SelectDisplayProps={{ style: { height: 0 } }}
                >
                  {supportedCarriers.sort(getComparator("asc", "fullName.en")).map((carrier) => (
                    <MenuItem key={carrier.code} value={carrier.code}>
                      {carrier.fullName[i18n.language]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <TextField
              id="thirdPartyShipperNumber"
              label={t("userProfile.thirdPartyBillingAccountsDrawer.fields.thirdPartyShipperNumber.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.thirdPartyShipperNumber || ""}
              error={formik.touched.thirdPartyShipperNumber && formik.errors.thirdPartyShipperNumber !== undefined}
              type="text"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>

          {formik.values.carrierCode !== "fedex-freight" && (
            <>
              <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                <TextField
                  id="postalCode"
                  label={t("userProfile.thirdPartyBillingAccountsDrawer.fields.postalCode.label")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalCode || ""}
                  error={formik.touched.postalCode && formik.errors.postalCode !== undefined}
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Box>

              <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                {countries && (
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel id="countryCode">{t("userProfile.thirdPartyBillingAccountsDrawer.fields.countryCode.label")}</InputLabel>
                    <Select
                      labelId="countryCode"
                      id="countryCode"
                      name="countryCode"
                      label={t("userProfile.thirdPartyBillingAccountsDrawer.fields.countryCode.label")}
                      onChange={formik.handleChange}
                      value={formik.values.countryCode}
                      SelectDisplayProps={{ style: { height: 0 } }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name[i18n.language]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            </>
          )}

          <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
            <TextField
              id="name"
              label={t("userProfile.thirdPartyBillingAccountsDrawer.fields.name.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name || ""}
              error={formik.touched.name && formik.errors.name !== undefined}
              type="text"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>

          {formik.values.carrierCode === "fedex-freight" && (
            <>
              <Alert icon={false} severity="info" sx={(theme) => ({ pl: theme.spacing(2), pr: theme.spacing(1) })}>
                <Typography component="p" variant="bodyMedium400">
                  {t("userProfile.thirdPartyBillingAccountsDrawer.alerts.addressMatch")}
                </Typography>
              </Alert>
              <FullAddressForm formik={formik} displayResidentialToggle={false} displaySaveAddressCheckbox={false} displaySearchAddressBar setSelectedAddressFromAutoComplete={setSelectedAddressFromAutoComplete} saveDefaultAddressDisabled />
            </>
          )}
        </CardContent>
      </form>

      {/* Added account numbers list (if it's not edit mode */}
      {!editAccount &&
        currentCompany?.billing?.accountNumbers &&
        Array.isArray(currentCompany?.billing?.accountNumbers) &&
        currentCompany.billing.accountNumbers.map((account, i) => <AccountCard key={i} setEditAccount={setEditAccount} handleRemoveAccount={handleRemoveAccount} account={account} index={i} />)}
    </Box>
  )
}

ThirdPartyBillingAccountsDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

ThirdPartyBillingAccountsDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowThirdPartyBillingAccountsDrawer: PropTypes.func.isRequired,
  refetchUserProfile: PropTypes.func.isRequired,
}

export default ThirdPartyBillingAccountsDrawer
