// REACT
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

// Mui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CardContent from "@mui/material/CardContent"

// Custom
import InvitationSignUpForm from "./components/invitation-signup-form"

import { ReactComponent as LeftBackground } from "../../assets/images/sign-up-left-backgraound.svg"

import "./style.scss"

function SignUpInvitation() {
  const [currentCompany, setCurrentCompany] = useState("inex")
  const { t } = useTranslation("authenticator")
  const { invitationId } = useParams()

  return (
    <Box flexDirection="column" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1 })}>
      <Box className="left-background" style={{ zIndex: "-2", preserveAspectRatio: "none" }}>
        <LeftBackground />
      </Box>
      <Box className="right-background" style={{ zIndex: "-2", preserveAspectRatio: "none" }}>
        <LeftBackground />
      </Box>
      <Box
        className="primaryLabel"
        pb={2}
        sx={(theme) => ({
          width: "50rem",
          textAlign: "center",
          margin: "auto",
          marginTop: theme.utils.pxToRem(70),
        })}
      >
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: 700,
            fontSize: theme.utils.pxToRem(50),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
          })}
        >
          {t("inexSignUp.titlePart1")}
        </Typography>
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: 700,
            fontSize: theme.utils.pxToRem(50),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
            display: "inline",
            padding: "0 5px",
            background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, #A7E6D7 60%, #A7E6D7 80%, rgba(255,255,0,0) 80%, rgba(255,255,0,0) 100%)",
          })}
        >
          {t("inexSignUp.titlePart2")}
        </Typography>
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: 700,
            fontSize: theme.utils.pxToRem(50),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
          })}
        >
          {t("inexSignUp.titlePart3")}
        </Typography>
      </Box>
      <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(16) })} />
      <Typography
        className="secondaryLabel"
        component="h4"
        pb={2}
        sx={(theme) => ({
          color: theme.palette.inexTertiary.main,
          fontWeight: 600,
          fontSize: theme.utils.pxToRem(30),
          lineHeight: theme.utils.pxToLineHeight(20, 24),
          justifyContent: "center",
          fontFamily: "Poppins",
          width: "35rem",
          textAlign: "center",
          margin: "auto",
        })}
      >
        {t("inexSignUp.subtitle")}
      </Typography>
      <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(5) })} />
      <Typography
        className="thirnaryLabel"
        component="h4"
        pb={2}
        sx={(theme) => ({
          color: theme.palette.inexTertiary.main,
          fontSize: theme.utils.pxToRem(24),
          justifyContent: "center",
          fontFamily: "Poppins",
          width: "35rem",
          textAlign: "center",
          margin: "auto",
          lineHeight: theme.utils.pxToRem(35),
        })}
      >
        {t("inexSignUp.invitationText").replace("<companyName>", currentCompany)}
      </Typography>
      <InvitationSignUpForm invitationId={invitationId} setCurrentCompany={setCurrentCompany} />
    </Box>
  )
}

export default SignUpInvitation
