import React from "react"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"

function CreditCard({ paymentMethod }) {
  const titleCase = (str) => {
    if (!str) return str
    const splitStr = str.toLowerCase().split(" ")
    for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }

  const formatExpiryDate = (month, year) => {
    const monthString = month.toString().padStart(2, "0")
    const yearString = year.toString().slice(2)
    return `${monthString}/${yearString}`
  }

  return (
    <Box component="div">
      {titleCase(paymentMethod?.card?.brand)}: **** {paymentMethod?.card?.last4} - Exp: {formatExpiryDate(paymentMethod?.card?.exp_month, paymentMethod?.card?.exp_year)}
    </Box>
  )
}

CreditCard.propTypes = {
  paymentMethod: PropTypes.instanceOf(Object).isRequired,
}

export default CreditCard
