import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"

// Theme
import ManageInvitations from "./components/manage-invitations"
import ManageUsers from "./components/manage-users"
import CompanyDetails from "./components/company-details"

// Others
import { useGetCompanyProfileQuery, useGetCompanyUsersQuery } from "../../services/company/slice"
import { fetchCompanyUsersSuccess, updateUserRoleSuccess } from "../../reducers/companyReducer"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function Company() {
  const { t } = useTranslation("company")
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.user)
  useGetCompanyUsersQuery(currentUser?.companyId)
  const companyUsers = useSelector((state) => state.company.users)

  const { data: companyProfile } = useGetCompanyProfileQuery(currentUser?.companyId)

  useEffect(() => {
    if (companyProfile?.users) {
      dispatch(fetchCompanyUsersSuccess(companyProfile.users))
    }
  }, [companyProfile, dispatch])

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const handleRoleChange = (userId, newRole) => {
    dispatch(updateUserRoleSuccess({ userId, companyRole: newRole }))
    setSnackbarMessage(t('snackMessage.roleChangeSuccess'))
    setOpenSnackbar(true)
  }

  const handleStatusChange = (message) => {
    setSnackbarMessage(message)
    setOpenSnackbar(true)
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  return (
    <Box component="div" sx={() => ({ marginLeft: "10%", marginRight: "10%", marginTop: "80px" })}>
      <Box sx={() => ({ marginLeft: "14px", marginBottom: "60px" })}>
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: 600,
            fontSize: theme.utils.pxToRem(26),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
          })}
        >
          {t("titlePart1")}
        </Typography>
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: 600,
            fontSize: theme.utils.pxToRem(26),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginLeft: theme.utils.pxToRem(10),
            marginTop: theme.utils.pxToRem(70),
            display: "inline",
            padding: "0 5px",
            background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, #A7E6D7 60%, #A7E6D7 90%, rgba(255,255,0,0) 90%, rgba(255,255,0,0) 100%)",
          })}
        >
          {t("titlePart2")}
        </Typography>
      </Box>
      <Box sx={(theme) => ({ ml: theme.utils.pxToRem(14), mr: theme.utils.pxToRem(14), width: "auto" })}>
        <CompanyDetails companyId={currentUser?.companyId} />
      </Box>
      <Box sx={(theme) => ({ ml: theme.utils.pxToRem(14), mr: theme.utils.pxToRem(14), width: "auto" })}>
        <ManageUsers users={companyUsers} isFetching={false} onRoleChange={handleRoleChange} onStatusChange={handleStatusChange} />
      </Box>
      <Box sx={(theme) => ({ ml: theme.utils.pxToRem(14), mr: theme.utils.pxToRem(14), width: "auto" })}>
        <ManageInvitations users={companyUsers} refetchUsers={() => dispatch(fetchCompanyUsersSuccess(companyProfile.users))} />
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Company
