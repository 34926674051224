import React from "react"

import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"

function SkeletonQuote() {
  return (
    <Box mb={1} ml={1}>
      <Skeleton variant="rectangle" width="100%" height="100%">
        <Box sx={(theme) => ({ width: "100%", height: theme.utils.pxToRem(112) })} />
      </Skeleton>
    </Box>
  )
}

export default SkeletonQuote
