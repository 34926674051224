import restApiSlice from "../../../../../../services/rest-api/slice"

export const paymentValidationRestApiSlice = restApiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getValidation: builder.query({
      query: (rateResponseId) => ({ url: `/v2/carriers/generic/validate/${rateResponseId}`, method: "GET" }),
      transformResponse: (response) => response.data,
      extraOptions: {
        target: "paymentValidation",
      },
      keepUnusedDataFor: 0,
    }),
  }),
})

export const { useGetValidationQuery } = paymentValidationRestApiSlice

export default paymentValidationRestApiSlice.reducer
