import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

import { isRecipientComplete } from "../quotations/utils"
import { RecipientProps } from "../../models/rate-request"

function RecipientCard({ recipient, setShowRateRequestDrawer, isEditable, showPhoneNumber, showEmailAddress, ...sw }) {
  const { t } = useTranslation("quotations")
  const theme = useTheme()

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("recipient")
    setShowRateRequestDrawer(true)
  }

  const summaryStatusProps = { variant: isRecipientComplete(recipient) ? "success" : "warning" }

  return (
    <RateRequestSummaryCard title={t("recipientTitle")} handleClick={showRateRequestDrawer} summaryStatusProps={summaryStatusProps} showStatus={isEditable} isClickable={isEditable}>
      {recipient && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {recipient.companyName && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {recipient.companyName}
            </Typography>
          )}
          {recipient.personName && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {recipient.personName}
            </Typography>
          )}
          {recipient.addressLine1 && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {recipient.addressLine1}
            </Typography>
          )}
          {recipient.addressLine2 && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {recipient.addressLine2}
            </Typography>
          )}
          {(recipient.city || recipient.provinceCode) && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {`${recipient.city || ""}${recipient.city && (recipient.provinceCode || recipient.provinceName) ? ", " : ""}${recipient.provinceCode || recipient.provinceName || ""}${
                (recipient.city || recipient.provinceCode || recipient.provinceName) && (recipient.countryCode || recipient.countryName) ? ", " : ""
              }${recipient.countryCode || recipient.countryName || ""}`}
            </Typography>
          )}
          {recipient.postalCode && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {recipient.postalCode}
            </Typography>
          )}
          {(showPhoneNumber || showEmailAddress) && (
            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {showPhoneNumber && recipient.phoneNumber && (
                <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {recipient.phoneNumber}
                </Typography>
              )}
              {showEmailAddress && recipient.emailAddress && (
                <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {recipient.emailAddress}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

RecipientCard.propTypes = {
  recipient: RecipientProps,
  setShowRateRequestDrawer: PropTypes.func,
  isEditable: PropTypes.bool,
  showPhoneNumber: PropTypes.bool,
  showEmailAddress: PropTypes.bool,
}

RecipientCard.defaultProps = {
  recipient: undefined,
  setShowRateRequestDrawer: () => {},
  isEditable: true,
  showPhoneNumber: false,
  showEmailAddress: false,
}

export default RecipientCard
