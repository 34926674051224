// REACT
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"
import { useGetBoxesQuery } from "../../../../../../services/box-dimension/slice"

function BoxDimensionCard({ handleBoxDimensionCardClick }) {
  const { t } = useTranslation("userProfile")

  const currentCompany = useSelector((state) => state.user?.company)
  const { data: boxes } = useGetBoxesQuery(currentCompany?.id)
  const getSummaryStatus = () => SummaryStatusVariantsEnum.success
  const summaryStatusProps = { variant: getSummaryStatus() }
  
  let customBoxes = [];
  if(boxes) {
    customBoxes = boxes.filter(item => item.companyId !== null && item.companyId !== undefined);
  }


  return (
    <UserProfileSummaryCard title={t("userProfile.boxDimensionDrawer.header")} handleClick={handleBoxDimensionCardClick} summaryStatusProps={summaryStatusProps}>
      {customBoxes && Array.isArray(customBoxes) && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {customBoxes.map((box, i) => (
            <Typography key={i} variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {box.name} - {box.height}Hx{box.width}Wx{box.length}L - {box.weight} {box.isMetric ? "kg" : "lbs"}
            </Typography>
          ))}
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

BoxDimensionCard.propTypes = {
  handleBoxDimensionCardClick: PropTypes.func,
}

BoxDimensionCard.defaultProps = {
  handleBoxDimensionCardClick: () => {},
}

export default BoxDimensionCard
