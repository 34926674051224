import { Alert, Box, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { pxToRem } from "../../../../soe-theme/src/utils"

function NoRateResponse() {
  const { t } = useTranslation("quotations")
  return (
    <Box component="div" sx={{ marginLeft: pxToRem(8) }}>
      <Alert severity="warning">
        <Typography component="p" variant="bodyMedium400">
          {t("noRateResponseDescription")}
        </Typography>
      </Alert>
    </Box>
  )
}

export default NoRateResponse
