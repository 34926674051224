// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import groupBy from "lodash/groupBy"
import Currency from "../currency"
import LogoText from "../../assets/images/business-logo-text.svg"
import TranslationService from "../../services/translation"
import AppConfig from "../../utils/app-config"

function PriceSummaryCard({ rate, isFullFormat }) {
  const { i18n, t } = useTranslation("priceSummaryCard")
  const customTheme = useTheme()

  const chargesGrouped = groupBy(rate.charges, (charge) => charge.type)

  return (
    <Card variant="outlined" sx={(theme) => ({ my: theme.spacing(1) })}>
      <CardContent
        className="cardContent"
        sx={(theme) => ({
          "&:last-child": {
            pb: theme.spacing(2),
          },
        })}
      >
        {rate ? (
          <Box component="div" className="cardInformationText">
            <Typography variant="h5" component="span" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
              <Currency amount={rate.totals.discounted.total.amount} currency={rate.totals.discounted.total.currency} currencyPosition="right" showSymbol />
            </Typography>
            {AppConfig.features.showDiscountAmount && (
              <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                <Box component="div">
                  <img width={136} src={LogoText} alt="Business logo" />
                </Box>
                <Typography variant="bodyMedium400" ml={1} mr={1} component="span">
                  {t("discountTitle.label", { ns: "priceSummaryCard" })}
                </Typography>
                <Typography variant="inherit" component="span">
                  <Currency amount={rate.totals.retail.total.amount - rate.totals.discounted.total.amount} currency={rate.totals.discounted.total.currency} showSymbol={false} />
                </Typography>
              </Box>
            )}
            {isFullFormat && (
              <>
                <Box component="div" mt={customTheme.spacing(1)}>
                  {rate?.charges?.length > 0 && (
                    <Box>
                      {Object.keys(chargesGrouped).map((chargeGroup, indexTitle) => (
                        <Box component="div" key={chargeGroup.concat(indexTitle)}>
                          <Box component="div">
                            <Typography variant="bodyMedium600" component="span" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                              {t(`chargeTypes.${chargeGroup}.label`)}
                            </Typography>
                          </Box>
                          {chargesGrouped[chargeGroup].map((charge, index) => (
                            <Box component="div" key={charge.code.concat(index)} sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography variant="inherit" component="span" sx={(theme) => ({ lineHeight: theme.utils.pxToRem(24) })}>
                                {TranslationService.getLabelFromLocale(i18n.language, charge.name)}
                              </Typography>
                              <Typography variant="inherit" component="span" sx={(theme) => ({ lineHeight: theme.utils.pxToRem(24) })}>
                                <Currency amount={charge.discounted.amount} currency={charge.discounted.currency} showSymbol />
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box component="div" mt={customTheme.spacing(1)} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="bodyMedium600" component="span" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                    {`${t("charges.totalTitle.label")} (${rate.totals.discounted.total.currency})`}
                  </Typography>
                  <Typography variant="inherit" component="span" sx={(theme) => ({ lineHeight: theme.utils.pxToRem(24), fontWeight: theme.typography.fontWeightBold })}>
                    <Currency amount={rate.totals.discounted.total.amount} currency={rate.totals.discounted.total.currency} showSymbol />
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Typography variant="inherit" component="span" sx={(theme) => ({ lineHeight: theme.utils.pxToRem(24) })}>
            {t("noQuote.label")}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

PriceSummaryCard.propTypes = {
  rate: PropTypes.shape({
    charges: PropTypes.arrayOf(PropTypes.shape({})),
    totals: PropTypes.shape({
      discounted: PropTypes.shape({
        total: PropTypes.shape({
          amount: PropTypes.number,
          currency: PropTypes.string,
        }),
      }),
      retail: PropTypes.shape({
        total: PropTypes.shape({
          amount: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
  isFullFormat: PropTypes.bool,
}

PriceSummaryCard.defaultProps = {
  isFullFormat: true,
}

export default PriceSummaryCard
