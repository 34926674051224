const AppConfig = {
  app: {
    version: "1.1.1",
    environment: "PROD",
    nodeEnv: "production",
    addressAutoCompleteTimeout: "250",
  },
  aws: {
    cognito: {
      userPoolId: "ca-central-1_Qh87G1vny",
      clientId: "1htev00tp3v8sv6lmkd6u57fhh",
      hostedUiUrl: "https://saveonexpress.auth.ca-central-1.amazoncognito.com",
    },
  },
  externalLinks: {
    harmonizedSystemSearchUrl: "https://www.tariffinder.ca/en/getStarted",
  },
  restApi: {
    apiUrl: "https://api.saveonexpress.ca",
  },
  websocket: {
    url: "wss://wss.saveonexpress.ca",
    keepaliveDelay: "540000",
  },
  stripe: {
    publicKey: "pk_live_51KgDvOBCsctpTZ5i17give7lFhxXmYI2BaGmfqxXnHwy3VMnnyeHb2FY4Aewurw1TfxKfxq1K30UWydEmBK2Ro0S00V0Qf9YUE",
  },
  features: {
    showDiscountAmount: "false" === "true",
    showAddressValidation: "false" === "true",
    showOrderTypeColumn: "false" === "true",
  },
  logRocket: {
    id: process.env.REACT_APP_LOGROCKET_ID,
  },
  recaptcha: {
    siteId: "6Lf0UgYpAAAAACtZKICElZZK2FCES0TmgJQPoSTd",
  },
}

export default AppConfig
