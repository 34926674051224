import { createSlice } from "@reduxjs/toolkit"

import restApiSlice from "../services/rest-api/slice"

const initialState = {
  paymentStatus: undefined,
  isLoading: false,
  isPending: true,
}

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearPayment: () => initialState,
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload
    },
    setIsPending: (state, action) => ({
      ...state,
      isPending: action.payload,
    }),
    setIsLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
  },
  extraReducers: {
    RESET_QUOTATIONS: () => initialState,
  },
})

export const { clearPayment, setPaymentStatus, setIsLoading, setIsPending } = paymentSlice.actions

export const paymentRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    onAccountPayment: builder.mutation({
      query: ({ payload, connectionId }) => ({ url: "/payments/onaccount", headers: { connectionId }, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
    savedPaymentMethodCharge: builder.mutation({
      query: ({ payload, connectionId }) => ({ url: `/payments/charge/rateresponse/${payload.rateResponseId}`, headers: { connectionId }, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
    getPaymentReady: builder.query({
      query: ({ rateResponseId, connectionId }) => ({ url: `/payments/ready/${rateResponseId}`, headers: { connectionId }, method: "GET", keepUnusedDataFor: 0 }),
      transformResponse: (response) => response.data,
    }),
    getPaymentIntent: builder.query({
      query: () => ({ url: "/payments/intent", method: "GET", keepUnusedDataFor: 0 }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useOnAccountPaymentMutation, useSavedPaymentMethodChargeMutation, useGetPaymentReadyQuery, useGetPaymentIntentQuery } = paymentRestApiSlice

export default paymentSlice.reducer
