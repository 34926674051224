import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { useTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import React from "react"
import { useTranslation } from "react-i18next"

function SubmissionInformation() {
  const { t } = useTranslation("quotations")
  const theme = useTheme()
  return (
    <Card
      variant="outlined"
      square
      sx={{
        height: theme.utils.pxToRem(40),
        marginBottom: theme.spacing(1),
      }}
    >
      <CardContent sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1), paddingLeft: theme.spacing(2) }}>
        <Typography variant="bodyMedium600" gutterBottom>
          {t("submissionInformation")}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default SubmissionInformation
