import Grid from "@mui/material/Grid"
import React, { useEffect, useState } from "react"

import { useSelector, useDispatch } from "react-redux"

import BuyerCard from "../buyer-card"
import CommercialInvoiceCard from "../commercial-invoice-card"
import BrokerCard from "../broker-card"
import FreightOptionsCard from "../freight-options-card"
import InternationalOptionsCard from "../international-options-card"
import Main from "../main"
import PackagesCard from "../packages-card"
import Payment from "./components/payment"
import QuotationsList from "./components/quotations-list"
import QuoteSort from "./components/quote-sort"
import RateRequest from "./components/rate-request"
import RecipientCard from "../recipient-card"
import ShipperCard from "../shipper-card"
import ShippingOptionsCard from "../shipping-options-card"
import BillingOptionsCard from "../billing-options-card"
import SubmissionInformation from "./components/submission-information"
import { useGetCompanyProfileQuery } from "../../services/company/slice"

import { SoeDrawer } from "../../soe-theme/src/components"
import { updateFreightOptions, updateCommercialInvoice } from "../../reducers/quotationsReducer"
import { isFreightOptionsRequired, isInternationalShipment } from "./utils"

import { TRANSACTION_STATUS_ENUM } from "../transaction-status"
import { PAYMENT_WIZARD_STEPS_ENUM } from "../../utils/constants"

function Quotations() {
  const currentUser = useSelector((state) => state.user)
  const { data: currentCompany } = useGetCompanyProfileQuery(currentUser.companyId)

  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false)
  const [showRateRequestDrawer, setShowRateRequestDrawer] = useState(false)
  const { isLoading, paymentStatus } = useSelector((state) => state.payment)

  const { freightOptions, internationalOptions, packages, shipper, shippingOptions, billingOptions, recipient, commercialInvoice, broker, buyer } = useSelector((state) => state.quotations)
  const dispatch = useDispatch()

  const [rateRequestStepWizard, setRateRequestStepWizard] = useState()
  const [paymentStepWizard, setPaymentStepWizard] = useState()

  const setRateRequestStepWizardInstance = (sw) => {
    setRateRequestStepWizard(sw)
  }

  const setPaymentStepWizardInstance = (sw) => {
    setPaymentStepWizard(sw)
  }

  useEffect(() => {
    if (!isFreightOptionsRequired(packages)) {
      dispatch(updateFreightOptions(undefined))
    }
    if (internationalOptions?.commercialInvoiceRequired) dispatch(updateCommercialInvoice({ ...commercialInvoice, unitOfMeasure: packages.unitOfMeasure }))
  }, [packages?.pieces])

  useEffect(() => {
    if (internationalOptions?.commercialInvoiceRequired) {
      dispatch(updateCommercialInvoice({ ...commercialInvoice, unitOfMeasure: packages.unitOfMeasure }))
    }
  }, [packages?.pieces, internationalOptions?.commercialInvoiceRequired])

  const isPaymentSuccessOnPaymentMethodStep = paymentStatus?.status === TRANSACTION_STATUS_ENUM.SUCCESS && paymentStepWizard?.state?.namedSteps?.[paymentStepWizard.currentStep - 1] === PAYMENT_WIZARD_STEPS_ENUM.PAYMENT_METHOD

  return (
    <Main id="quotations" sx={{ height: "auto" }}>
      <SoeDrawer showDrawer={showRateRequestDrawer} setShowDrawer={setShowRateRequestDrawer}>
        <RateRequest setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} setStepWizardInstance={setRateRequestStepWizardInstance} />
      </SoeDrawer>

      <Grid container>
        <Grid item md={3} xs={12}>
          <SubmissionInformation />
          <ShipperCard shipper={shipper} setShowRateRequestDrawer={setShowRateRequestDrawer} stepWizard={rateRequestStepWizard} />
          <RecipientCard recipient={recipient} setShowRateRequestDrawer={setShowRateRequestDrawer} stepWizard={rateRequestStepWizard} />
          <PackagesCard packages={packages} commercialInvoice={commercialInvoice} setShowRateRequestDrawer={setShowRateRequestDrawer} stepWizard={rateRequestStepWizard} />
          <ShippingOptionsCard shippingOptions={shippingOptions} setShowRateRequestDrawer={setShowRateRequestDrawer} stepWizard={rateRequestStepWizard} />
          {(currentCompany?.featureFlags?.thirdPartyBilling === true) && <BillingOptionsCard billingOptions={billingOptions} setShowRateRequestDrawer={setShowRateRequestDrawer} stepWizard={rateRequestStepWizard} />}
          {isFreightOptionsRequired(packages) && <FreightOptionsCard freightOptions={freightOptions} setShowRateRequestDrawer={setShowRateRequestDrawer} disabled={!isInternationalShipment(shipper, recipient)} stepWizard={rateRequestStepWizard} />}
          <InternationalOptionsCard internationalOptions={internationalOptions} setShowRateRequestDrawer={setShowRateRequestDrawer} disabled={!isInternationalShipment(shipper, recipient)} stepWizard={rateRequestStepWizard} />
          <BrokerCard broker={broker} setShowRateRequestDrawer={setShowRateRequestDrawer} disabled={!internationalOptions?.brokerRequired} stepWizard={rateRequestStepWizard} />
          <CommercialInvoiceCard
            commercialInvoice={commercialInvoice}
            packages={packages}
            setShowRateRequestDrawer={setShowRateRequestDrawer}
            disabled={internationalOptions?.documentsOnly || !isInternationalShipment(shipper, recipient)}
            stepWizard={rateRequestStepWizard}
          />
          <BuyerCard buyer={buyer} setShowRateRequestDrawer={setShowRateRequestDrawer} disabled={!commercialInvoice?.buyerRequired} stepWizard={rateRequestStepWizard} />
        </Grid>
        <Grid item md={9} xs={12}>
          <QuoteSort />
          <QuotationsList showRateRequestDrawer={showRateRequestDrawer} setShowRateRequestDrawer={setShowRateRequestDrawer} setShowPaymentDrawer={setShowPaymentDrawer} />
        </Grid>
      </Grid>
      <SoeDrawer showDrawer={showPaymentDrawer} setShowDrawer={setShowPaymentDrawer} anchor="right" keepMounted={false} preventClosing={isLoading || isPaymentSuccessOnPaymentMethodStep}>
        <Payment setShowPaymentDrawer={setShowPaymentDrawer} setPaymentStepWizardInstance={setPaymentStepWizardInstance} />
      </SoeDrawer>
    </Main>
  )
}

export default Quotations
