import React from "react"
import PropTypes from "prop-types"

import Box from "@mui/material/Box"
import DateFnsTz from "date-fns-tz"
import DateTimeService from "../../services/date-time"

import "./style.scss"

function DateTime(props) {
  const { date, time, timezoneCode, timezone } = props
  const utcTime = DateFnsTz.zonedTimeToUtc(`${date} ${time}`, timezone)

  return (
    <Box component="span">
      {DateTimeService.toLocaleDateString(utcTime, timezone)} {time} {timezoneCode || DateTimeService.getTimezoneName(utcTime, timezone)}
    </Box>
  )
}

DateTime.defaultProps = {
  time: undefined,
  timezoneCode: undefined,
  timezone: undefined,
}

DateTime.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string,
  timezoneCode: PropTypes.string,
  timezone: PropTypes.string,
}

export default DateTime
