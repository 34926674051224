import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import CardContent from "@mui/material/CardContent"
import TextField from "@mui/material/TextField"
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { useCreateEmailLabelMutation, useGetPrintLabelsQuery } from "../../../../../../services/order/slice"
import OrderProps from "../../../../../../models/order"

function EmailLabelDrawer({ shipment, showEmailLabelDrawer, setShowEmailLabelDrawer, onSubmitEvent, setSubmitEvent }) {
  const { t } = useTranslation("shipments")
  const customTheme = useTheme()
  const SPACING = 10

  const [sendEmail, { isLoading: isSendingEmail }] = useCreateEmailLabelMutation()
  const { data: printLabels, isLoading: isFetchingLabels } = useGetPrintLabelsQuery({ orderId: shipment.id }, { skip: !shipment.id })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: shipment?.quotation?.request?.shipper?.emailAddress || "",
    },
    validate: (values) => {
      const errors = {}
      if (!values.email) {
        errors.email = "Required"
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address"
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      // Wait for printLabels to be available
      if (!printLabels) {
        console.log('Waiting for print labels...')
        return
      }
      
      const payload = {
        email: formValues.email,
        shipment,
        printLabels,
      }

      try {
        await sendEmail({ payload }).unwrap()
        setShowSuccessMessage(true)
        setShowErrorMessage(false)
        setIsButtonDisabled(true)
      } catch (error) {
        setShowSuccessMessage(false)
        setShowErrorMessage(true)
      }

      formik.resetForm()
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  useEffect(() => {
    if (printLabels && formik.isSubmitting) {
      formik.handleSubmit()
    }
  }, [printLabels])

  return (
    <SoeDrawer showDrawer={showEmailLabelDrawer} setShowDrawer={setShowEmailLabelDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title="Email your label"
          setShowDrawer={() => {
            setShowEmailLabelDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <Box component="div">
            <form onSubmit={formik.handleSubmit} noValidate>
              <CardContent style={{ border: `solid 1px ${customTheme.palette.inexPrimary.main}`, borderRadius: SPACING }}>
                <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                  <TextField
                    id="email"
                    label="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email || ""}
                    error={formik.touched.email && formik.errors.email !== undefined}
                    helperText={formik.touched.email && formik.errors.email}
                    type="email"
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled={isFetchingLabels || isSendingEmail || isButtonDisabled}
                  />
                </Box>
              </CardContent>
              {showSuccessMessage && (
                <Alert severity="success" onClose={() => setShowSuccessMessage(false)} sx={{ mt: 2 }}>
                  Email sent successfully!
                </Alert>
              )}
              {showErrorMessage && (
                <Alert severity="error" onClose={() => setShowErrorMessage(false)} sx={{ mt: 2 }}>
                  Failed to send email. Please try again.
                </Alert>
              )}
            </form>
          </Box>
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: setSubmitEvent,
              label: isFetchingLabels || isSendingEmail ? 'Loading...' : "Send",
              variant: "contained",
              disabled: isFetchingLabels || isSendingEmail || isButtonDisabled,
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

EmailLabelDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

EmailLabelDrawer.propTypes = {
  shipment: OrderProps.isRequired,
  showEmailLabelDrawer: PropTypes.bool.isRequired,
  setShowEmailLabelDrawer: PropTypes.func.isRequired,
  onSubmitEvent: PropTypes.instanceOf(Object),
  setSubmitEvent: PropTypes.func.isRequired,
}

export default EmailLabelDrawer
