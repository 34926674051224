import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"

const StyledOpenInNewTab = styled(Button, { name: "StyledOpenInNewTab", slot: "Root" })(({ theme }) => ({
  backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
  color: theme.palette.contentActionDefault.main,
  fontWeight: theme.typography.fontWeightBold,
  boxShadow: "none",
  textTransform: "none",
  paddingRight: theme.spacing(1),
  "&:hover": {
    backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
    color: theme.palette.contentActionDefault.main,
    boxShadow: "none",
  },
  "&:focus": {
    color: theme.palette.contentActionDefault.main,
    boxShadow: "none",
  },
}))

export default StyledOpenInNewTab
