import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import LinearProgress from "@mui/material/LinearProgress"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTranslation } from "react-i18next"

import TransactionStatusMessageList from "./components/transaction-status-message-list"
import TranslationService from "../../services/translation"

export const TRANSACTION_STATUS_ENUM = {
  SUCCESS: "success",
  PENDING: "pending",
  WARNING: "warning",
  ERROR: "error",
  INFO: "info",
}

const getTitleColor = (theme, transaction, isError) => {
  if (transaction?.status === TRANSACTION_STATUS_ENUM.SUCCESS) return "green"
  if (transaction?.status === TRANSACTION_STATUS_ENUM.WARNING) return "orange"
  if (transaction?.status === TRANSACTION_STATUS_ENUM.ERROR || isError) return "red"
  if (transaction?.status === TRANSACTION_STATUS_ENUM.INFO) return "blue"
  return theme.palette.contentNeutralPrimary.main
}

function TransactionStatus({ transaction, title, isError, isLoading }) {
  const { i18n } = useTranslation()

  return (
    <Card sx={(theme) => ({ mb: theme.spacing(1) })}>
      <Box component="div" sx={(theme) => ({ display: "flex", flexDirection: "column", mt: theme.spacing(0), pt: theme.spacing(1), p: theme.spacing(2) })}>
        <Typography variant="bodyMedium600" sx={(theme) => ({ color: getTitleColor(theme, transaction, isError) })}>
          {TranslationService.getLabelFromLocale(i18n.language, transaction?.title) || title}
        </Typography>
        <LinearProgress
          color={isLoading || transaction?.status === TRANSACTION_STATUS_ENUM.PENDING ? "secondary" : isError ? "error" : transaction?.status}
          variant={isLoading || transaction?.status === TRANSACTION_STATUS_ENUM.PENDING ? "indeterminate" : "determinate"}
          value={100}
        />
        {transaction?.messages && <TransactionStatusMessageList messages={transaction?.messages} />}
      </Box>
    </Card>
  )
}

TransactionStatus.propTypes = {
  transaction: PropTypes.shape({
    status: PropTypes.string,
    title: PropTypes.instanceOf(Object),
    messages: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }),
  title: PropTypes.string,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
}

TransactionStatus.defaultProps = {
  transaction: undefined,
  title: undefined,
  isError: false,
  isLoading: false,
}

export default TransactionStatus
