import Button from "@mui/material/Button"

import { styled } from "@mui/material/styles"

export const test = 45

export const MainCustomButton = styled(Button, { label: "MainCustomButton", slot: "Root" })(({ theme }) => ({
    backgroundColor: theme.palette.inexPrimary.main,
    color: "white",
    textTransform: "uppercase",
    boxShadow: "0px 3px 6px #00000029",
    borderColor: theme.palette.inexPrimary.main,
    width: "150px",
    height: "50px",
    "&:hover": {
       backgroundColor: theme.palette.inexSecondary.main,
       borderColor: theme.palette.inexSecondary.main,
       color: "white",
    },
    "&:disabled": {
      backgroundColor: "#A7E6D780",
    }
 }))
