import PropTypes from "prop-types"
import React from "react"

import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

import ClientsAutocomplete from "../../../clients-autocomplete"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import ImpersonateService from "../../../../services/impersonate"

function ImpersonateClientSelectionDrawer({ showImpersonateClientSelectionDrawer, setShowImpersonateClientSelectionDrawer }) {
  const { t } = useTranslation("appBar")

  const formik = useFormik({
    initialValues: {
      client: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.client) {
        errors.client = true
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      ImpersonateService.impersonate(formValues.client.emailAddress)
      window.location.reload(false)
    },
  })

  const handleClose = () => {
    setShowImpersonateClientSelectionDrawer(false)
  }

  const handleConfirm = () => {
    formik.handleSubmit()
    setShowImpersonateClientSelectionDrawer(false)
  }

  return (
    <SoeDrawer showDrawer={showImpersonateClientSelectionDrawer} setShowDrawer={setShowImpersonateClientSelectionDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("impersonateClientSelectionDrawer.title")}
          setShowDrawer={() => {
            setShowImpersonateClientSelectionDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <ClientsAutocomplete formik={formik} />
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleClose,
              label: t("impersonateClientSelectionDrawer.actions.cancel.label"),
              variant: "outlined",
            },
            {
              action: handleConfirm,
              label: t("impersonateClientSelectionDrawer.actions.confirm.label"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

ImpersonateClientSelectionDrawer.propTypes = {
  showImpersonateClientSelectionDrawer: PropTypes.bool.isRequired,
  setShowImpersonateClientSelectionDrawer: PropTypes.func.isRequired,
}

export default ImpersonateClientSelectionDrawer
