// REACT
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"

function PaymentMethodCard({ paymentMethod, handlePaymentMethodCardClick }) {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const currentUser = useSelector((state) => state.user)

  const getSummaryStatus = () => (paymentMethod.status === "default" ? SummaryStatusVariantsEnum.default : SummaryStatusVariantsEnum.delete)
  const summaryStatusProps = { variant: getSummaryStatus() }

  const titleCase = (str) => {
    if (!str) return str
    const splitStr = str.toLowerCase().split(" ")
    for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }

  const formatExpiryDate = (month, year) => {
    const monthString = month.toString().padStart(2, "0")
    const yearString = year.toString().slice(2)
    return `${monthString}/${yearString}`
  }

  return (
    <UserProfileSummaryCard title={t("userProfile.paymentMethodCard.title")} summaryStatusProps={summaryStatusProps} handleClick={handlePaymentMethodCardClick}>
      {currentUser && paymentMethod && (
        <Box
          mt={customTheme.spacing(1)}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {t("userProfile.paymentMethodCard.fields.type.label")} {titleCase(paymentMethod.card?.brand)}
          </Typography>
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {t("userProfile.paymentMethodCard.fields.number.label")} {`**** ${paymentMethod.card?.last4}`}
          </Typography>
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {t("userProfile.paymentMethodCard.fields.expiry.label")} {formatExpiryDate(paymentMethod.card?.exp_month, paymentMethod.card?.exp_year)}
          </Typography>
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

PaymentMethodCard.propTypes = {
  handlePaymentMethodCardClick: PropTypes.func,
  paymentMethod: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    card: PropTypes.shape({
      brand: PropTypes.string,
      last4: PropTypes.string,
      exp_month: PropTypes.number,
      exp_year: PropTypes.number,
    }),
  }),
}

PaymentMethodCard.defaultProps = {
  handlePaymentMethodCardClick: () => {},
  paymentMethod: {},
}

export default PaymentMethodCard
