import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

import SoeDrawerActions from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-actions"
import SoeDrawerContainer from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-container"
import SoeDrawerContent from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-content"
import SoeDrawerHeader from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-header"

import { FREIGHT_OPTIONS } from "../../../../../../utils/constants"
import { updateFreightOptions } from "../../../../../../reducers/quotationsReducer"
import { isInternationalShipment, getFreightOptionsInitialValues } from "../../../../utils"
import { pxToThemeSpacing } from "../../../../../../soe-theme/src/utils"

function RateRequestFreightOptions({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { t } = useTranslation(["quotations", "constant"])
  const dispatch = useDispatch()

  const { recipient, shipper, freightOptions } = useSelector((state) => state.quotations)

  const [action, setAction] = useState("")
  const [tailgateRequired, setTailgateRequired] = useState(!!freightOptions?.tailgate)
  const [appointmentRequired, setAppointmentRequired] = useState(!!freightOptions?.appointment)
  const [twoPersonsRequired, setTwoPersonsRequired] = useState(!!freightOptions?.twoPersons)
  const [prebookedAppointmentRequired, setPrebookedAppointmentRequired] = useState(!!freightOptions?.prebookedAppointment)
  const [afterHoursRequired, setAfterHoursRequired] = useState(!!freightOptions?.afterHours)

  const formik = useFormik({
    initialValues: getFreightOptionsInitialValues(freightOptions),
    validateOnChange: true,
    enableReinitialize: true,
    validate: async (formValues) => {
      const errors = {}
      const appointmentDateRegex = /^([0-9]{4}-[0-9]{2}-[0-9]{2})$/
      const appointmentTimeRegex = /^(([0-1]?[0-9]|2[0-3]):[0-5][0-9])$/
      if (appointmentRequired && !formValues.appointment) {
        errors.appointment = true
      }
      if (prebookedAppointmentRequired && (!formValues.appointmentDate || !new RegExp(appointmentDateRegex, "g").test(formValues.appointmentDate))) {
        errors.appointmentDate = true
      }
      if (prebookedAppointmentRequired && (!formValues.appointmentTime || !new RegExp(appointmentTimeRegex, "g").test(formValues.appointmentTime))) {
        errors.appointmentTime = true
      }
      if (afterHoursRequired && !formValues.afterHours) {
        errors.afterHours = true
      }
      if (twoPersonsRequired && !formValues.twoPersons) {
        errors.twoPersons = true
      }
      if (tailgateRequired && !formValues.tailgate) {
        errors.tailgate = true
      }
      return errors
    },
    onSubmit: (formValues) => {
      const freightOptionsPayload = {
        ...formValues,
        prebookedAppointment:
          prebookedAppointmentRequired && (formValues.appointmentDate || formValues.appointmentTime)
            ? {
                appointmentDate: formValues.appointmentDate,
                appointmentTime: formValues.appointmentTime,
              }
            : undefined,
      }
      delete freightOptionsPayload.appointmentDate
      delete freightOptionsPayload.appointmentTime
      dispatch(updateFreightOptions(freightOptionsPayload))
      if (action === "continue") wizardProps.nextStep()
      if (action === "close") setShowRateRequestDrawer(false)
    },
  })

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  useEffect(() => {
    if (!freightOptions) {
      setTailgateRequired(false)
      setAppointmentRequired(false)
      setTwoPersonsRequired(false)
      setPrebookedAppointmentRequired(false)
      setAfterHoursRequired(false)
    }
  }, [freightOptions])

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("close")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.freightOptions.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (appointmentRequired) {
                      setAppointmentRequired(false)
                      formik.setFieldValue("appointment", undefined)
                    } else {
                      setAppointmentRequired(true)
                      formik.setFieldValue("appointment", "")
                    }
                  }}
                  name="appointmentRequired"
                  checked={appointmentRequired}
                />
              }
              label={t("drawer.freightOptions.fields.appointment.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {appointmentRequired && (
            <Box component="div">
              <TextField
                name="appointment"
                onChange={formik.handleChange}
                value={formik.values?.appointment || ""}
                size="small"
                label={t("drawer.freightOptions.fields.appointment.inputLabel", { ns: "rateRequest" })}
                error={formik.touched?.appointment && formik.errors?.appointment !== undefined}
              />
            </Box>
          )}
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (prebookedAppointmentRequired) {
                      formik.setFieldValue("prebookedAppointment", undefined)
                      setPrebookedAppointmentRequired(false)
                    } else {
                      setPrebookedAppointmentRequired(true)
                      formik.setFieldValue("prebookedAppointment", "")
                    }
                  }}
                  checked={prebookedAppointmentRequired}
                  name="prebookedAppointmentRequired"
                />
              }
              label={t("drawer.freightOptions.fields.prebookedAppointment.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {prebookedAppointmentRequired && (
            <Box component="div">
              <TextField
                name="appointmentDate"
                onChange={formik.handleChange}
                size="small"
                value={formik.values?.appointmentDate || ""}
                label={t("drawer.freightOptions.fields.prebookedAppointment.appointmentDate", { ns: "rateRequest" })}
                error={formik.touched?.appointmentDate && formik.errors?.appointmentDate !== undefined}
                placeholder={t("drawer.freightOptions.fields.prebookedAppointment.appointmentDatePlaceHolder", { ns: "rateRequest" })}
                sx={{ width: "0.48" }}
              />
              <TextField
                name="appointmentTime"
                onChange={formik.handleChange}
                size="small"
                value={formik.values?.appointmentTime || ""}
                label={t("drawer.freightOptions.fields.prebookedAppointment.appointmentTime", { ns: "rateRequest" })}
                error={formik.touched?.appointmentTime && formik.errors?.appointmentTime !== undefined}
                placeholder={t("drawer.freightOptions.fields.prebookedAppointment.appointmentTimePlaceHolder", { ns: "rateRequest" })}
                sx={{ width: "0.48", marginLeft: pxToThemeSpacing(10) }}
              />
            </Box>
          )}
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (afterHoursRequired) {
                      setAfterHoursRequired(false)
                      formik.setFieldValue("afterHours", undefined)
                    } else {
                      setAfterHoursRequired(true)
                      formik.setFieldValue("afterHours", "delivery")
                    }
                  }}
                  checked={afterHoursRequired}
                  name="afterHoursRequired"
                />
              }
              label={t("drawer.freightOptions.fields.afterHours.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {afterHoursRequired && (
            <Box component="div">
              <FormControl fullWidth error={formik.touched?.afterHours && formik.errors?.afterHours !== undefined}>
                <InputLabel />
                <Select name="afterHours" onChange={formik.handleChange} size="small" defaultValue="delivery">
                  {FREIGHT_OPTIONS.AFTER_HOURS.map((afterHour, index) => (
                    <MenuItem key={afterHour.value.concat(index)} value={afterHour.value || ""}>
                      {t(`drawer.freightOptions.fields.afterHours.options.${afterHour.label}`, { ns: "rateRequest" })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (twoPersonsRequired) {
                      setTwoPersonsRequired(false)
                      formik.setFieldValue("twoPersons", undefined)
                    } else {
                      setTwoPersonsRequired(true)
                      formik.setFieldValue("twoPersons", "delivery")
                    }
                  }}
                  name="twoPersonsRequired"
                  checked={twoPersonsRequired}
                />
              }
              label={t("drawer.freightOptions.fields.twoPersons.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {twoPersonsRequired && (
            <Box component="div">
              <FormControl fullWidth error={formik.touched?.twoPersons && formik.errors?.twoPersons !== undefined}>
                <InputLabel />
                <Select name="twoPersons" onChange={formik.handleChange} size="small" defaultValue="delivery">
                  {FREIGHT_OPTIONS.TWO_PERSONS.map((twoPerson, index) => (
                    <MenuItem key={twoPerson.value.concat(index)} value={twoPerson.value || "delivery"}>
                      {t(`drawer.freightOptions.fields.twoPersons.options.${twoPerson.label}`, { ns: "rateRequest" })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box component="div">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    if (tailgateRequired) {
                      setTailgateRequired(false)
                      formik.setFieldValue("tailgate", undefined)
                    } else {
                      setTailgateRequired(true)
                      formik.setFieldValue("tailgate", "delivery")
                    }
                  }}
                  name="tailgateRequired"
                  checked={tailgateRequired}
                />
              }
              label={t("drawer.freightOptions.fields.tailgate.checkboxLabel", { ns: "rateRequest" })}
            />
          </Box>
          {tailgateRequired && (
            <Box component="div">
              <FormControl fullWidth error={formik.touched?.tailgate && formik.errors?.tailgate !== undefined}>
                <InputLabel />
                <Select name="tailgate" onChange={formik.handleChange} size="small" defaultValue="delivery">
                  {FREIGHT_OPTIONS.TAILGATE.map((tg, index) => (
                    <MenuItem key={tg.value.concat(index)} value={tg.value || "delivery"}>
                      {t(`drawer.freightOptions.fields.tailgate.options.${tg.label}`, { ns: "rateRequest" })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </form>
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          isInternationalShipment(shipper, recipient)
            ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.freightOptions.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.freightOptions.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
            : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.freightOptions.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
        }
      />
    </SoeDrawerContainer>
  )
}

RateRequestFreightOptions.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestFreightOptions.defaultProps = {}
export default RateRequestFreightOptions
