// REACT
import React from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

// MUI
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

import SignOut from "../../../authenticator/components/sign-out"

// Other
import ImpersonateService from "../../../../services/impersonate"

import { ROUTES } from "../../../../utils/constants"

function AppBarMenu({ open, onClose, anchorEl, setShowImpersonateClientSelectionDrawer }) {
  const { t } = useTranslation("appBar")
  const navigate = useNavigate()

  const currentUser = useSelector((state) => state.user)

  const handleUserMenuClose = () => {
    onClose(true)
  }

  const handleProfileClick = () => {
    handleUserMenuClose()
    navigate(ROUTES.USER_PROFILE)
  }

  const handleAddressbookClick = () => {
    handleUserMenuClose()
    navigate(ROUTES.ADDRESS_BOOK)
  }

  const handleQuotationsHistoryClick = () => {
    handleUserMenuClose()
    navigate(ROUTES.RATE_REQUEST_HISTORY)
  }

  const handleShipmentsClick = () => {
    handleUserMenuClose()
    navigate(ROUTES.SHIPMENTS)
  }

  const handleInvoicesClick = () => {
    handleUserMenuClose()
    navigate(ROUTES.INVOICES)
  }

  const handleCompanyClick = () => {
    handleUserMenuClose()
    navigate(ROUTES.COMPANY)
  }

  const handleImpersonateClick = () => {
    handleUserMenuClose()
    setShowImpersonateClientSelectionDrawer(true)
  }

  const handleUnimpersonateClick = () => {
    handleUserMenuClose()
    ImpersonateService.unimpersonate()
    window.location.reload(false)
  }

  return (
    <Box component="div">
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleUserMenuClose}
      >
        <MenuItem onClick={() => handleProfileClick()} disableRipple>
          {t("userMenu.profile")}
        </MenuItem>
        <MenuItem onClick={() => handleAddressbookClick()} disableRipple>
          {t("userMenu.addressbook")}
        </MenuItem>
        <MenuItem onClick={() => handleQuotationsHistoryClick()} disableRipple>
          {t("userMenu.quotationsHistory")}
        </MenuItem>
        {currentUser.companyRole === "ADMIN" && (
          <MenuItem onClick={() => handleCompanyClick()} disableRipple>
            {t("userMenu.company")}
          </MenuItem>
        )}
        <MenuItem onClick={() => handleShipmentsClick()} disableRipple>
          {t("userMenu.shipments")}
        </MenuItem>
        {currentUser.companyRole !== "SHIP" && (
          <MenuItem onClick={() => handleInvoicesClick()} disableRipple>
            {t("userMenu.invoices")}
          </MenuItem>
        )}
        {currentUser?.isAdmin && !ImpersonateService.currentImpersonation() && (
          <MenuItem onClick={() => handleImpersonateClick()} disableRipple>
            {t("userMenu.impersonate")}
          </MenuItem>
        )}
        {ImpersonateService.currentImpersonation() && (
          <MenuItem onClick={() => handleUnimpersonateClick()} disableRipple>
            {t("userMenu.unimpersonate")}
          </MenuItem>
        )}
        <SignOut
          setIsMenuOpen={(isMenuOpen) => {
            if (!isMenuOpen) {
              handleUserMenuClose()
            }
          }}
        />
      </Menu>
    </Box>
  )
}

AppBarMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Object),
  setShowImpersonateClientSelectionDrawer: PropTypes.func,
}

AppBarMenu.defaultProps = {
  anchorEl: undefined,
  setShowImpersonateClientSelectionDrawer: () => {},
}

export default AppBarMenu
