import { createSlice } from "@reduxjs/toolkit"

import restApiSlice from "../services/rest-api/slice"

import { AddressSearchGroupsText } from "../constants/quotationsConstants"

const initialState = {
  quotationsList: undefined,
  quoteInformation: undefined,
  rateRequestId: undefined,
  shipper: undefined,
  recipient: undefined,
  packages: {},
  optionsInformation: undefined,
  internationalOptions: undefined,
  broker: undefined,
  buyer: undefined,
  commercialInvoice: undefined,
  freightOptions: undefined,
  pickupInformation: undefined,
  shippingOptions: undefined,
  billingOptions: {
    type: "myAccount",
  },
  sortParams: {
    value: undefined,
    date: undefined,
    price: true,
  },
  showSpinner: false,
  rates: [],
}

export const quotationsSlice = createSlice({
  name: "quotations",
  initialState,
  reducers: {
    updateRateRequestId: (state, action) => ({
      ...state,
      rateRequestId: action.payload,
    }),
    updateShipper: (state, action) => ({
      ...state,
      shipper: action.payload
        ? {
            id: action.payload?.id || undefined,
            companyName: action.payload?.companyName || undefined,
            personName: action.payload?.personName || undefined,
            addressLine1: action.payload?.addressLine1 || undefined,
            addressLine2: action.payload?.addressLine2 || undefined,
            city: action.payload?.city || undefined,
            provinceName: action.payload?.provinceName || undefined,
            provinceCode: action.payload?.provinceCode || undefined,
            countryCode: action.payload?.countryCode || undefined,
            postalCode: action.payload?.postalCode || undefined,
            residential: action.payload?.residential,
            phoneNumber: action.payload?.phoneNumber || undefined,
            emailAddress: action.payload?.emailAddress || undefined,
            validationProviderId: action.payload?.validationProviderId || undefined,
            timezone: action.payload?.timezone || undefined,
          }
        : undefined,
    }),
    updateRecipient: (state, action) => ({
      ...state,
      recipient: action.payload
        ? {
            id: action.payload?.id || undefined,
            companyName: action.payload?.companyName || undefined,
            personName: action.payload?.personName || undefined,
            addressLine1: action.payload?.addressLine1 || undefined,
            addressLine2: action.payload?.addressLine2 || undefined,
            city: action.payload?.city || undefined,
            provinceName: action.payload?.provinceName || undefined,
            provinceCode: action.payload?.provinceCode || undefined,
            countryCode: action.payload?.countryCode || undefined,
            postalCode: action.payload?.postalCode || undefined,
            residential: action.payload?.residential,
            phoneNumber: action.payload?.phoneNumber || undefined,
            emailAddress: action.payload?.emailAddress || undefined,
            validationProviderId: action.payload?.validationProviderId || undefined,
            timezone: action.payload?.timezone || undefined,
          }
        : undefined,
    }),
    updatePackages: (state, action) => ({
      ...state,
      packages: {
        ...state.packages,
        unitOfMeasure: action.payload?.unitOfMeasure,
        pieces: action.payload?.pieces,
      },
    }),
    updateInternationalOptions: (state, action) => ({
      ...state,
      internationalOptions: action.payload,
    }),
    updateShippingOptions: (state, action) => {
      const result = {
        ...state,
        shippingOptions: action.payload,
      }
      if (result.shippingOptions?.shippingDate) {
        result.shippingOptions.shippingDate = action.payload?.shippingDate?.split(" ")[0]
      }
      return result
    },
    updateBillingOptions: (state, action) => ({
      ...state,
      billingOptions: action.payload,
    }),
    updateCommercialInvoice: (state, action) => ({
      ...state,
      commercialInvoice: action.payload,
    }),
    updateFreightOptions: (state, action) => ({
      ...state,
      freightOptions: action.payload,
    }),
    updateBroker: (state, action) => ({
      ...state,
      broker: action.payload
        ? {
            id: action.payload?.id || undefined,
            companyName: action.payload?.companyName || undefined,
            personName: action.payload?.personName || undefined,
            addressLine1: action.payload?.addressLine1 || undefined,
            addressLine2: action.payload?.addressLine2 || undefined,
            city: action.payload?.city || undefined,
            provinceName: action.payload?.provinceName || undefined,
            provinceCode: action.payload?.provinceCode || undefined,
            countryCode: action.payload?.countryCode || undefined,
            postalCode: action.payload?.postalCode || undefined,
            residential: action.payload?.residential,
            phoneNumber: action.payload?.phoneNumber || undefined,
            emailAddress: action.payload?.emailAddress || undefined,
            validationProviderId: action.payload?.validationProviderId || undefined,
          }
        : undefined,
    }),
    updateBuyer: (state, action) => ({
      ...state,
      buyer: action.payload
        ? {
            id: action.payload?.id || undefined,
            companyName: action.payload?.companyName || undefined,
            personName: action.payload?.personName || undefined,
            addressLine1: action.payload?.addressLine1 || undefined,
            addressLine2: action.payload?.addressLine2 || undefined,
            city: action.payload?.city || undefined,
            provinceName: action.payload?.provinceName || undefined,
            provinceCode: action.payload?.provinceCode || undefined,
            countryCode: action.payload?.countryCode || undefined,
            postalCode: action.payload?.postalCode || undefined,
            residential: action.payload?.residential,
            phoneNumber: action.payload?.phoneNumber || undefined,
            emailAddress: action.payload?.emailAddress || undefined,
            validationProviderId: action.payload?.validationProviderId || undefined,
          }
        : undefined,
    }),
    updateQuote: (state, action) => ({
      ...state,
      quoteInformation: action.payload,
    }),
    setSortParams: (state, { payload }) => ({
      ...state,
      sortParams: payload,
    }),
    setShowSpinner: (state, action) => ({
      ...state,
      showSpinner: action.payload,
    }),
    updateRates: (state, action) => ({
      ...state,
      rates: state.rates.concat(action.payload),
    }),
    clearRates: (state) => ({
      ...state,
      rates: [],
    }),
  },
  extraReducers: {
    RESET_QUOTATIONS: () => initialState,
  },
})

export const quotationsRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddresses: builder.query({
      query: (params) => ({ url: "/addresses/autocomplete", method: "GET", params: { ...params } }),
      transformResponse: (response) => {
        if (Array.isArray(response.data)) {
          response.data.forEach((entry) => {
            entry.origin = AddressSearchGroupsText.CanadaPost
          })
          return response
        }
        return response
      },
    }),
    getPackagingTypes: builder.query({
      query: () => ({ url: "/carriers/packaging", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getRates: builder.query({
      query: ({ payload, connectionId }) => ({ url: "/v2/carriers/generic/rate", headers: { connectionId }, method: "POST", body: payload }),
    }),
    getCurrencies: builder.query({
      query: () => ({ url: "/international/currencies", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetAddressesQuery, useGetPackagingTypesQuery, useGetRatesQuery, useGetCurrenciesQuery } = quotationsRestApiSlice

export const {
  clearRates,
  setShowSpinner,
  setSortParams,
  setAddNewPackageDisable,
  updateInternationalOptions,
  updatePackages,
  updateQuote,
  updateRates,
  updateRecipient,
  updateShipper,
  updateShippingOptions,
  updateBillingOptions,
  updateCommercialInvoice,
  updateFreightOptions,
  updateBroker,
  updateRateRequestId,
  updateBuyer,
} = quotationsSlice.actions

export default quotationsSlice.reducer
