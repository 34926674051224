import restApiSlice from "../rest-api/slice"

const invitationRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInvitation: builder.query({
      query: (invitationId) => ({ url: `/invitation/${invitationId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getInvitationsByCompany: builder.query({
      query: (companyId) => ({ url: `/invitations/${companyId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    updateUserInvitation: builder.mutation({
      query: ({ invitationId, updates }) => ({ url: `/invitation/${invitationId}`, method: "PATCH", body: updates }),
    }),
    deleteUserInvitation: builder.mutation({
      query: (invitationId) => ({ url: `/invitation/${invitationId}`, method: "DELETE" }),
    }),
    createUserInvitation: builder.mutation({
      query: (payload) => ({ url: `/invitation`, method: "POST", body: payload }),
    }),
  }),
})

export const { useGetUserInvitationQuery, useGetInvitationsByCompanyQuery, useUpdateUserInvitationMutation, useDeleteUserInvitationMutation, useCreateUserInvitationMutation } = invitationRestApiSlice

export default invitationRestApiSlice.reducer
