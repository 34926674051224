import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "SoeStripe", slot: "Root" })(() => ({}))

export const CustomCardContent = styled(CardContent, { name: "SoeCustomCardContent ", slot: "Root" })(({ theme }) => ({
  "&:last-child": {
    paddingBottom: theme.spacing(2),
  },
}))
