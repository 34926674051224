import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"
import React from "react"

import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import DateFnsTz from "date-fns-tz"
import BuyerCard from "../../../../../buyer-card"
import CommercialInvoiceCard from "../../../../../commercial-invoice-card"
import BrokerCard from "../../../../../broker-card"
import FreightOptionsCard from "../../../../../freight-options-card"
import InternationalOptionsCard from "../../../../../international-options-card"
import PackagesCard from "../../../../../packages-card"
import RateRequestProps from "../../../../../../models/rate-request"
import RecipientCard from "../../../../../recipient-card"
import ShipperCard from "../../../../../shipper-card"
import ShippingOptionsCard from "../../../../../shipping-options-card"
import BillingOptionsCard from "../../../../../billing-options-card"

import { ROUTES } from "../../../../../../utils/constants"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import {
  updateBroker,
  updateBuyer,
  updateCommercialInvoice,
  updateFreightOptions,
  updateInternationalOptions,
  updatePackages,
  updateRateRequestId,
  updateRecipient,
  updateShipper,
  updateShippingOptions,
  updateBillingOptions,
} from "../../../../../../reducers/quotationsReducer"

function RateRequestDetailsDrawer({ selectedRateRequest, showRateRequestDetailsDrawer, setShowRateRequestDetailsDrawer }) {
  const { t } = useTranslation("rateRequestHistory")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isInternationalOptions = (internationalOptions) => {
    let result = false
    Object.keys(internationalOptions).forEach((key) => {
      if (key !== "legacy" && key !== "toConvert") {
        result = true
      }
    })
    return result
  }

  const calculateShippingDate = (shippingDate, shippingDateTimezone) => {
    const shippingDateInTimezone = DateFnsTz.zonedTimeToUtc(shippingDate, shippingDateTimezone)
    const currentDateInTimezone = new Date()
    currentDateInTimezone.setHours(0, 0, 0, 0)
    if (currentDateInTimezone > shippingDateInTimezone) {
      const updatedDate = currentDateInTimezone.toLocaleDateString("sv-SE", { timeZone: shippingDateTimezone })
      return updatedDate
    }
    return shippingDate
  }

  const handleRequote = () => {
    dispatch(updateRateRequestId(selectedRateRequest.rateRequestId))
    dispatch(updateShipper(selectedRateRequest.shipper))
    dispatch(updateRecipient(selectedRateRequest.recipient))
    dispatch(updatePackages(selectedRateRequest.packages))

    const shippingOptions = {
      ...selectedRateRequest.shippingOptions,
      shippingDate: calculateShippingDate(selectedRateRequest.shippingOptions.shippingDate, selectedRateRequest.shippingOptions?.shippingDateTimezone),
    }

    dispatch(updateShippingOptions(shippingOptions))
    dispatch(updateBillingOptions(selectedRateRequest.billingOptions || { type: "myAccount" }))
    dispatch(updateFreightOptions(selectedRateRequest.freightOptions))
    if (!isEmpty(selectedRateRequest.internationalOptions) && isInternationalOptions(selectedRateRequest.internationalOptions)) {
      dispatch(updateInternationalOptions(selectedRateRequest.internationalOptions))
    } else {
      dispatch(updateInternationalOptions(undefined))
    }
    if (!isEmpty(selectedRateRequest.broker)) {
      dispatch(updateBroker(selectedRateRequest.broker))
    } else {
      dispatch(updateBroker(undefined))
    }
    if (!isEmpty(selectedRateRequest.commercialInvoice)) {
      dispatch(updateCommercialInvoice(selectedRateRequest.commercialInvoice))
    } else {
      dispatch(updateCommercialInvoice(undefined))
    }
    if (!isEmpty(selectedRateRequest.buyer)) {
      dispatch(updateBuyer(selectedRateRequest.buyer))
    } else {
      dispatch(updateBuyer(undefined))
    }
    setShowRateRequestDetailsDrawer(false)
    navigate(ROUTES.QUOTATIONS)
  }

  return (
    <SoeDrawer showDrawer={showRateRequestDetailsDrawer} setShowDrawer={setShowRateRequestDetailsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("rateRequestDetailsDrawer.title")}
          setShowDrawer={() => {
            setShowRateRequestDetailsDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <ShipperCard shipper={selectedRateRequest.shipper} isEditable={false} showPhoneNumber showEmailAddress />
          <RecipientCard recipient={selectedRateRequest.recipient} isEditable={false} showPhoneNumber showEmailAddress />
          <PackagesCard packages={selectedRateRequest.packages} isEditable={false} />
          <ShippingOptionsCard shippingOptions={selectedRateRequest.shippingOptions} isEditable={false} />
          {!isEmpty(selectedRateRequest.billingOptions) && <BillingOptionsCard billingOptions={selectedRateRequest.billingOptions} isEditable={false} />}
          {selectedRateRequest.freightOptions && <FreightOptionsCard freightOptions={selectedRateRequest.freightOptions} isEditable={false} />}
          {!isEmpty(selectedRateRequest.internationalOptions) && isInternationalOptions(selectedRateRequest.internationalOptions) && <InternationalOptionsCard internationalOptions={selectedRateRequest.internationalOptions} isEditable={false} />}
          {!isEmpty(selectedRateRequest.broker) && <BrokerCard broker={selectedRateRequest.broker} isEditable={false} />}
          {!isEmpty(selectedRateRequest.commercialInvoice) && <CommercialInvoiceCard commercialInvoice={selectedRateRequest.commercialInvoice} isEditable={false} />}
          {!isEmpty(selectedRateRequest.buyer) && <BuyerCard buyer={selectedRateRequest.buyer} isEditable={false} />}
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleRequote,
              label: t("rateRequestDetailsDrawer.actions.requote"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

RateRequestDetailsDrawer.propTypes = {
  selectedRateRequest: RateRequestProps.isRequired,
  showRateRequestDetailsDrawer: PropTypes.bool.isRequired,
  setShowRateRequestDetailsDrawer: PropTypes.func.isRequired,
}

export default RateRequestDetailsDrawer
