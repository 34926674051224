// REACT
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Masonry from "@mui/lab/Masonry"
import Typography from "@mui/material/Typography"

// Custom theme
import { useTheme } from "@mui/material"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"

// Others
import MonetaryInfoBillingContactCard from "./components/monetary-info-billing-contact-card"
import MonetaryInfoBillingPrefCard from "./components/monetary-info-billing-pref-card"
import MonetaryInfoBillingPrefDrawer from "./components/monetary-info-billing-pref-drawer"
import MonetaryInfoBillingContactDrawer from "./components/monetary-info-billing-contact-drawer"
import ThirdPartyBillingAccounts from "./components/third-party-billing-accounts"
import ThirdPartyBillingAccountsDrawer from "./components/third-party-billing-accounts-drawer"
import BoxDimensionCard from "./components/box-dimension-card"
import BoxDimensionDrawer from "./components/box-dimension-drawer"
import { useGetUserProfileQuery } from "../../../../services/user/slice"

function UserProfileMonetaryInfo() {
  const { refetch: refetchUserProfile } = useGetUserProfileQuery()
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()
  const currentUser = useSelector((state) => state.user)

  const [showMonetaryInfoBillingContactDrawer, setShowMonetaryInfoBillingContactDrawer] = useState(false)
  const [showThirdPartyBillingAccountsDrawer, setShowThirdPartyBillingAccountsDrawer] = useState(false)
  const [showMonetaryInfoBillingPrefDrawer, setShowMonetaryInfoBillingPrefDrawer] = useState(false)
  const [showBoxDimensionDrawer, setShowBoxDimensionDrawer] = useState(false)

  const [onSubmitEvent, setSubmitEvent] = useState()
  const handleMonetaryInfoBillingContactCardClick = () => {
    setSubmitEvent()
    setShowMonetaryInfoBillingContactDrawer(true)
  }

  const handleMonetaryInfoBillingPrefCardClick = () => {
    setSubmitEvent()
    setShowMonetaryInfoBillingPrefDrawer(true)
  }

  const handleThirdPartyBillingAccountsClick = () => {
    setSubmitEvent()
    setShowThirdPartyBillingAccountsDrawer(true)
  }

  const handleBoxDimensionCardClick = () => {
    setSubmitEvent()
    setShowBoxDimensionDrawer(true)
  }

  return (
    <Box component="div">
      <Box display="flex" pt={customTheme.utils.pxToThemeSpacing(20)} pr={customTheme.utils.pxToThemeSpacing(10)} pb={customTheme.utils.pxToThemeSpacing(20)} pl={customTheme.utils.pxToThemeSpacing(10)}>
        <Box component="h5">
          <Typography sx={(theme) => ({ fontSize: customTheme.utils.pxToRem(18), fontWeight: theme.typography.fontWeightBold })}>{t("userProfile.monetaryInformation.title.label")}</Typography>
        </Box>
      </Box>
      <Box pl={customTheme.utils.pxToThemeSpacing(6)} sx={{ display: "flex" }}>
        <Masonry columns={4} sx={{ alignContent: "flex-start" }}>
          {currentUser.companyRole !== 'SHIP' && (
            <>
              <MonetaryInfoBillingContactCard handleMonetaryInfoBillingContactCardClick={() => handleMonetaryInfoBillingContactCardClick()} setShowMonetaryInfoBillingContactDrawer={setShowMonetaryInfoBillingContactDrawer} />
              <MonetaryInfoBillingPrefCard handleMonetaryInfoBillingPrefCardClick={() => handleMonetaryInfoBillingPrefCardClick()} />
            </>
          )}
          <ThirdPartyBillingAccounts handleThirdPartyBillingAccountsClick={() => handleThirdPartyBillingAccountsClick()} setShowThirdPartyBillingAccountsDrawer={setShowThirdPartyBillingAccountsDrawer} />
          <BoxDimensionCard handleBoxDimensionCardClick={() => handleBoxDimensionCardClick()} setShowBoxDimensionDrawer={setShowBoxDimensionDrawer} />
        </Masonry>
      </Box>
      {/* Billing Contact drawer */}
      <SoeDrawer showDrawer={showMonetaryInfoBillingContactDrawer} setShowDrawer={setShowMonetaryInfoBillingContactDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.monetaryBillingContactDrawer.title")}
            setShowDrawer={() => {
              setShowMonetaryInfoBillingContactDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <MonetaryInfoBillingContactDrawer onSubmitEvent={onSubmitEvent} setShowMonetaryInfoBillingContactDrawer={setShowMonetaryInfoBillingContactDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.monetaryBillingContactDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      {/* Third Party account numbers */}
      <SoeDrawer showDrawer={showThirdPartyBillingAccountsDrawer} setShowDrawer={setShowThirdPartyBillingAccountsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.thirdPartyBillingAccountsDrawer.title")}
            setShowDrawer={() => {
              setShowThirdPartyBillingAccountsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <ThirdPartyBillingAccountsDrawer onSubmitEvent={onSubmitEvent} setShowThirdPartyBillingAccountsDrawer={setShowThirdPartyBillingAccountsDrawer} refetchUserProfile={refetchUserProfile} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.thirdPartyBillingAccountsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      {/* Bill preference currency drawer */}
      <SoeDrawer showDrawer={showMonetaryInfoBillingPrefDrawer} setShowDrawer={setShowMonetaryInfoBillingPrefDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.monetaryBillingPrefInfoCard.title")}
            setShowDrawer={() => {
              setShowMonetaryInfoBillingPrefDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <MonetaryInfoBillingPrefDrawer onSubmitEvent={onSubmitEvent} setShowMonetaryInfoBillingPrefDrawer={setShowMonetaryInfoBillingPrefDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.monetaryBillingPrefInfoDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      {/* Box Dimensions */}
      <SoeDrawer showDrawer={showBoxDimensionDrawer} setShowDrawer={setShowBoxDimensionDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.boxDimensionDrawer.title")}
            setShowDrawer={() => {
              setShowBoxDimensionDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <BoxDimensionDrawer onSubmitEvent={onSubmitEvent} setShowBoxDimensionDrawer={setShowBoxDimensionDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.boxDimensionDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Box>
  )
}

export default UserProfileMonetaryInfo
