import restApiSlice from "../rest-api/slice"
import store from "../../store"
import { updateCompanyDetailsSuccess, fetchCompanyDetailsSuccess, fetchCompanyUsersSuccess } from "../../reducers/companyReducer"

const companyRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyProfile: builder.query({
      query: (companyId) => ({ url: `/organization/${companyId}`, method: "GET" }),
      providesTags: ["company"],
      transformResponse: (response) => {
        store.dispatch(fetchCompanyDetailsSuccess(response.data))
        return response.data
      },
    }),
    editCompanyProfile: builder.mutation({
      query: ({ companyId, ...payload }) => ({ url: `/organization/${companyId}`, method: "PATCH", body: payload }),
      invalidatesTags: ["company"],
      transformResponse: (response) => {
        store.dispatch(updateCompanyDetailsSuccess(response.data))
        return response.data
      },
    }),
    getCompanyUsers: builder.query({
      query: (companyId) => ({ url: `/organization/${companyId}/users`, method: "GET" }),
      providesTags: ["company"],
      transformResponse: (response) => {
        store.dispatch(fetchCompanyUsersSuccess(response.data))
        return response.data
      },
    }),
  }),
})

export const { useEditCompanyProfileMutation, useGetCompanyProfileQuery, useGetCompanyUsersQuery } = companyRestApiSlice

export default companyRestApiSlice.reducer
