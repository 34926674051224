// REACT
import React from "react"
import { useTranslation } from "react-i18next"

// Mui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CardContent from "@mui/material/CardContent"

function ResetPasswordHeader() {
  const { t } = useTranslation("authenticator")

  return (
    <Box flexDirection="column" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1 })}>
      <Box
        className="primaryLabel"
        pb={2}
        sx={(theme) => ({
          width: "37rem",
          textAlign: "center",
          margin: "auto",
          marginTop: theme.utils.pxToRem(240),
        })}
      >
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: "800",
            fontSize: theme.utils.pxToRem(50),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
          })}
        >
          {t("forgotPassword.title.lable")}
        </Typography>
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: "800",
            fontSize: theme.utils.pxToRem(50),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            padding: "0 5px",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
            display: "inline",
            background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, #A7E6D7 60%, #A7E6D7 80%, rgba(255,255,0,0) 80%, rgba(255,255,0,0) 100%)",
          })}
        >
          {t("forgotPassword.title.underline")}
        </Typography>
      </Box>
      <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(16) })} />
      <Typography
        className="secondaryLabel"
        component="h4"
        pb={2}
        sx={(theme) => ({
          color: theme.palette.inexTertiary.main,
          fontSize: theme.utils.pxToRem(18),
          lineHeight: theme.utils.pxToLineHeight(20, 24),
          justifyContent: "center",
          fontFamily: "Poppins",
          width: "28rem",
          textAlign: "center",
          margin: "auto",
        })}
      >
        {t("forgotPassword.sendEmail")}
      </Typography>
    </Box>
  )
}

export default ResetPasswordHeader
