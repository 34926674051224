import PropTypes from "prop-types"

// REACT
import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import ModalAlert from "../../../modal/components/modal-alert"
import { MainCustomButton, CustomTextField } from "./style"

import { forgotPassword } from "../../../../reducers/authenticatorReducer"

import emailIcon from "../../../../assets/images/envelope.svg"

function ResetPasswordForm({ handleSendEmail }) {
  const customTheme = useTheme()
  const { t } = useTranslation("authenticator")
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.email) {
        errors.email = t("forgotPassword.fields.email.error.required")
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = t("forgotPassword.fields.email.error.invalid")
      }
      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(forgotPassword(formValues)).then((response) => {
        if (!response?.error) handleSendEmail(formValues.email)
      })
    },
  })

  return (
    <Box className="signUp" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(425) })}>
          <ModalAlert />
          <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <CustomTextField
                id="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={emailIcon} alt="email-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("forgotPassword.fields.email.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email !== undefined}
                helperText={(formik.touched.email && formik.errors.email) || " "}
                required
                autoFocus
              />
              <CardContent sx={{ p: customTheme.utils.pxToRem(20) }} />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <MainCustomButton type="submit" variant="outlined" size="medium">
                  <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                    {t("forgotPassword.fields.forgotPasswordButton.label")}
                  </Typography>
                </MainCustomButton>
              </Box>
              <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(16) })} />
            </form>
          </CardContentNoPaddingBottom>
        </Box>
      </Grid>
    </Box>
  )
}

ResetPasswordForm.propTypes = {
  handleSendEmail: PropTypes.func.isRequired,
}

export default ResetPasswordForm
