// REACT
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"

// MUI
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import ModalAlert from "../../../modal/components/modal-alert"
import { MainCustomButton, CustomTextField, CustomModalContainerBox } from "./style"
import ConfirmSignUp from "../../../sign-up/components/confirm-sign-up"

import { signUp } from "../../../../reducers/authenticatorReducer"

import passwordIcon from "../../../../assets/images/padlock.svg"
import passwordEyeIcon from "../../../../assets/images/password-eye.svg"
import { useGetUserInvitationQuery } from "../../../../services/invitation/slice"

import AppConfig from "../../../../utils/app-config"

function InvitationSignUp({ invitationId, setCurrentCompany }) {
  const customTheme = useTheme()
  const { t } = useTranslation("authenticator")
  const dispatch = useDispatch()

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [passwordFieldType, setPasswordFieldType] = useState("password")
  const [confPasswordFieldType, setConfPasswordFieldType] = useState("password")
  const { data: getInvitationResponse } = useGetUserInvitationQuery(invitationId)

  useEffect(() => {
    if (getInvitationResponse?.companyName) {
      setCurrentCompany(getInvitationResponse.companyName)
    }
  }, [getInvitationResponse])

  const handleEyeClick = (field, setField) => {
    if (field === "password") {
      setField("text")
    } else {
      setField("password")
    }
  }

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false)
  }

  const handleOpenConfirmDialog = () => {
    setIsConfirmDialogOpen(true)
  }

  const handleTermsAndConditionsLinkClick = () => {
    return window.open("https://inex.ca/terms/", "_blank") || window.location.replace("https://inex.ca/terms/")
  }

  const handlePrivacyPolicyLinkClick = () => {
    return window.open("https://inex.ca/privacy-policy/", "_blank") || window.location.replace("https://inex.ca/privacy-policy/")
  }

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      termsConditionsChecked: false,
      recaptchaCheck: false,
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.password) {
        errors.password = t("inexSignUp.fields.password.error.required")
      }
      if (!formValues.confirmPassword) {
        errors.confirmPassword = t("inexSignUp.fields.confirmPassword.error.required")
      }
      if (formValues.password !== formValues.confirmPassword) {
        errors.confirmPassword = t("inexSignUp.fields.confirmPassword.error.passwordMatch")
      }
      if (!formValues.recaptchaCheck) {
        errors.recaptchaCheck = t("inexSignUp.fields.recaptcha.error.required")
      }
      if (!formValues.termsConditionsChecked) {
        errors.termsConditionsChecked = t("inexSignUp.fields.password.error.required")
      }
      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (formValues) => {
      const values = {
        ...formValues,
        email: getInvitationResponse?.email,
        companyName: getInvitationResponse?.companyName,
        personName: getInvitationResponse?.personName,
        invitationId: invitationId || "1",
      }

      dispatch(signUp(values)).then((response) => {
        if (!response.error) handleOpenConfirmDialog()
      })
    },
  })

  return (
    <Box className="signUp" component="div" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(425) })}>
          <ModalAlert />
          <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <CustomTextField
                id="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => handleEyeClick(passwordFieldType, setPasswordFieldType)}>
                      <img src={passwordEyeIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignUp.fields.password.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password !== undefined}
                helperText={(formik.touched.password && formik.errors.password) || " "}
                fullWidth
                required
                type={passwordFieldType}
              />
              <CustomTextField
                id="confirmPassword"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => handleEyeClick(confPasswordFieldType, setConfPasswordFieldType)}>
                      <img src={passwordEyeIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignUp.fields.confirmPassword.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={formik.touched.confirmPassword && formik.errors.confirmPassword !== undefined}
                helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || " "}
                fullWidth
                required
                color={customTheme.palette.inexTertiary.main}
                type={confPasswordFieldType}
              />
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.termsConditionsChecked}
                      onChange={() => formik.setFieldValue("termsConditionsChecked", !formik.values.termsConditionsChecked)}
                      sx={{ color: customTheme.palette.inexTertiary.lighter, "&.Mui-checked": { color: customTheme.palette.inexPrimary.main } }}
                    />
                  }
                  label={
                    <Box style={{ color: customTheme.palette.inexTertiary.main, fontFamily: "Poppins", fontSize: customTheme.utils.pxToRem(18) }}>
                      {t("inexSignUp.confirmationText")}
                      <Link onClick={handleTermsAndConditionsLinkClick} style={{ color: customTheme.palette.inexPrimary.main }}>
                        {t("inexSignUp.termsAndConditions")}
                      </Link>
                      {t("inexSignUp.and")}
                      <Link onClick={handlePrivacyPolicyLinkClick} style={{ color: customTheme.palette.inexPrimary.main }}>
                        {t("inexSignUp.privacyPolicy")}
                      </Link>
                      .
                    </Box>
                  }
                />
                {formik.touched.termsConditionsChecked && formik.errors.termsConditionsChecked !== undefined && <FormHelperText sx={{ color: "#FF5252" }}>{t("inexSignUp.termsAndConditionsError")}</FormHelperText>}
              </Box>

              <div className="captcha" style={{ transform: "scale(1.31)", transformOrigin: "0 0" }}>
                <ReCAPTCHA sitekey={AppConfig.recaptcha.siteId} onChange={(value) => formik.setFieldValue("recaptchaCheck", !!value)} />
              </div>
              <CardContent sx={{ p: customTheme.utils.pxToRem(20) }} />
              <MainCustomButton style={{ display: "flex", margin: "auto" }} type="submit" variant="outlined" size="medium" disabled={!formik.values.termsConditionsChecked || !formik.values.recaptchaCheck}>
                <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                  {t("inexSignUp.fields.signUpButton.label")}
                </Typography>
              </MainCustomButton>
              <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(16) })} />
            </form>
          </CardContentNoPaddingBottom>
        </Box>
      </Grid>

      <Modal open={isConfirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <CustomModalContainerBox className="authContainer" component="div">
          <ConfirmSignUp />
        </CustomModalContainerBox>
      </Modal>
    </Box>
  )
}

InvitationSignUp.propTypes = {
  invitationId: PropTypes.string,
  setCurrentCompany: PropTypes.func,
}

InvitationSignUp.defaultProps = {
  invitationId: "",
  setCurrentCompany: () => {},
}

export default InvitationSignUp
