import PropTypes from "prop-types"

// React
import React from "react"

// Mui
import Box from "@mui/material/Box"

import "./style.scss"

function NumericBoxInput({ onChange, fieldValue }) {
  const handlePaste = (event) => {
    const inputs = document.querySelectorAll("#otp > *[id]")
    event.stopPropagation()
    event.preventDefault()
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData("Text")

    for (let i = 0; i < 6; i += 1) {
      if (pastedData[i]) {
        inputs[i].value = pastedData[i]
      } else {
        inputs[i].value = ""
      }
    }

    inputs[pastedData.length - 1 > 5 ? 5 : pastedData.length - 1].focus()
    onChange(fieldValue, pastedData)
  }

  const handleInputChange = (event) => {
    const inputs = document.querySelectorAll("#otp > *[id]")

    const i = Number(event.target.id) - 1

    if (event.key === "Backspace") {
      inputs[i].value = ""
      if (i !== 0) inputs[i - 1].focus()
    } else if ((event.keyCode > 47 && event.keyCode < 58) || (event.keyCode > 95 && event.keyCode < 106)) {
      inputs[i].value = event.key
      if (i !== inputs.length - 1) inputs[i + 1].focus()
      event.preventDefault()
    } else {
      return true
    }

    if (inputs) {
      const result = Array.from(inputs).reduce((acc, curr) => {
        acc += curr.value
        return acc
      }, "")

      onChange(fieldValue, result)
    }

    return true
  }

  return (
    <Box className="text-center">
      <Box id="otp" className="flex justify-center">
        <input autoComplete="one-time-code" onKeyDown={handleInputChange} onPaste={handlePaste} className="m-2 text-center form-control form-control-solid rounded" type="text" id="1" maxLength="1" />
        <input autoComplete="one-time-code" onKeyDown={handleInputChange} onPaste={handlePaste} className="m-2 text-center form-control form-control-solid rounded" type="text" id="2" maxLength="1" />
        <input autoComplete="one-time-code" onKeyDown={handleInputChange} onPaste={handlePaste} className="m-2 text-center form-control form-control-solid rounded margin-right" type="text" id="3" maxLength="1" />
        <input autoComplete="one-time-code" onKeyDown={handleInputChange} onPaste={handlePaste} className="m-2 text-center form-control form-control-solid rounded" type="text" id="4" maxLength="1" />
        <input autoComplete="one-time-code" onKeyDown={handleInputChange} onPaste={handlePaste} className="m-2 text-center form-control form-control-solid rounded" type="text" id="5" maxLength="1" />
        <input autoComplete="one-time-code" onKeyDown={handleInputChange} onPaste={handlePaste} className="m-2 text-center form-control form-control-solid rounded" type="text" id="6" maxLength="1" />
      </Box>
    </Box>
  )
}

NumericBoxInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldValue: PropTypes.string.isRequired,
}

export default NumericBoxInput
