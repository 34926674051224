import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { useFormik } from "formik"
import { Box, IconButton, MenuItem, TableCell, TableRow, TextField, Tooltip } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import {
  useCreateUserInvitationMutation,
  useDeleteUserInvitationMutation,
  useUpdateUserInvitationMutation
} from "../../../../services/invitation/slice"

// ICONS
import { ReactComponent as CheckIcon } from "../../../../assets/images/check.svg"
import { ReactComponent as CrossIcon } from "../../../../assets/images/cross.svg"

// Style
import { CustomSelectField } from "../../style"

function InvitationItem({ invitation, invitations, inviteUserProgess, companyName, setInviteUserProgess, refetchUsers, users, refetchInvitations }) {
  const { t, i18n } = useTranslation("company")
  const [companyRole, setCompanyRole] = useState(invitation.companyRole || "ADMIN")
  const [status, setStatus] = useState(invitation.status || "Pending")
  const [updateInvitation] = useUpdateUserInvitationMutation()
  const [deleteUserInvitation] = useDeleteUserInvitationMutation()
  const [createInvitation] = useCreateUserInvitationMutation()
  const customTheme = useTheme()

  const formik = useFormik({
    initialValues: { email: "", personName: ""},
    validate: (formValues) => {
      const errors = {}
      if (!formValues.email) {
        errors.email = t("form.email.error.required")
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = t("form.email.error.invalid")
      } else if (users?.some((u) => u.emailAddress === formValues.email || (Array.isArray(invitations) && invitations?.some((a) => a.email === formValues.email && a.status === "accepted")))) {
        errors.email = t("form.email.error.alreadyExists")
      }
      if (!formValues.personName) {
        errors.personName = t("form.personName.error.required")
      }
      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async () => {
      const { email, personName } = formik.values
      createInvitation({ companyName, email, personName, status: "sent", companyRole })
      setInviteUserProgess("")
      setTimeout(() => {
        setStatus("sent")
        refetchInvitations()
      }, 300)
    },
  })

  const handleCompanyRoleChange = (event) => {
    setCompanyRole(event.target.value)
  }

  const toLocaleDateString = (date) => {
    const newDate = date ?? new Date()
    return new Date(newDate).toLocaleDateString(i18n.language, {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleRequestOrUpdateInvitation = (newStatus) => {
    if (inviteUserProgess && newStatus==="rejected") {
      // delete invitation in progress
      setInviteUserProgess("")
    }
    else if (inviteUserProgess) {
      formik.handleSubmit()
    } else {

      updateInvitation({
        invitationId: invitation.id,
        updates: { companyRole, status: newStatus },
      })
      refetchUsers()
      setTimeout(() => setStatus(newStatus), 300)
    }
  }

  const handleDeleteInvitation = async () => {
    await deleteUserInvitation(invitation.id)
    setStatus("deleted")
    refetchInvitations()
  }

  const getStatusColor = (statusItem) => {
    const statusesMapping = {
      requested: "orange",
      sent: "#157CC5",
      accepted: "green",
      rejected: "red",
    }
    return statusesMapping[statusItem]
  }
  return (
    <TableRow
      sx={(theme) => ({
        backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
      })}
    >
      <TableCell component="td" scope="row">
        {inviteUserProgess === "show_input" ? (
          <TextField
            style={{ width: "inherit" }}
            id="personName"
            inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToRem(24) } }}
            label={t("form.personName.label")}
            variant="outlined"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.personName}
            error={formik.touched.personName && formik.errors.personName !== undefined}
            helperText={(formik.touched.personName && formik.errors.personName) || " "}
            required
            autoFocus
          />
        ) : (
          invitation.personName || formik.values.personName || "-"
        )}
      </TableCell>
      <TableCell component="td" scope="row">
        {inviteUserProgess === "show_input" ? (
          <TextField
            style={{ width: "inherit" }}
            id="email"
            inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToRem(24) } }}
            label={t("form.email.label")}
            variant="outlined"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && formik.errors.email !== undefined}
            helperText={(formik.touched.email && formik.errors.email) || " "}
            required
            autoFocus
          />
        ) : (
          invitation.email || formik.values.email
        )}
      </TableCell>
      <TableCell component="td" scope="row">
      {inviteUserProgess === "show_input" ? (
        <CustomSelectField labelId="demo-simple-select-label" id="demo-simple-select" value={companyRole} label="companyRole" onChange={handleCompanyRoleChange}>
          <MenuItem value="ADMIN">Admin</MenuItem>
          <MenuItem value="SHIP">Shipping User</MenuItem>
          <MenuItem value="BILL">Billing User</MenuItem>
        </CustomSelectField>
        ) : (
          (t(`companyDetails.fields.companyRole.${companyRole}`))
        )}
      </TableCell>
      <TableCell component="td" scope="row" style={{ color: getStatusColor(status) }}>
        {capitalize(status)}
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">{toLocaleDateString(invitation.modifiedDate || invitation.createdDate)}</Box>
      </TableCell>
      <TableCell component="td" scope="row">
        {(["requested"].includes(status) || inviteUserProgess === "show_input") && (
          <Tooltip title={["requested"].includes(status) ? "ACCEPT" : "SEND INVITATION"} placement="top">
            <IconButton variant="outlined" onClick={() => handleRequestOrUpdateInvitation("accepted")} sx={{ boxShadow: "2", marginRight: 10, height: "35px" }}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        )}
        {!["rejected", "accepted"].includes(status) && (
          <Tooltip title={status === "requested" ? "REJECT" : "DELETE INVITATION"} placement="top">
            <IconButton variant="outlined" onClick={() => status==="sent" ? handleDeleteInvitation() : handleRequestOrUpdateInvitation("rejected")} sx={{ boxShadow: "2", marginRight: 10, height: "35px" }}>
              <CrossIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  )
}

InvitationItem.defaultProps = {
  invitation: {},
  inviteUserProgess: "",
  companyName: "",
  setInviteUserProgess: () => {},
  users: [],
  invitations: [],
  refetchUsers: () => {},
  refetchInvitations: () => {},
}

InvitationItem.propTypes = {
  invitation: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    personName: PropTypes.string,
    companyRole: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedDate: PropTypes.string,
  }),
  invitations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      status: PropTypes.string,
      personName: PropTypes.string,
      companyRole: PropTypes.string,
      createdDate: PropTypes.string,
      modifiedDate: PropTypes.string,
    })
  ),
  inviteUserProgess: PropTypes.string,
  companyName: PropTypes.string,
  setInviteUserProgess: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.arrayOf(PropTypes.object),
  refetchUsers: PropTypes.func,
  refetchInvitations: PropTypes.func,
}

export default InvitationItem
