import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

import { styled } from "@mui/material/styles"

export const MainCustomButton = styled(Button, { label: "MainCustomButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: theme.palette.inexPrimary.main,
  color: "white",
  textTransform: "uppercase",
  boxShadow: "0px 3px 6px #00000029",
  borderColor: theme.palette.inexPrimary.main,
  width: "150px",
  height: "47.2px",
  "&:hover": {
    backgroundColor: theme.palette.inexSecondary.main,
    borderColor: theme.palette.inexSecondary.main,
    color: "white",
  },
  "&:disabled": {
    opacity: 0.6,
    color: "white",
  },
}))

export const SecondaryCustomButton = styled(Button, { label: "SecondaryCustomButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.inexPrimary.main,
  textTransform: "uppercase",
  boxShadow: "0px 3px 6px #00000029",
  borderColor: theme.palette.inexPrimary.main,
  width: "150px",
  height: "47.2px",
  "&:hover": {
    backgroundColor: "white",
    borderColor: "white",
    color: theme.palette.inexSecondary.main,
  },
}))

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: theme.utils.pxToRem(20),
    height: theme.utils.pxToRem(55),
  },
  "& .MuiInputBase-root": {
    borderRadius: "3px",
    fontFamily: "Poppins",
    height: theme.utils.pxToRem(55),
  },
  "& label.Mui-focused": {
    color: theme.palette.inexTertiary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.inexTertiary.main,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.inexTertiary.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.inexTertiary.main,
    },
    "&.Mui-focused fieldset": {
      border: `solid 1px ${theme.palette.inexPrimary.main}`,
    },
  },
}))

export const CustomSelectField = styled(Select)(({ theme }) => ({
    "& .MuiInputBase-input": {
      fontSize: theme.utils.pxToRem(20),
    },
    "& .MuiInput-underline:before": {
      borderRadius: "3px !important",
      fontFamily: "Poppins",
      height: theme.utils.pxToRem(55),
    },
    "& label.Mui-focused": {
      color: theme.palette.inexTertiary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.inexTertiary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.inexTertiary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.inexTertiary.main,
      },
      "&.Mui-focused fieldset": {
        border: `solid 1px ${theme.palette.inexPrimary.main}`,
      },
    },
  }))
  
