import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import MoreMenu from "../../../more-menu"

function AddressBookItem({ addressBookItem, setShowAddressBookDrawer, setSelectedAddressBookItem, setShowConfirmationDialog }) {
  const { t } = useTranslation("addressBook")

  const handleEdit = () => {
    setSelectedAddressBookItem(addressBookItem)
    setShowAddressBookDrawer(true)
  }

  const handleDelete = () => {
    setSelectedAddressBookItem(addressBookItem)
    setShowConfirmationDialog(true)
  }

  const sideMenuOptionList = [
    {
      label: t("sideMenu.modify"),
      handleClick: handleEdit,
    },
    {
      label: t("sideMenu.delete"),
      handleClick: handleDelete,
    },
  ]

  return (
    <TableRow hover component="tr" sx={{ backgroundColor: "white", cursor: "pointer" }} onClick={handleEdit}>
      <TableCell component="td" scope="row">
        {addressBookItem.companyName}
      </TableCell>
      <TableCell component="td" scope="row">
        {addressBookItem.personName}
      </TableCell>
      <TableCell component="td" scope="row">
        {addressBookItem.addressLine1}
      </TableCell>
      <TableCell component="td" scope="row">
        {addressBookItem.addressLine2}
      </TableCell>
      <TableCell component="td" scope="row">
        {addressBookItem.city}
      </TableCell>
      <TableCell component="td" scope="row">
        {addressBookItem.provinceCode}
      </TableCell>
      <TableCell component="td" scope="row">
        {addressBookItem.countryCode}
      </TableCell>
      <TableCell component="td" scope="row">
        {addressBookItem.postalCode}
      </TableCell>
      <TableCell component="td" scope="row">
        {t(`headers.types.${addressBookItem.type.toLowerCase()}`)}
      </TableCell>
      <TableCell component="td" scope="row" align="right">
        <MoreMenu optionsList={sideMenuOptionList} />
      </TableCell>
    </TableRow>
  )
}

AddressBookItem.propTypes = {
  addressBookItem: PropTypes.shape({
    companyName: PropTypes.string,
    personName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    provinceCode: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  setShowAddressBookDrawer: PropTypes.func,
  setSelectedAddressBookItem: PropTypes.func,
  setShowConfirmationDialog: PropTypes.func,
}

AddressBookItem.defaultProps = {
  setShowAddressBookDrawer: () => {},
  setSelectedAddressBookItem: () => {},
  setShowConfirmationDialog: () => {},
}

export default AddressBookItem
