import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  pk: null,
  sk: null,
  userId: null,
  createdDate: null,
  creatorEmail: null,
  companyName: null,
  entityType: null,
  users: [],
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setInitialState: () => initialState,
    fetchCompanyDetailsSuccess: (state, action) => {
      return { ...initialState, users: state.users, ...action.payload };
    },
    fetchCompanyUsersSuccess: (state, action) => {
      return { ...state, users: action.payload };
    },
    updateCompanyDetailsSuccess: (state, action) => {
      return { ...state, ...action.payload };
    },

    // TODO: will need to refactor this part to make the reducer handle multiple fields
    // and not have to create one per field and so on
    updateUserRoleSuccess: (state, action) => {
      const userIndex = state.users.findIndex((u) => u.pk === action.payload.userId);
      if (userIndex !== -1) {
        state.users[userIndex] = {
          ...state.users[userIndex],
          companyRole: action.payload.companyRole,
        };
      }
    },
    updateUserAccountStatusSuccess: (state, action) => {
        const userIndex = state.users.findIndex((u) => u.pk === action.payload.userId);
        if (userIndex !== -1) {
          state.users[userIndex] = {
            ...state.users[userIndex],
            disabled: action.payload.disabled,
          };
        }
      },
  },
});

export const {
  setInitialState,
  updateCompanyDetailsSuccess,
  fetchCompanyDetailsSuccess,
  fetchCompanyUsersSuccess,
  updateUserRoleSuccess,
  updateUserAccountStatusSuccess,
} = companySlice.actions;

export default companySlice.reducer;
