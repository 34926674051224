import { Checkbox as MuiCheckbox } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import FormHelperText from "@mui/material/FormHelperText"
import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import TranslationService from "../../../../../../../../../../services/translation"

function Checkbox({ formik, fieldFormat }) {
  const { i18n } = useTranslation()
  const customTheme = useTheme()

  const SPACING = 10
  const size = fieldFormat?.grid || 12
  const label = fieldFormat?.label && TranslationService.getLabelFromLocale(i18n.language, fieldFormat.label)
  const name = fieldFormat?.name

  useEffect(() => {
    if (formik.values.additionalFields?.[name] === undefined) formik.setFieldValue(`additionalFields.${name}`, false)
  }, [formik.values.additionalFields]?.[name])

  return (
    <Grid item xs={12} md={size} my={customTheme.utils.pxToThemeSpacing(SPACING)}>
      <FormControl error={formik.touched.additionalFields?.[name] && formik.errors[name] !== undefined} component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <MuiCheckbox
                name={name}
                checked={formik.values.additionalFields?.[name] || false}
                onChange={(event) => formik.setFieldValue(`additionalFields.${name}`, event.target.checked)}
                sx={(theme) => ({
                  "&.Mui-checked": {
                    color: theme.palette.contentActionDefault.main,
                  },
                })}
              />
            }
            label={label}
          />
        </FormGroup>
        <FormHelperText>{(formik.touched.additionalFields?.[name] && formik.errors[name]) || ""}</FormHelperText>
      </FormControl>
    </Grid>
  )
}

Checkbox.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  fieldFormat: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string.isRequired,
      fr: PropTypes.string,
    }).isRequired,
    name: PropTypes.string.isRequired,
    grid: PropTypes.number.isRequired,
    form: PropTypes.shape({
      attributes: PropTypes.shape({
        inputType: PropTypes.string,
        maxlength: PropTypes.string,
      }),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.instanceOf(Object),
        })
      ),
    }),
    validations: PropTypes.shape({
      isRequired: PropTypes.bool,
      maxLength: PropTypes.number,
      minLength: PropTypes.number,
      regex: PropTypes.shape({
        error: PropTypes.shape({
          code: PropTypes.string,
          messages: PropTypes.shape({
            en: PropTypes.string.isRequired,
            fr: PropTypes.string,
          }),
        }),
        test: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default Checkbox
