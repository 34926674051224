// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AppConfig from "../../../../../../../../utils/app-config"
import Country from "./components/country"
import CustomTextField from "../../../rate-request-packages/components/package-form/style"

import { UnitsOfMeasureEnum } from "../../../../../../../../services/units-conversion"

import StyledOpenInNewTab from "./style"

function InvoiceForm({ formik, index }) {
  const { t } = useTranslation(["rateRequest"])
  const customTheme = useTheme()

  const SPACING = 12

  const handleAdornmentBackgroundColor = (value) => {
    if (value !== undefined) return customTheme.palette.contentAlertError.main
    if (formik.values.unitOfMeasure === UnitsOfMeasureEnum.IMPERIAL) return customTheme.palette.contentActionDefault.main
    return customTheme.palette.contentAlertPositiveDarker.main
  }

  const openInNewTab = () => {
    window.open(AppConfig.externalLinks.harmonizedSystemSearchUrl, "_blank")
  }

  return (
    <Box component="div" sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
      <Box component="div" mb={customTheme.utils.pxToThemeSpacing(SPACING)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <TextField
          id="description"
          name={`invoiceItems[${index}].description`}
          value={formik.values.invoiceItems[index].description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched?.invoiceItems && formik.touched?.invoiceItems[index]?.description && formik.errors?.invoiceItems && formik.errors?.invoiceItems[index]?.description}
          label={t("drawer.invoiceItems.fields.description.label", { ns: "rateRequest" })}
          variant="outlined"
          size="small"
        />
      </Box>
      <Box component="div" mb={customTheme.utils.pxToThemeSpacing(SPACING)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box component="div">
          <TextField
            id="hsCode"
            name={`invoiceItems[${index}].hsCode`}
            value={formik.values.invoiceItems[index].hsCode}
            onChange={(e) => {
              const regEx = /^(\d*\.?\d*)*$/
              if (new RegExp(regEx, "g").test(e.target.value)) {
                formik.setFieldValue(`invoiceItems[${index}].hsCode`, e.target.value)
              }
            }}
            onBlur={formik.handleBlur}
            error={formik.touched?.invoiceItems && formik.touched?.invoiceItems[index]?.hsCode && formik.errors?.invoiceItems && formik.errors?.invoiceItems[index]?.hsCode}
            label={t("drawer.invoiceItems.fields.findHsCode.label", { ns: "rateRequest" })}
            variant="outlined"
            size="small"
            sx={(theme) => ({
              width: theme.utils.pxToRem(106),
            })}
          />
        </Box>
        <Box component="div">
          <StyledOpenInNewTab variant="contained" size="medium" fullWidth endIcon={<OpenInNewIcon />} onClick={() => openInNewTab()}>
            {t("drawer.invoiceItems.fields.findHsCodeLinkButton.label", { ns: "rateRequest" })}
          </StyledOpenInNewTab>
          <Box />
        </Box>
      </Box>
      <Box component="div" mb={customTheme.utils.pxToThemeSpacing(SPACING)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box component="div">
          <TextField
            id="quantity"
            name={`invoiceItems[${index}].quantity`}
            value={formik.values.invoiceItems[index].quantity || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.invoiceItems && formik.touched?.invoiceItems[index]?.quantity && formik.errors?.invoiceItems && formik.errors?.invoiceItems[index]?.quantity}
            type="number"
            inputProps={{ min: 0, step: 1 }}
            label={t("drawer.invoiceItems.fields.quantity.label", { ns: "rateRequest" })}
            variant="outlined"
            size="small"
            sx={(theme) => ({
              width: theme.utils.pxToRem(106),
            })}
          />
        </Box>
        <Typography
          component="span"
          sx={(theme) => ({
            lineHeight: theme.utils.pxToRem(40),
            fontWeight: theme.typography.fontWeightBold,
            mx: theme.utils.pxToThemeSpacing(4),
          })}
        >
          X
        </Typography>
        <Box component="div">
          <TextField
            id="value"
            name={`invoiceItems[${index}].value`}
            label={t("drawer.invoiceItems.fields.value.label", { ns: "rateRequest" })}
            value={formik.values.invoiceItems[index].value ? Number(formik.values.invoiceItems[index].value) : ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.invoiceItems && formik.touched?.invoiceItems[index]?.value && formik.errors?.invoiceItems && formik.errors?.invoiceItems[index]?.value}
            inputMode="numeric"
            type="number"
            inputProps={{ min: 0 }}
            variant="outlined"
            size="small"
            sx={(theme) => ({
              width: theme.utils.pxToRem(106),
            })}
          />
        </Box>
        <Typography
          component="span"
          sx={(theme) => ({
            lineHeight: theme.utils.pxToRem(40),
            fontWeight: theme.typography.fontWeightBold,
            mx: theme.utils.pxToThemeSpacing(4),
          })}
        >
          =
        </Typography>
        <Box component="div">
          <TextField
            id="total"
            name="total"
            value={(formik.values.invoiceItems[index].quantity * formik.values.invoiceItems[index].value || 0).toFixed(2)}
            label={t("drawer.invoiceItems.fields.total.label", { ns: "rateRequest" })}
            variant="outlined"
            size="small"
            disabled
            sx={(theme) => ({
              width: theme.utils.pxToRem(106),
              mr: theme.utils.pxToRem(14),
            })}
          />
        </Box>
      </Box>
      <Box component="div" mb={customTheme.utils.pxToThemeSpacing(SPACING)} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <CustomTextField
          id="weight"
          label={t("drawer.invoiceItems.fields.weight.label", { ns: "rateRequest" })}
          name={`invoiceItems[${index}].weight`}
          InputProps={{
            endAdornment: (
              <InputAdornment
                align="center"
                position="end"
                sx={{
                  backgroundColor: handleAdornmentBackgroundColor(formik.touched?.invoiceItems && formik.touched?.invoiceItems[index]?.weight && formik.errors?.invoiceItems && formik.errors?.invoiceItems[index]?.weight),
                }}
              >
                {formik.values.unitOfMeasure === UnitsOfMeasureEnum.IMPERIAL ? (
                  <Typography variant="bodyMedium600" pl={customTheme.utils.pxToThemeSpacing(4)}>
                    {t("drawer.invoiceItems.weightMetrics.pound", { ns: "rateRequest" })}
                  </Typography>
                ) : (
                  <Typography variant="bodyMedium600" pl={customTheme.utils.pxToThemeSpacing(2)}>
                    {t("drawer.invoiceItems.weightMetrics.kilogram", { ns: "rateRequest" })}
                  </Typography>
                )}
              </InputAdornment>
            ),
            min: 0,
          }}
          value={formik.values?.invoiceItems && formik.values?.invoiceItems[index]?.weight ? Number(formik.values.invoiceItems[index].weight) : ""}
          onChange={(event) => {
            if (event.target.value !== "") {
              event.target.value = Number(event.target.value)
            }
            formik.handleChange(event)
          }}
          onBlur={formik.handleBlur}
          error={formik.touched?.invoiceItems && formik.touched?.invoiceItems[index]?.weight && formik.errors?.invoiceItems && formik.errors?.invoiceItems[index]?.weight}
          type="number"
          variant="outlined"
          size="small"
          sx={(theme) => ({
            width: theme.utils.pxToRem(106),
            mr: theme.spacing(2),
          })}
          required
        />
        <Box component="div" sx={(theme) => ({ width: theme.utils.pxToRem(228) })}>
          <Country formik={formik} index={index} />
        </Box>
      </Box>
    </Box>
  )
}

InvoiceForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
}

InvoiceForm.defaultProps = {}

export default InvoiceForm
