// REACT
import React from "react"
import PropTypes from "prop-types"

// Custom style
import MuiWrapper from "./style"

function Main({ children, ...muiBoxProps }) {
  return (
    <MuiWrapper component="main" {...muiBoxProps}>
      {children}
    </MuiWrapper>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
