import { Box, Button, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import SoeArrowBackIcon from "../../../../soe-theme/src/icons/SoeArrowBackIcon"
import { pxToRem } from "../../../../soe-theme/src/utils"

function IncompleteRateRequest({ setShowRateRequestDrawer }) {
  const theme = useTheme()
  const { t } = useTranslation("quotations")
  return (
    <Box component="div" sx={{ display: "flex", alignItems: "center", padding: pxToRem(20), marginTop: pxToRem(8), marginLeft: pxToRem(8), backgroundColor: theme.palette.backgroundAlertPositive.main }}>
      <Box component="div" sx={{ display: "flex", alignItems: "center", maxWidth: "50%", justifyContent: "flex-start" }}>
        <SoeArrowBackIcon sx={{ marginRight: pxToRem(16), color: theme.palette.contentAlertPositiveDarker.main }} />
        <Typography variant="bodyMedium400" sx={{ color: theme.palette.contentAlertPositiveDarker.main }} gutterBottom>
          {t("noQuotation.getQuoteInformation")}
        </Typography>
      </Box>
      <Box component="div" sx={{ marginLeft: "auto" }}>
        <Button
          variant="outlined"
          size="medium"
          sx={{
            color: theme.palette.contentAlertPositiveDarker.main,
            borderColor: theme.palette.contentAlertPositiveDarker.main,
            "&:hover": {
              color: theme.palette.contentAlertPositiveDarker.main,
              borderColor: theme.palette.contentAlertPositiveDarker.main,
              backgroundColor: theme.palette.backgroundAlertPositive.main,
            },
          }}
          onClick={() => setShowRateRequestDrawer(true)}
        >
          {t("noQuotation.completeSubmissionButton.label")}
        </Button>
      </Box>
    </Box>
  )
}

IncompleteRateRequest.propTypes = {
  setShowRateRequestDrawer: PropTypes.func,
}

IncompleteRateRequest.defaultProps = {
  setShowRateRequestDrawer: () => {},
}

export default IncompleteRateRequest
