import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"

import { useGetAdditionalFieldsQuery } from "../../../schedule-pickup-drawer/slice"
import TranslationService from "../../../../../../../../services/translation"

function PickupAdditionalFieldsCard({ pickup, isEditable }) {
  const { i18n, t } = useTranslation("shipments")
  const theme = useTheme()

  const { data: carrierAdditionalFields, isFetching } = useGetAdditionalFieldsQuery(pickup.carrierCode)

  return (
    <RateRequestSummaryCard title={t("carrierHeader.pickupDetailsDrawer.pickupAdditionalFieldsCard.title")} handleClick={() => {}} showStatus={isEditable} isClickable={isEditable}>
      {pickup && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isFetching ? (
            <Skeleton variant="rectangular" height={50} width="100%" />
          ) : (
            <Stack>
              {carrierAdditionalFields?.map((carrierAdditionalField, index) => {
                const pickupAdditionalFieldName = Object.keys(pickup.additionalFields).find((additionalFieldName) => additionalFieldName === carrierAdditionalField.name)
                if (!pickupAdditionalFieldName) return undefined
                return (
                  <Typography key={"".concat(index)} sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                    {TranslationService.getLabelFromLocale(i18n.language, carrierAdditionalField.label)}:{" "}
                    {carrierAdditionalField.form?.options ? (
                      <b>{TranslationService.getLabelFromLocale(i18n.language, carrierAdditionalField.form.options.find((option) => option.value === pickup.additionalFields[pickupAdditionalFieldName]).label)}</b>
                    ) : (
                      <b>{pickup.additionalFields[pickupAdditionalFieldName].toString()}</b>
                    )}
                  </Typography>
                )
              })}
            </Stack>
          )}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

PickupAdditionalFieldsCard.propTypes = {
  pickup: PropTypes.instanceOf(Object).isRequired,
  isEditable: PropTypes.bool,
}

PickupAdditionalFieldsCard.defaultProps = {
  isEditable: false,
}

export default PickupAdditionalFieldsCard
