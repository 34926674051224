import React from "react"

import Select from "@mui/material/Select"
import { MenuItem } from "@mui/material"
import PropTypes from "prop-types"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

function CustomTimePicker({value, name, label, onChange, error}){

  const handleChange = (e) => {
    // TODO: to be consistent with other components, should this return a Date object?
    return onChange(e.target.value)
  }

  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour += 1) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`
        options.push(
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        );
      }
    }
    return options
  }

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        value={value}
        labelId="select-label"
        name={name} label={label}
        onChange={handleChange}
        MenuProps={{
          style: {
            maxHeight: "40%",
          },
        }}
        IconComponent={QueryBuilderIcon}
        error={error}
      >
        {generateTimeOptions()}
      </Select>
    </FormControl>)
}

CustomTimePicker.defaultProps = {
  value: "",
  name: "",
  label: "",
  onChange: () =>{},
  error: false
}

CustomTimePicker.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool
}

export default CustomTimePicker
