import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

import { useTheme } from "@mui/material"
import RateRequestSummaryCard from "../rate-request-summary-card"
import DateTimeService from "../../services/date-time"

function InvoiceInfoCard({ invoice, disabled }) {
  const { t } = useTranslation("shipments")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard disabled={disabled}>
      <Grid container justifyContent="center" alignItems="center" style={{ marginBottom: 10 }}>
        <Grid item xs={2} textAlign="left">
          <Typography variant="bodySmall200" component="div" sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 16) }}>
            {t("invoiceInfo.invoiceNumber")}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="p" ml={theme.spacing(1)} component="div" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {invoice?.number}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" style={{ marginBottom: 10 }}>
        <Grid item xs={2} textAlign="left">
          <Typography variant="bodySmall200" component="div" sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 16) }}>
            {t("invoiceInfo.dateOfInvoice")}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="p" ml={theme.spacing(1)} component="div" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {DateTimeService.toLocaleDateString(invoice.date)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={2} textAlign="left">
          <Typography variant="bodySmall200" component="div" sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 16) }}>
            {t("invoiceInfo.paymentStatus")}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="p" ml={theme.spacing(1)} component="div" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {invoice?.paymentStatus === "paid" ? t("invoiceInfo.paid") : t("invoiceInfo.unpaid")}
          </Typography>
        </Grid>
      </Grid>
    </RateRequestSummaryCard>
  )
}

InvoiceInfoCard.propTypes = {
  invoice: PropTypes.shape({
    number: PropTypes.string,
    date: PropTypes.string,
    paymentStatus: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
}

InvoiceInfoCard.defaultProps = {
  disabled: false,
}

export default InvoiceInfoCard
