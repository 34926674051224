const ShipmentsStatus = {
  Pending: "pending",
  InTransit: "intransit",
  Completed: "delivered",
  Canceled: "canceled",
}

export const OrderStatus = {
  MATCHED: "matched",
  VALIDATED: "validated",
  INVOICED: "invoiced",
  DISPUTED: "disputed",
  PREPAID: "prepaid",
  CANCELED: "canceled",
}

export default ShipmentsStatus
