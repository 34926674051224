// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import DateTimeService from "../../../../../../services/date-time"
import CardContentNoPaddingBottom from "../../../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import TimeZoneDateTimeSelector from "../../../../../timezone-datetime-selector"
import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"

import { updateShippingOptions, useGetCurrenciesQuery } from "../../../../../../reducers/quotationsReducer"
import { isCommercialInvoiceComplete, isInternationalShipment, isFreightOptionsRequired } from "../../../../utils"
import { useGetCompanyProfileQuery } from "../../../../../../services/company/slice"

function RateRequestShippingOptions({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { packages, recipient, shipper, shippingOptions, commercialInvoice } = useSelector((state) => state.quotations)

  const { t } = useTranslation(["rateRequest", "constant"])
  const dispatch = useDispatch()
  const customTheme = useTheme()

  const [action, setAction] = useState("")
  const [currentDate, setCurrentDate] = useState("")
  const currentUser = useSelector((state) => state.user)
  const { data: currentCompany } = useGetCompanyProfileQuery(currentUser.companyId)
  const { data: currencies } = useGetCurrenciesQuery()

  const signatures = [
    { label: "required", value: "required" },
    { label: "adult", value: "adult" },
    { label: "none", value: "none" },
  ]

  const SPACING = 16

  const featureFlag = () => {
    if (currentCompany?.featureFlags?.thirdPartyBilling === true) {
      return true
    }
    return isInternationalShipment(shipper, recipient) || isFreightOptionsRequired(packages)
  }

  const formik = useFormik({
    initialValues: {
      insurance: shippingOptions?.insurance || undefined,
      nonConveyable: shippingOptions?.nonConveyable || undefined,
      shippingDate: shippingOptions?.shippingDate || currentDate,
      references: shippingOptions?.references || undefined,
      signature: shippingOptions?.signature || undefined,
      createReturnLabel: shippingOptions?.createReturnLabel || undefined,
      deliveryInstructions: shippingOptions?.deliveryInstructions || undefined,
      shippingDateTimezone: shippingOptions?.shippingDateTimezone || shipper?.timezone || Intl.DateTimeFormat().resolvedOptions()?.timeZone,
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.shippingDate) {
        errors.shippingDate = t("drawer.shippingOptions.fields.shippingDate.error.required")
      }

      const utcShippingDateEndOfDay = DateTimeService.getZonedEndOfDayToUtc(formValues.shippingDate, formValues.shippingDateTimezone)
      if (new Date() > utcShippingDateEndOfDay) errors.shippingDate = true

      if (!isInternationalShipment(shipper, recipient) && formValues.insurance && !formValues.insurance.amount) {
        errors.amount = t("drawer.shippingOptions.fields.amount.error.required")
      }
      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      dispatch(
        updateShippingOptions({
          ...formValues,
          shippingDate: DateTimeService.getZonedDateString(formValues.shippingDate, formValues.shippingDateTimezone),
          ...(isInternationalShipment(shipper, recipient) &&
            isCommercialInvoiceComplete(commercialInvoice) &&
            formValues.insurance && {
              insurance: {
                amount: commercialInvoice.customsValue,
                currency: commercialInvoice.currency,
              },
            }),
        })
      )

      if (currentCompany?.featureFlags?.thirdPartyBilling === true) {
        if (action === "continue") {
          wizardProps.nextStep()
        }
      } else if (action === "continue") {
        if (isFreightOptionsRequired(packages)) {
          wizardProps.goToNamedStep("freight-options")
          return
        }
        if (isInternationalShipment(shipper, recipient)) {
          wizardProps.goToNamedStep("international-options")
          return
        }
      }

      if (action === "close") {
        setShowRateRequestDrawer(false)
      }
    },
  })

  useEffect(() => {
    if (!shippingOptions) {
      const newDate = DateTimeService.getZonedDateString(new Date(), shipper?.timezone || Intl.DateTimeFormat().resolvedOptions()?.timeZone)
      setCurrentDate(newDate)
    }
  }, [shippingOptions, shipper])

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  const handleSaveAndCloseClick = () => {
    setAction("close")
    formik.handleSubmit()
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.shippingOptions.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent>
        <form onSubmit={formik.handleSubmit} noValidate>
          <TimeZoneDateTimeSelector formik={formik} displayTimeZone={shipper?.timezone && Intl.DateTimeFormat().resolvedOptions().timeZone !== shipper?.timezone} shipperTimeZone={shipper?.timezone} displayShippingDate />
          <Card>
            <CardContentNoPaddingBottom
              sx={{
                "&:last-child": {
                  pb: 2,
                },
              }}
            >
              <Box component="div">
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.references?.customer || ""}
                  id="references.customer"
                  label={t("drawer.shippingOptions.fields.customer.label")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  my={customTheme.utils.pxToThemeSpacing(SPACING)}
                />
              </Box>
              <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                <TextField
                  id="references.order"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.references?.order || ""}
                  label={t("drawer.shippingOptions.fields.order.label")}
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Box>
              <Box component="div" mt={customTheme.utils.pxToThemeSpacing(SPACING)}>
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.references?.invoice || ""}
                  id="references.invoice"
                  label={t("drawer.shippingOptions.fields.invoice.label")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              </Box>
              <Box component="div" my={customTheme.utils.pxToThemeSpacing(6)}>
                <Box component="div">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!(formik.values && formik.values.insurance)}
                        onChange={() => (formik.values && formik.values.insurance ? formik.setFieldValue("insurance", undefined) : formik.setFieldValue("insurance", { amount: undefined, currency: currentUser.billing.currency || "CAD" }))}
                      />
                    }
                    value={formik.values.insurance}
                    label={t("drawer.shippingOptions.fields.insurance.label")}
                  />
                </Box>
                {!isInternationalShipment(shipper, recipient) && formik.values && formik.values.insurance && (
                  <Box component="div" my={customTheme.utils.pxToThemeSpacing(6)} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box component="div" sx={(theme) => ({ width: theme.utils.pxToRem(232) })}>
                      <TextField
                        name="insurance.amount"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.insurance.amount || ""}
                        type="number"
                        min="0"
                        label={t("drawer.shippingOptions.fields.amount.label")}
                        variant="outlined"
                        error={formik.touched.insurance && formik.touched.insurance.amount && formik.errors.amount !== undefined}
                        size="small"
                      />
                    </Box>
                    <Box component="div">
                      <FormControl fullWidth size="small">
                        <InputLabel />
                        <Select name="insurance.currency" onChange={formik.handleChange} value={formik.values.insurance.currency}>
                          {currencies?.map((currency, index) => (
                            <MenuItem key={currency.code.concat(index)} value={currency.code}>
                              {currency.code}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                )}
                <Box component="div">
                  <FormControlLabel
                    onChange={() => (formik.values && formik.values.signature ? formik.setFieldValue("signature", undefined) : formik.setFieldValue("signature", signatures[0].value))}
                    value={formik.values.signature}
                    control={<Checkbox checked={!!(formik.values && formik.values.signature)} />}
                    label={t("drawer.shippingOptions.fields.signature.label")}
                  />
                </Box>
                {formik.values.signature && (
                  <Box component="div" my={customTheme.utils.pxToThemeSpacing(6)}>
                    <FormControl fullWidth>
                      <InputLabel />
                      <Select name="signature" size="small" onChange={formik.handleChange} value={formik.values.signature}>
                        {signatures.map((signature, index) => (
                          <MenuItem key={signature.label.concat(index)} value={signature.value}>
                            {t(`drawer.shippingOptions.fields.signature.options.${signature.label}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                <Box component="div">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!(formik.values && formik.values.nonConveyable)}
                        onChange={() => (formik.values && formik.values.nonConveyable ? formik.setFieldValue("nonConveyable", undefined) : formik.setFieldValue("nonConveyable", true))}
                        value={formik.values.nonConveyable}
                      />
                    }
                    label={t("drawer.shippingOptions.fields.nonConveyable.label")}
                    name="nonConveyable"
                  />
                </Box>
                <Box component="div">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!(formik.values && formik.values.createReturnLabel)}
                        onChange={() => (formik.values && formik.values.createReturnLabel ? formik.setFieldValue("createReturnLabel", undefined) : formik.setFieldValue("createReturnLabel", true))}
                        value={formik.values.createReturnLabel}
                      />
                    }
                    label={t("drawer.shippingOptions.fields.createReturnLabel.label")}
                    name="createReturnLabel"
                  />
                </Box>
              </Box>
              <Box component="div">
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.deliveryInstructions || ""}
                  name="deliveryInstructions"
                  label={t("drawer.shippingOptions.fields.deliveryInstructions.label")}
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                />
              </Box>
            </CardContentNoPaddingBottom>
          </Card>
        </form>
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          featureFlag() ?
            [
              {
                action: handleSaveAndCloseClick,
                label: t("drawer.packages.actions.prevButton.label", { ns: "rateRequest" }),
                variant: "outlined",
              },
              {
                action: handleContinueClick,
                label: t("drawer.packages.actions.nextButton.label", { ns: "rateRequest" }),
                variant: "contained",
              },
            ] 
            : 
            [
              {
                action: handleSaveAndCloseClick,
                label: t("drawer.packages.actions.prevButton.label", { ns: "rateRequest" }),
                variant: "outlined",
              },
            ]

        }
      />
    </SoeDrawerContainer>
  )
}

RateRequestShippingOptions.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestShippingOptions.defaultProps = {}

export default RateRequestShippingOptions
