export const ShippingStepEnum = {
    Shipper: "Shipper",
    Recipient: "Recipient",
    Packages: "Packages",
    ShippingOptions: "ShippingOptions",
  }
  
  export const AddressSearchGroupsText = {
    AddressBook: "AddressBook",
    CanadaPost: "CanadaPost",
  }
  
  export const AddressTypeEnum = {
    Broker: "broker",
    Shipping: "shipping",
    Buyer: "buyer",
  }
  
  export const paymentType = {
    account: "account",
    creditcard: "creditcard",
  }
  