import PropTypes from "prop-types"

// REACT
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import CardContentNoPaddingTop from "../../../../soe-theme/src/components/cardContent-no-padding-top"
import { MainCustomButton } from "./style"
import NumericBoxInput from "./components/numeric-box-input"
import ModalAlert from "../../../modal/components/modal-alert"

import background from "../../../../assets/images/confirm-dialog-background.svg"

import { confirmSignUp, resendConfirmationCode } from "../../../../reducers/authenticatorReducer"

function ConfirmSignUp({ onSubmit, isConfirmDialogOpen }) {
  const customTheme = useTheme()
  const { t } = useTranslation("authenticator")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleNavigateToLogin = () => navigate("/log-in", { replace: true, state: { fromSignUp: true } })

  const formik = useFormik({
    initialValues: {
      email: useSelector((state) => state.authenticator.email),
      verificationCode: "",
    },
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(confirmSignUp(formValues)).then((response) => {
        if (!response.error) {
          onSubmit()
          handleNavigateToLogin()
        }
      })
    },
  })

  return (
    <Box className="confirmSignUp" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <img src={background} alt="background-icon" style={{ preserveAspectRatio: "none", position: "absolute", top: "0px", right: "0px" }} />
      <Grid container direction="column" alignItems="center">
        <Card my={2} sx={{ overflowY: "auto", borderBottom: "12px solid #A7E6D7", width: customTheme.utils.pxToRem(800), minHeight: "25rem" }}>
          <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(770), marginTop: theme.utils.pxToRem(50) })}>
            <CardContentNoPaddingBottom sx={{ justifyContent: "center", padding: "0" }}>
              <Box component="div">
                <CardContentNoPaddingTop sx={{ display: "grid", justifyContent: "center" }}>
                  {!isConfirmDialogOpen && <ModalAlert />}
                  <Typography sx={(theme) => ({ marginBottom: theme.utils.pxToRem(25), color: theme.palette.inexTertiary.main, fontFamily: "Poppins", display: "flex", justifyContent: "center", fontSize: theme.utils.pxToRem(24), fontWeight: "600" })}>
                    {t("inexConfirmSignUp.emailSent1")}
                  </Typography>
                  <Typography sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(18), justifyContent: "center", color: theme.palette.inexTertiary.main })}>{t("inexConfirmSignUp.emailSent2")}</Typography>
                  <Typography sx={(theme) => ({ fontStyle: "italic", fontSize: theme.utils.pxToRem(18), fontFamily: "Poppins", display: "flex", color: theme.palette.inexTertiary.main, justifyContent: "center", textAlignLast: "center" })}>
                    {" "}
                    {formik.values.email}
                  </Typography>
                  <Typography sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(18), justifyContent: "center", color: theme.palette.inexTertiary.main })}>{t("inexConfirmSignUp.emailSent3")}</Typography>
                </CardContentNoPaddingTop>
              </Box>
              <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(8) })} />
              <form onSubmit={formik.handleSubmit} noValidate>
                <Box>
                  <NumericBoxInput id="verificationCode" onChange={formik.setFieldValue} fieldValue="verificationCode" value={formik.values.verificationCode} />
                </Box>
                <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(25) })} />

                <Box component="div">
                  <CardContent sx={{ display: "grid", justifyContent: "center", padding: "0" }}>
                    <MainCustomButton
                      disabled={formik.values.verificationCode.length !== 6}
                      type="submit"
                      sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(18), display: "flex", borderRadius: "2px", textTransform: "uppercase" })}
                    >
                      {t("confirmSignUp.fields.confirmSignUpButton.label")}
                    </MainCustomButton>
                  </CardContent>
                </Box>
                <Box component="div">
                  <CardContentNoPaddingBottom
                    sx={(theme) => ({ fontSize: theme.utils.pxToRem(18), width: "auto", padding: "0", marginBottom: theme.utils.pxToRem(50), display: "grid", justifyContent: "center", color: theme.palette.inexPrimary.main })}
                  >
                    <Link
                      onClick={() => {
                        return dispatch(resendConfirmationCode(formik.values))
                      }}
                      sx={(theme) => ({ color: theme.palette.inexPrimary.main })}
                    >
                      {t("inexConfirmSignUp.lostCode")}
                    </Link>
                  </CardContentNoPaddingBottom>
                </Box>
              </form>
            </CardContentNoPaddingBottom>
          </Box>
        </Card>
      </Grid>
    </Box>
  )
}

ConfirmSignUp.propTypes = {
  onSubmit: PropTypes.func,
  isConfirmDialogOpen: PropTypes.bool,
}

ConfirmSignUp.defaultProps = {
  onSubmit: () => {},
  isConfirmDialogOpen: false,
}

export default ConfirmSignUp
