import { setShowSpinner, updateRates } from "../../reducers/quotationsReducer";
import { setPaymentStatus, setIsLoading } from "../../reducers/paymentReducer";
import { MESSAGE_STATUS, MESSAGE_TYPE } from "./constant";
import AppConfig from "../../utils/app-config";
import restApiSlice from "../rest-api/slice";
import { getJwtTokens } from "../../reducers/authenticatorReducer";
import { isDev } from "../../utils/get-environment";
import { createAlert } from "../../reducers/alertReducer";
import { updateUserDetailsSuccess } from "../../reducers/userReducer";
import { setTransactionStatus } from "../../reducers/transactionReducer";

let socket;
let timer;
let user;

const WebSocketService = {
  init(dispatch, currentUser) {
    user = currentUser;
    if (!socket) {
      console.log("Initializing WebSocket");
      socket = new WebSocket(AppConfig.websocket.url);
      this.setupWebSocket(dispatch, currentUser);
    }
    if (!timer) {
      timer = setInterval(this.sendPing, AppConfig.websocket.keepaliveDelay);
    }
  },

  setupWebSocket(dispatch, currentUser) {
    socket.onopen = () => {
      console.log("WebSocket connected");
    };

    socket.onmessage = (e) => {
      const { event, data } = JSON.parse(e.data);
      switch (event) {
        case MESSAGE_TYPE.CONNECTION: {
          if (currentUser.websocket.previousConnectionId && isDev()) {
            dispatch(createAlert("WebSocket reconnected at ".concat(new Date().toISOString())));
          }
          const websocket = { connectionId: data.connectionId };
          dispatch(updateUserDetailsSuccess({ websocket }));
          this.sendIdentity(currentUser.websocket.previousConnectionId);
          break;
        }
        case MESSAGE_TYPE.RATE_RESPONSE: {
          dispatch(updateRates(data.event.data));
          break;
        }
        case MESSAGE_TYPE.RATE_STATUS: {
          if (data.event.status === MESSAGE_STATUS.RECEIVED || data.event.status === MESSAGE_STATUS.STARTED) {
            dispatch(restApiSlice.util.invalidateTags(["RateRequestHistory"]));
            dispatch(setShowSpinner(true));
          } else if (data.event.status === MESSAGE_STATUS.SUCCEEDED) {
            dispatch(setShowSpinner(false));
          }
          break;
        }
        case MESSAGE_TYPE.PAYMENT_STATUS: {
          if (isDev()) console.log("MESSAGE_TYPE.PAYMENT_STATUS DATA : ", data);
          dispatch(setPaymentStatus(data));
          dispatch(setIsLoading(false));
          break;
        }
        case MESSAGE_TYPE.CREATE_ORDER_STATUS: {
          if (isDev()) console.log("CREATE_ORDER_STATUS DATA : ", data);
          dispatch(setTransactionStatus(data));
          dispatch(restApiSlice.util.invalidateTags(["UserProfile"]));
          break;
        }
        default:
          break;
      }
    };

    socket.onclose = () => {
      console.log("WebSocket closed");
      socket = null;
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
      if (user) {
        if (isDev()) dispatch(createAlert("Oh no! WebSocket's closed at ".concat(new Date().toISOString())));
        const previousConnectionId = currentUser.websocket.connectionId;
        const websocket = { connectionId: null, previousConnectionId };
        dispatch(updateUserDetailsSuccess({ websocket }));
        setTimeout(() => {
          console.log("Reinitializing WebSocket after close");
          this.init(dispatch, currentUser);
        }, 1000); // Delay to prevent rapid reconnections
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error", error);
      socket.close();
    };
  },

  async sendIdentity(previousConnectionId) {
    try {
      const response = await getJwtTokens();
      socket.send(
        JSON.stringify({
          action: "message",
          identity: response.idToken,
          previousConnectionId,
        })
      );
    } catch (error) {
      console.error("Error sending identity:", error);
    }
  },

  sendPing() {
    if (socket) {
      socket.send(JSON.stringify({ action: "ping" }));
    }
  },

  close() {
    if (socket) {
      socket.close();
    }
    socket = null;
    user = undefined;
  },
};

export default WebSocketService;
