/* eslint-disable no-unused-vars */

// REACT
import PropTypes from "prop-types"
import React, { useEffect, useContext } from "react"

import { createAction } from "@reduxjs/toolkit"
import { useNavigate } from "react-router-dom"

import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Others
import DrawerAlert from "../../../../../drawer-alert"

import { useChangeUserEmailMutation } from "../../../../../../reducers/paymentMethodReducer"

import { clearDrawerAlert } from "../../../../../../reducers/drawerAlertReducer"
import { signOut } from "../../../../../../reducers/authenticatorReducer"
import ImpersonateService from "../../../../../../services/impersonate"
import WebSocketService from "../../../../../../services/websocket"

import { setInitialState } from "../../../../../../reducers/userReducer"

import { ROUTES } from "../../../../../../utils/constants"

function GeneralInfoEmailDrawer({ currentUser, handleOpenAlert, onSubmitEvent, setShowGeneralInfoEmailDrawer }) {
  const { t } = useTranslation("userProfile")
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [handleChangeUserEmail] = useChangeUserEmailMutation()

  const formik = useFormik({
    initialValues: {
      oldEmail: currentUser.emailAddress,
      newEmail: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.oldEmail) {
        errors.oldEmail = t("userProfile.generalInfoEmailDrawer.changeEmail.fields.oldEmail.error.required")
      }
      if (!formValues.newEmail) {
        errors.newEmail = t("userProfile.generalInfoEmailDrawer.changeEmail.fields.newEmail.error.required")
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      handleChangeUserEmail({ ...formValues, pk: currentUser.pk }).then(() => {
        dispatch(setInitialState())
        WebSocketService.close()
        dispatch(signOut()).then(() => {
          const resetStore = createAction("RESET_STORE")
          dispatch(resetStore())
        })
        ImpersonateService.unimpersonate()
        navigate(ROUTES.LOG_IN, { replace: true })
      }
      )
    },
  })

  useEffect(() => {
    dispatch(clearDrawerAlert())
  }, [])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerAlert />
        <Typography variant="bodyMedium600" pb={1} gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {t("userProfile.generalInfoEmailDrawer.changeEmail.fields.accountEmail.label")}
        </Typography>
        <Box component="div" py={2}>
          <TextField
            id="oldEmail"
            label={t("userProfile.generalInfoEmailDrawer.changeEmail.fields.oldEmail.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldEmail}
            error={formik.touched.oldEmail && formik.errors.oldEmail !== undefined}
            helperText={(formik.touched.oldEmail && formik.errors.oldEmail) || " "}
            variant="outlined"
            size="small"
            type="text"
            required
            disabled
          />
        </Box>
        <Box component="div">
          <TextField
            id="newEmail"
            label={t("userProfile.generalInfoEmailDrawer.changeEmail.fields.newEmail.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newEmail}
            error={formik.touched.newEmail && formik.errors.newEmail !== undefined}
            helperText={(formik.touched.newEmail && formik.errors.newEmail) || " "}
            variant="outlined"
            size="small"
            type="text"
            required
          />
        </Box>
      </form>
    </Box>
  )
}

GeneralInfoEmailDrawer.defaultProps = {
  onSubmitEvent: undefined,
  currentUser: undefined,
  handleOpenAlert: undefined,
}

GeneralInfoEmailDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowGeneralInfoEmailDrawer: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Object),
  handleOpenAlert: PropTypes.func,
}

export default GeneralInfoEmailDrawer
