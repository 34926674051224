import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import SoeDrawerWizard from "../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-wizard"
import RateRequestBroker from "./components/rate-request-broker"
import RateRequestCommercialInvoice from "./components/rate-request-commercial-invoice"
import RateRequestFreightOptions from "./components/rate-request-freight-options"
import RateRequestInternationalOptions from "./components/rate-request-international-options"
import RateRequestPackages from "./components/rate-request-packages"
import RateRequestRecipient from "./components/rate-request-recipient"
import RateRequestShipper from "./components/rate-request-shipper"
import RateRequestShippingOptions from "./components/rate-request-shipping-options"
import RateRequestBuyer from "./components/rate-request-buyer"
import RateRequestBillingOptions from "./components/rate-request-billing-options"
import { useGetCompanyProfileQuery } from "../../../../services/company/slice"

function RateRequest({ setShowRateRequestDrawer, setStepWizardInstance, showRateRequestDrawer }) {
  const currentUser = useSelector((state) => state.user)
  const { data: currentCompany } = useGetCompanyProfileQuery(currentUser.companyId)

  return (
    <SoeDrawerWizard setStepWizardInstance={setStepWizardInstance} islazyload="true">
      <RateRequestShipper stepName="shipper" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestRecipient stepName="recipient" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestPackages stepName="packages" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestShippingOptions stepName="shipping-options" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestBillingOptions stepName="billing-options" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestFreightOptions stepName="freight-options" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestInternationalOptions stepName="international-options" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestBroker stepName="broker" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestCommercialInvoice stepName="commercial-invoice" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
      <RateRequestBuyer stepName="buyer" setShowRateRequestDrawer={setShowRateRequestDrawer} showRateRequestDrawer={showRateRequestDrawer} />
    </SoeDrawerWizard>
  )
}

RateRequest.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  setStepWizardInstance: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequest.defaultProps = {}

export default RateRequest
