// REACT
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// Formik
import { useFormik } from "formik"

// Others
import AppConfig from "../../../../../../utils/app-config"
import FullAddressForm from "../../../../../full-address-form"
import TransactionStatus, { TRANSACTION_STATUS_ENUM } from "../../../../../transaction-status"

import { getAddressFormat, isRequiredField } from "../../../../../../services/address-form"
import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { updateRecipient } from "../../../../../../reducers/quotationsReducer"
import { useAddAddressBookItemMutation, useEditAddressBookItemMutation } from "../../../../../address-book/slice"
import { useValidateAddressMutation } from "../../../../../full-address-form/slice"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../services/address-form/slice"
import { isValidEmail, isValidPhoneNumber } from "../../utils"

function RateRequestRecipient({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { t } = useTranslation("fullAddressForm")
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.user)
  const { recipient } = useSelector((state) => state.quotations)

  const [action, setAction] = useState("")
  const [saveAddressChecked, setSaveAddressChecked] = useState(false)
  const [selectedAddressFromAutoComplete, setSelectedAddressFromAutoComplete] = useState({})
  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()

  const [addAddressBookItem, { data: newAddressBookItem }] = useAddAddressBookItemMutation()
  const [editAddressBookItem, { data: updatedAddressBookItem }] = useEditAddressBookItemMutation()
  const [validateAddress, { data: validationAddress, isLoading, isError }] = useValidateAddressMutation()

  useEffect(() => {
    if (newAddressBookItem?.data) {
      let newAddressBook = { ...recipient, ...newAddressBookItem.data }
      if (newAddressBookItem.data.canadaPostId) newAddressBook = { ...newAddressBook, canadaPostId: newAddressBookItem.data.canadaPostId }
      dispatch(updateRecipient(newAddressBook))
    }
  }, [newAddressBookItem])

  useEffect(() => {
    if (updatedAddressBookItem?.data?.canadaPostId) {
      dispatch(updateRecipient({ ...recipient, canadaPostId: updatedAddressBookItem.data.canadaPostId }))
    }
  }, [updatedAddressBookItem])

  // eslint-disable-next-line no-unused-vars
  const saveAddressCheckbox = (value) => {
    // @todo To eventually complete function, perform action when value is true and remove eslint comment above.
  }

  const formik = useFormik({
    initialValues: {
      id: recipient?.id || undefined,
      companyName: recipient?.companyName || "",
      personName: recipient?.personName || "",
      addressLine1: recipient?.addressLine1 || "",
      addressLine2: recipient?.addressLine2 || "",
      city: recipient?.city || "",
      provinceName: recipient?.provinceName || "",
      provinceCode: recipient?.provinceCode || "",
      countryCode: recipient?.countryCode || currentUser?.company?.clientAddress?.countryCode || currentUser?.clientAddress?.countryCode || "CA",
      postalCode: recipient?.postalCode || "",
      residential: recipient?.residential || false,
      phoneNumber: recipient?.phoneNumber || "",
      emailAddress: recipient?.emailAddress || "",
      timezone: recipient?.timezone || undefined,
      validationProviderId: recipient?.validationProviderId || undefined,
    },
    validate: (formValues) => {
      const errors = {}

      if (!formValues.personName.trim()) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.phoneNumber.trim()) {
        errors.phoneNumber = t("fields.phoneNumber.error.required")
      }

      const isPhoneNumberValid = isValidPhoneNumber(formValues.phoneNumber)
      if (formValues.phoneNumber.trim() && !isPhoneNumberValid) {
        errors.phoneNumber = t("fields.phoneNumber.error.invalid")
      }

      const isEmailValid = isValidEmail(formValues.emailAddress)

      if (formValues.emailAddress.trim() && !isEmailValid) {
        errors.emailAddress = t("fields.emailAddress.error.invalid")
      }

      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const { timezone, ...addressToValidate } = formValues
      setShowTransactionStatus(true)
      validateAddress(addressToValidate)
    },
  })

  useEffect(() => {
    if (validationAddress?.status === TRANSACTION_STATUS_ENUM.SUCCESS) {
      dispatch(updateRecipient({ ...formik.values, ...validationAddress?.metadata?.address }))
      if ((selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.origin === "CanadaPost") || (selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.canadaPostId) || formik.values.canadaPostId) {
        if (selectedAddressFromAutoComplete.origin === "CanadaPost") formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.id }
        if (selectedAddressFromAutoComplete.canadaPostId) formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.canadaPostId }
        if (formik.values.canadaPostId) formik.values = { ...formik.values, canadaPostId: formik.values.canadaPostId }
      }

      if (saveAddressChecked) {
        if (formik.values.id) {
          editAddressBookItem({ id: formik.values.id, payload: formik.values })
        } else {
          addAddressBookItem(formik.values)
        }
      }
      if (action === "continue") {
        setShowTransactionStatus(false)
        wizardProps.nextStep()
      }
      if (action === "saveAndClose") {
        setShowRateRequestDrawer(false)
        setShowTransactionStatus(false)
      }
    }
  }, [validationAddress])

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  useEffect(() => {
    formik.setErrors({})
    formik.setTouched({})
  }, [formik.values.countryCode])

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.recipient.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent drawerScrollToTop={showTransactionStatus}>
        {AppConfig.features.showAddressValidation && showTransactionStatus && <TransactionStatus transaction={validationAddress} title={t("drawer.recipient.validateAddress.label", { ns: "rateRequest" })} isError={isError} isLoading={isLoading} />}
        <FullAddressForm
          formik={formik}
          displaySearchAddressBar
          displayResidentialToggle
          displaySaveAddressCheckbox
          setSelectedAddressFromAutoComplete={setSelectedAddressFromAutoComplete}
          saveAddressCheckboxLabel={formik.values.id ? t("recipient.updateAddressCheckbox.label") : t("recipient.saveAddressCheckbox.label")}
          saveAddressChecked={saveAddressChecked}
          setSaveAddressChecked={setSaveAddressChecked}
        />
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          wizardProps.currentStep === wizardProps.totalSteps
            ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.recipient.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
            : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.recipient.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.recipient.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
        }
      />
    </SoeDrawerContainer>
  )
}

RateRequestRecipient.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestRecipient.defaultProps = {}

export default RateRequestRecipient
