import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

const MuiWrapper = styled(Box, { name: "Main", slot: "Root" })(({ theme }) => ({
  margin: theme.spacing(2),
  width: "100",
  height: "100%",
}))

export default MuiWrapper
