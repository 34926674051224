// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

// Others
import Country from "./components/country"
import Province from "./components/province"
import Input from "./components/input"

const componentsEnum = {
  Country,
  Province,
  Input,
}

function AddressForm({ formik, addressFormat }) {
  const [components, setComponents] = useState(null)

  useEffect(() => {
    if (addressFormat) {
      const newComponents = addressFormat.map((fieldFormat, index) => {
        const ComponentName = componentsEnum[fieldFormat.form?.element]
        return <ComponentName key={index.toString()} fieldFormat={fieldFormat} formik={formik} />
      })
      setComponents(newComponents)
    }
  }, [addressFormat, formik])

  return components
}

AddressForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({ countryCode: PropTypes.string }),
  }).isRequired,
  addressFormat: PropTypes.instanceOf(Object).isRequired,
}

export default AddressForm
