// REACT
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import CardContentNoPaddingTop from "../../../../soe-theme/src/components/cardContent-no-padding-top"
import { MainCustomButton } from "./style"

import background from "../../../../assets/images/confirm-dialog-background.svg"

import { clearModalAlert } from "../../../../reducers/modalReducer"
import { useCreateUserInvitationMutation } from "../../../../services/invitation/slice"

function CompanyExists({ handleCloseCompanyExistDialog, isDomainMatch, values }) {
  const { t } = useTranslation("company")
  const [isEmailInUse, setIsEmailInUse] = useState(false)
  const customTheme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [createInvitation, { data: createInvitationResponse, isError }] = useCreateUserInvitationMutation()

  useEffect(() => {
    if (createInvitationResponse?.data?.companyName) {
      handleCloseCompanyExistDialog(false)
      dispatch(clearModalAlert())
      navigate("/log-in", { replace: true })
      // When we implement email check we need to set this true
    } else if (isError) {
      setIsEmailInUse(true)
    }
  }, [createInvitationResponse?.data, isError])

  const handleSentInvitation = () => {
    const { companyName, email, personName } = values
    createInvitation({ companyName, email, personName, status: "requested" })
  }

  return (
    <Box className="confirmSignUp" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <img src={background} alt="background-icon" style={{ preserveAspectRatio: "none", position: "absolute", top: "0px", right: "0px" }} />
      <Grid container direction="column" alignItems="center">
        <Card my={2} sx={{ overflowY: "auto", borderBottom: "12px solid #A7E6D7", width: customTheme.utils.pxToRem(800), minHeight: "25rem" }}>
          <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(770), marginTop: theme.utils.pxToRem(50) })}>
            <CardContentNoPaddingBottom sx={{ justifyContent: "center", padding: "0" }}>
              <Box component="div">
                <CardContentNoPaddingTop sx={{ display: "grid", justifyContent: "center" }}>
                  <Typography sx={(theme) => ({ marginBottom: theme.utils.pxToRem(28), color: theme.palette.inexTertiary.main, fontFamily: "Poppins", display: "flex", justifyContent: "center", fontSize: theme.utils.pxToRem(24), fontWeight: "600" })}>
                    {t("alert.title")}
                  </Typography>

                  {isEmailInUse ? (
                    <Typography style={{ marginLeft: 100, marginRight: 100, textAlign: "center" }} sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(20), color: theme.palette.inexTertiary.main })}>
                      {t("alert.emailInUseText1")}
                      <br />
                      <br />
                      {t("alert.emailInUseText2")}
                    </Typography>
                  ) : isDomainMatch ? (
                    <Typography style={{ marginLeft: 100, marginRight: 100, textAlign: "center" }} sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(20), color: theme.palette.inexTertiary.main })}>
                      {t("alert.domainExistsMsg1")}
                      <br />
                      <br />
                      {t("alert.domainExistsMsg2")}
                    </Typography>
                  ) : (
                    <Typography style={{ marginLeft: 100, marginRight: 100, textAlign: "center" }} sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(20), color: theme.palette.inexTertiary.main })}>
                      {t("alert.companyExistsMsg1")}
                      <br />
                      <br />
                      {t("alert.companyExistsMsg2")}
                    </Typography>
                  )}
                </CardContentNoPaddingTop>
              </Box>

              {isEmailInUse ? (
                <Box component="div">
                  <CardContentNoPaddingBottom sx={(theme) => ({ fontSize: theme.utils.pxToRem(18), marginBottom: theme.utils.pxToRem(50), display: "flex", justifyContent: "center", color: theme.palette.inexPrimary.main })}>
                    <span style={{ fontFamily: "Poppins", color: "#001F22", paddingRight: 10 }}>{t("alert.needHelp")}</span>
                    <Link href="mailto:info@inex.ca" sx={(theme) => ({ color: theme.palette.inexPrimary.main })}>
                      {t("alert.contactUs")}
                    </Link>
                  </CardContentNoPaddingBottom>
                </Box>
              ) : (
                <>
                  <Box component="div">
                    <CardContent sx={{ display: "grid", justifyContent: "center", padding: "0" }}>
                      <MainCustomButton onClick={handleSentInvitation} sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(18), display: "flex", borderRadius: "2px", textTransform: "uppercase" })}>
                        {t("alert.notify")}
                      </MainCustomButton>
                    </CardContent>
                  </Box>
                  <Box component="div">
                    <CardContentNoPaddingBottom
                      sx={(theme) => ({ fontSize: theme.utils.pxToRem(18), width: "auto", padding: "0", marginBottom: theme.utils.pxToRem(50), display: "grid", justifyContent: "center", color: theme.palette.inexPrimary.main })}
                    >
                      <Link onClick={handleCloseCompanyExistDialog} sx={(theme) => ({ color: theme.palette.inexPrimary.main })}>
                        {t("alert.getStarted")}
                      </Link>
                    </CardContentNoPaddingBottom>
                  </Box>
                </>
              )}
            </CardContentNoPaddingBottom>
          </Box>
        </Card>
      </Grid>
    </Box>
  )
}

CompanyExists.propTypes = {
  handleCloseCompanyExistDialog: PropTypes.func,
  isDomainMatch: PropTypes.bool,
  values: PropTypes.shape({
    email: PropTypes.string,
    companyName: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    personName: PropTypes.string,
    termsConditionsChecked: PropTypes.bool,
  }),
}

CompanyExists.defaultProps = {
  handleCloseCompanyExistDialog: () => { },
  isDomainMatch: false,
  values: {},
}

export default CompanyExists
