// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Others
import CarrierServiceLogo from "../../../../../carrier-service-logo"
import Clock from "../../../../../clock"
import DateTimeService from "../../../../../../services/date-time"
import MoreMenu from "../../../../../more-menu"
import PickupDetailsDrawer from "../pickup-details-drawer"
import SchedulePickupDrawer from "../schedule-pickup-drawer"
import AppConfig from "../../../../../../utils/app-config"

function CarrierHeader({ carrier, address, pickup, shippingDate, ordersCount, trackingId }) {
  const { t } = useTranslation("shipments")

  const currentUser = useSelector((state) => state.user)

  const shipperTimeOptions = { year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", timeZoneName: "short", timeZone: address.timezone }
  const [showSchedulePickupDrawer, setShowSchedulePickupDrawer] = useState(false)
  const [showPickupDetailsDrawer, setShowPickupDetailsDrawer] = useState(false)
  const isPickupHidden = carrier.code === "canada-post"

  const handleSchedulePickup = () => {
    setShowSchedulePickupDrawer(true)
  }

  const handleManifest = () => {
    window.open(`${AppConfig.restApi.apiUrl}/orders/manifests/print?carrierCode=${carrier.code}&shippingDate=${shippingDate}&companyId=${currentUser.companyId}`, "_blank")
  }

  const isDatesEquals = (firstDate, secondDate) => {
    const firstDateWithoutTime = new Date(new Date(firstDate).getTime())
    firstDateWithoutTime.setUTCHours(0, 0, 0, 0)
    const secondDateWithoutTime = new Date(new Date(secondDate).getTime())
    secondDateWithoutTime.setUTCHours(0, 0, 0, 0)
    return firstDateWithoutTime.toISOString() === secondDateWithoutTime.toISOString()
  }

  const sideMenuOptionList = () => {
    let optionList = []
    if (!pickup) {
      const options = [
        {
          label: t("aShipmentDetails.optionsList.manifest.label"),
          handleClick: handleManifest,
        },
      ]

      if (!isPickupHidden) {
        options.push({
          label: t("aShipmentDetails.optionsList.schedulePickup.label"),
          handleClick: handleSchedulePickup,
        })
      }

      return options
    }
    if (pickup.isRegularPickup) {
      return [
        {
          label: t("aShipmentDetails.optionsList.manifest.label"),
          handleClick: handleManifest,
        },
      ]
    }
    if (!isDatesEquals(pickup.readyTime, shippingDate)) {
      optionList = optionList.concat({
        label: t("aShipmentDetails.optionsList.schedulePickup.label"),
        handleClick: handleSchedulePickup,
      })
    }
    optionList = optionList.concat([
      {
        label: t("aShipmentDetails.optionsList.pickupDetails.label"),
        handleClick: () => setShowPickupDetailsDrawer(true),
      },
      {
        label: t("aShipmentDetails.optionsList.cancelPickup.label"),
        handleClick: () => setShowPickupDetailsDrawer(true),
      },
      {
        label: t("aShipmentDetails.optionsList.manifest.label"),
        handleClick: handleManifest,
      },
    ])
    return optionList
  }

  const displayedAddress =
    `${address.addressLine1?.trim() ? `${address.addressLine1?.trim()}, ` : ""}` +
    `${address.addressLine2?.trim() ? `${address.addressLine2?.trim()}, ` : ""}` +
    `${address.city?.trim() ? `${address.city?.trim()}, ` : ""}` +
    `${address.provinceCode?.trim() ? `${address.provinceCode?.trim()}, ` : address.provinceName?.trim() ? `${address.provinceName?.trim()}, ` : ""}` +
    `${address.countryCode?.trim() ? `${address.countryCode?.trim()}` : ""}` +
    `${address.postalCode?.trim() ? `, ${address.postalCode?.trim()}` : ""}`

  return (
    <>
      <TableRow component="tr" sx={(theme) => ({ backgroundColor: "white", height: theme.utils.pxToRem(72) })}>
        <TableCell padding="none" align="center" colSpan={1}>
          <CarrierServiceLogo code={carrier.code} logo={carrier.logo} align="center" />
        </TableCell>
        <TableCell align="left" colSpan={8}>
          <Box>
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("aShipmentDetails.from.label").concat(displayedAddress)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("aShipmentDetails.currentShipperTime.label")}
              <Clock options={shipperTimeOptions} />
            </Typography>
          </Box>
          {carrier?.division && carrier?.division !== "STANDARD" && (
            <Box>
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                {"Carrier division: "}
              </Typography>
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                <b>{carrier?.division}</b>
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell align="left" colSpan={2} />
        <TableCell align="left" colSpan={1}>
          {pickup && !pickup.isRegularPickup ? (
            <>
              <Box>
                <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                  {t("carrierHeader.pickupScheduledOn")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="bodySmall400"
                  gutterBottom
                  sx={(theme) => ({ color: isDatesEquals(pickup.readyTime, shippingDate) ? theme.palette.contentNeutralSecondary.main : ordersCount > 0 ? "orange" : "red", lineHeight: theme.utils.pxToLineHeight(16, 22), fontWeight: "bold" })}
                >
                  {DateTimeService.toLocaleDateString(pickup?.readyTime, address?.timezone)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="bodySmall400"
                  gutterBottom
                  sx={(theme) => ({
                    color: isDatesEquals(pickup.readyTime, shippingDate) ? theme.palette.contentNeutralSecondary.main : ordersCount > 0 ? "orange" : "red",
                    lineHeight: theme.utils.pxToLineHeight(16, 22),
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                >
                  {DateTimeService.toLocaleTimeString(pickup?.readyTime, address?.timezone)}
                </Typography>
              </Box>
            </>
          ) : (
            <Box>
              {!pickup?.isRegularPickup && !isPickupHidden && (
                <Button variant="contained" size="small" onClick={() => handleSchedulePickup()} sx={{ textTransform: "none" }}>
                  {t("aShipmentDetails.optionsList.schedulePickup.label", { ns: "shipments" })}
                </Button>
              )}
            </Box>
          )}
          {pickup && pickup.isRegularPickup && (
            <Box>
              <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22), fontWeight: theme.typography.fontWeightBold })}>
                {t("carrierHeader.regularPickup")}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList()} />
        </TableCell>
      </TableRow>
      {showSchedulePickupDrawer && <SchedulePickupDrawer trackingId={trackingId} carrier={carrier} address={address} showSchedulePickupDrawer={showSchedulePickupDrawer} setShowSchedulePickupDrawer={setShowSchedulePickupDrawer} />}
      {showPickupDetailsDrawer && <PickupDetailsDrawer carrier={carrier} pickup={pickup} showPickupDetailsDrawer={showPickupDetailsDrawer} setShowPickupDetailsDrawer={setShowPickupDetailsDrawer} />}
    </>
  )
}

CarrierHeader.propTypes = {
  carrier: PropTypes.shape({
    code: PropTypes.string,
    division: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    provinceCode: PropTypes.string,
    provinceName: PropTypes.string,
    timezone: PropTypes.string,
  }).isRequired,
  pickup: PropTypes.instanceOf(Object),
  shippingDate: PropTypes.string.isRequired,
  ordersCount: PropTypes.number.isRequired,
  trackingId: PropTypes.string,
}

CarrierHeader.defaultProps = {
  pickup: undefined,
  trackingId: "",
}

export default CarrierHeader
