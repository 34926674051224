// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Modal from "@mui/material/Modal"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"
import { CustomModalContainerBox } from "../app/style"

// Custom
import CardContentNoPaddingBottom from "../../soe-theme/src/components/cardContent-no-padding-bottom"

// Others
import { getAddressFormat, isRequiredField } from "../../services/address-form"

import FullAddressForm from "../full-address-form"
import LanguageSelector from "../../soe-theme/src/components/language-selector"
import TermsAndConditions from "./components/terms-conditions"

import { LANGUAGES } from "../../utils/constants"
import { useEditUserProfileMutation } from "../../services/user/slice"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../services/address-form/slice"

function AccountConfiguration({ setAccountConfiguration }) {
  const { i18n, t } = useTranslation("accountConfiguration")
  const customTheme = useTheme()

  const currentUser = useSelector((state) => state.user)

  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false)
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()

  const [editUserProfile] = useEditUserProfileMutation()

  const handleTermsConditionsCheckedClick = (event) => {
    setTermsConditionsChecked(event.target.checked)
  }

  const handleTermsAndConditionsLinkClick = (event) => {
    event.preventDefault()
    return window.open("https://inex.ca/terms/", "_blank") || window.location.replace("https://inex.ca/terms/")
  }

  const clientAddress = currentUser?.company?.clientAddress?.companyName ? currentUser?.company?.clientAddress : currentUser?.clientAddress
  const formik = useFormik({
    initialValues: {
      companyName: clientAddress?.companyName || "",
      personName: currentUser?.personName || "",
      addressLine1: clientAddress?.addressLine1 || "",
      addressLine2: clientAddress?.addressLine2 || "",
      city: clientAddress?.city || "",
      provinceName: clientAddress?.provinceName || "",
      provinceCode: clientAddress?.provinceCode || "",
      countryCode: clientAddress?.countryCode || "CA",
      postalCode: clientAddress?.postalCode || "",
      phoneNumber: clientAddress?.phoneNumber || "",
    },
    validate: async (formValues) => {
      const errors = {}

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.phoneNumber) {
        errors.phoneNumber = t("fields.phoneNumber.error.required")
      }

      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      editUserProfile({
        address: {
          companyName: formValues.companyName || undefined,
          personName: formValues.personName || undefined,
          addressLine1: formValues.addressLine1 || undefined,
          addressLine2: formValues.addressLine2 || undefined,
          city: formValues.city || undefined,
          province: formValues.province || undefined,
          provinceCode: formValues.provinceCode || undefined,
          countryCode: formValues.countryCode || undefined,
          postalCode: formValues.postalCode || undefined,
          phoneNumber: formValues.phoneNumber || undefined,
        },
      })
      setAccountConfiguration(false)
    },
  })

  useEffect(() => {
    formik.setErrors({})
    formik.setTouched({})
  }, [formik.values.countryCode])

  return (
    <Box className="accountConfiguration" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Card sx={(theme) => ({ maxHeight: theme.utils.pxToRem(768) })}>
          <CardHeader title={t("title")} />
          <Divider />
          <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(452) })}>
            <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
              <FullAddressForm formik={formik} displaySearchAddressBar={false} displayResidentialToggle={false} displaySaveAddressCheckbox={false} displayEmailAddressField={false} />
              <FormControlLabel
                control={<Checkbox checked={termsConditionsChecked} onChange={handleTermsConditionsCheckedClick} />}
                label={
                  <>
                    {t("termsAndConditionsPart1")}
                    <Link onClick={handleTermsAndConditionsLinkClick} style={{ textDecoration: "none", fontWeight: customTheme.typography.fontWeightBold }}>
                      {t("termsAndConditionsLink")}
                    </Link>
                    {t("termsAndConditionsPart2")}
                  </>
                }
              />
              <Box component="div">
                <CardContentNoPaddingBottom sx={{ display: "grid", justifyContent: "center" }}>
                  <Button variant="contained" onClick={formik.handleSubmit} sx={{ textTransform: "none" }} disabled={!termsConditionsChecked}>
                    {t("continue")}
                  </Button>
                </CardContentNoPaddingBottom>
              </Box>
              <Box component="div">
                <CardContentNoPaddingBottom sx={{ display: "grid", justifyContent: "center" }}>
                  <LanguageSelector i18n={i18n} languages={LANGUAGES} />
                </CardContentNoPaddingBottom>
              </Box>
            </CardContentNoPaddingBottom>
          </Box>
        </Card>
      </Grid>
      <Modal open={showTermsAndConditions} hideBackdrop onClose={() => setShowTermsAndConditions(false)}>
        <CustomModalContainerBox className="authContainer" component="div">
          <TermsAndConditions setShowTermsAndConditions={setShowTermsAndConditions} />
        </CustomModalContainerBox>
      </Modal>
    </Box>
  )
}

AccountConfiguration.propTypes = {
  setAccountConfiguration: PropTypes.func.isRequired,
}

export default AccountConfiguration
