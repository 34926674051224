import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from "@mui/material"
import UsersItem from "./users-row"

import "./style.scss"

function ManageUsers({ users, isFetching, onRoleChange, onStatusChange }) {
  const [inviteUserProgess, setInviteUserProgess] = useState("") // show_input

  const { t } = useTranslation("company")
  const currentUser = useSelector((state) => state.user)

  const headCells = [
    {
      id: "userName",
      disablePadding: false,
      label: t("headers.userName"),
    },
    {
      id: "userEmail",
      disablePadding: false,
      label: t("headers.userEmail"),
    },
    {
      id: "userRole",
      disablePadding: false,
      label: t("headers.userRole"),
    },
    {
      id: "date",
      disablePadding: false,
      label: t("headers.date"),
    },
    {
      id: "access",
      disablePadding: false,
      label: t("headers.access"),
    },
  ]

  const sortByStatus = () => (a, b) => {
    const mapping = {
      requested: 1,
      sent: 2,
      accepted: 3,
      rejected: 4,
    }

    return mapping[a.status?.toLowerCase()] - mapping[b.status?.toLowerCase()]
  }

  return (
    <Box className="manage-users-table" style={{ marginBottom: "50px" }}>
      <TableContainer style={{ marginTop: 50 }}>
        <Typography
          className="primaryLabel"
          component="h2"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: 600,
            fontSize: theme.utils.pxToRem(22),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
            marginBottom: theme.utils.pxToRem(10),
            display: "inline",
            padding: "0px 5px",
          })}
        >
          {t("manageUsers.title")}
        </Typography>
        <br />
        <Table
          size="small"
          sx={(theme) => ({
            borderCollapse: "separate",
            borderSpacing: "0px 10px",
            backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
            padding: "20px 50px",
            marginTop: "10px",
          })}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "white" }}>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? "none" : "normal"}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ marginTop: 50 }}>
            {isFetching ? (
              <>
                {Array(3)
                  .fill()
                  .map((_, index) => {
                    return (
                      <TableRow key={"".concat(index)}>
                        <TableCell colSpan={12}>
                          <Skeleton variant="rectangular" height={50} width="100%" />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </>
            ) : (
              <>
                {Array.isArray(users) &&
                  users
                    .slice()
                    .sort(sortByStatus())
                    .map((user) => (
                      <UsersItem
                        key={user.pk}
                        user={user}
                        companyName={currentUser.company?.companyName}
                        onRoleChange={onRoleChange}
                        onStatusChange={onStatusChange}
                      />
                    ))}
                {inviteUserProgess && (
                  <UsersItem
                    key="create request"
                    inviteUserProgess={inviteUserProgess}
                    companyName={currentUser.company?.companyName}
                    setInviteUserProgess={setInviteUserProgess}
                  />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

ManageUsers.defaultProps = {
  users: [],
  isFetching: false,
}

ManageUsers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  onRoleChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
}

export default ManageUsers
