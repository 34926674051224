// REACT
import React from "react"

// MUI
import Container from "@mui/material/Container"

// Others
import Main from "../main"

function Test() {
  return (
    <Container>
      <Main>{/* test your component here */}</Main>
    </Container>
  )
}
export default Test
