import { createAsyncThunk } from "@reduxjs/toolkit"
import UserPool from "../services/user-pool"
import restApiSlice from "../services/rest-api/slice"

export const changePassword = createAsyncThunk("auth/changePassword", async (formValues) => {
  return new Promise((resolve, reject) => {
    const currentUser = UserPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession((err) => {
        if (err) {
          reject(err)
        } else {
          currentUser.changePassword(formValues.oldPassword, formValues.newPassword, (error, res) => {
            if (error) {
              reject(error)
            } else {
              resolve(res)
            }
          })
        }
      })
    } else {
      reject(new Error("User is not authenticated"))
    }
  })
})

const profileRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarriers: builder.query({
      query: () => ({ url: "/carriers", method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    changeUserEmail: builder.mutation({
      query: (payload) => ({ url: `/users/${payload.oldEmail}/changeEmail`, method: "PUT", body: payload }),
    }),
  }),
})

export const { useGetCarriersQuery, useChangeUserEmailMutation } = profileRestApiSlice

export default profileRestApiSlice.reducer
