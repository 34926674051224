const TranslationService = {
  getLabelFromLocale(locale, labels) {
    if (labels && labels[locale]) {
      return typeof labels[locale] === "object" ? JSON.stringify(labels[locale]) : labels[locale]
    }
    if (labels?.en) {
      return typeof labels.en === "object" ? JSON.stringify(labels.en) : labels.en
    }
    // @todo: Manage Exceptions
    return undefined
  },
}

export default TranslationService
