// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContentText from "@mui/material/DialogContentText"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"

// Others
import i18next from "i18next"

import { CustomDialogActions, CustomDialogContent, CustomDialogTitle } from "./style"

function ConfirmModal({ open, handleClose, title, modalContent, modalActions }) {
  return (
    <Dialog open={open} onClose={() => handleClose()} style={{ minWidth: "500px" }}>
      <CustomDialogTitle>
        <Typography variant="headingH6" sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main, fontWeight: theme.typography.fontWeightBold })}>
          {title}
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </CustomDialogTitle>
      {modalContent}
      {modalActions}
    </Dialog>
  )
}

export default ConfirmModal

ConfirmModal.defaultProps = {
  open: false,
  modalContent: (
    <CustomDialogContent>
      <DialogContentText>
        <Typography variant="bodyMedium400">{i18next.t("confirmModal:confirmModal.contentText")}</Typography>
      </DialogContentText>
    </CustomDialogContent>
  ),
  modalActions: (
    <CustomDialogActions>
      <Button>{i18next.t("confirmModal:confirmModal.modalActions.cancelButton.label")}</Button>
      <Button>{i18next.t("confirmModal:confirmModal.modalActions.confirmButton.label")}</Button>
    </CustomDialogActions>
  ),
}

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  modalContent: PropTypes.node,
  modalActions: PropTypes.node,
}
