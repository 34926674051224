// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import RateRequestSummaryCard from "../rate-request-summary-card"
import { FREIGHT_OPTIONS } from "../../utils/constants"
import { isFreightOptionsComplete } from "../quotations/utils"

function FreightOptionsCard({ freightOptions, setShowRateRequestDrawer, disable, isEditable, ...sw }) {
  const { t } = useTranslation("freightOptionsCard")
  const customTheme = useTheme()

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("freight-options")
    setShowRateRequestDrawer(true)
  }

  const summaryStatusProps = {
    variant: isFreightOptionsComplete(freightOptions) ? "success" : "warning",
  }

  const getAfterHoursLabel = () => {
    const label = t(`afterHours.options.${FREIGHT_OPTIONS.AFTER_HOURS.find((afterHour) => afterHour.value === freightOptions.afterHours).label}`)
    return label || ""
  }

  const getTwoPersonsRequiredLabel = () => {
    const label = t(`twoPersons.options.${FREIGHT_OPTIONS.TWO_PERSONS.find((twoPerson) => twoPerson.value === freightOptions.twoPersons).label}`)
    return label || ""
  }

  const getTailgateRequired = () => {
    const label = t(`tailgate.options.${FREIGHT_OPTIONS.TAILGATE.find((tailgate) => tailgate.value === freightOptions.tailgate).label}`)
    return label || ""
  }

  const getIsEmptyOptions = () => {
    return Object.values(freightOptions).every((value) => value === undefined)
  }

  return (
    <RateRequestSummaryCard title={t("title")} summaryStatusProps={summaryStatusProps} handleClick={showRateRequestDrawer} disable={disable} showStatus={isEditable} isClickable={isEditable}>
      {freightOptions && !getIsEmptyOptions() && (
        <Box mt={customTheme.spacing(1)} sx={{ display: "flex", flexDirection: "column" }}>
          {freightOptions?.appointment && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("appointment.label")}
            </Typography>
          )}
          {freightOptions?.prebookedAppointment && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("prebookedAppointment.label")}
            </Typography>
          )}
          {freightOptions?.afterHours && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("afterHours.label")}
              {getAfterHoursLabel()}
            </Typography>
          )}
          {freightOptions?.twoPersons && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("twoPersons.label")}
              {getTwoPersonsRequiredLabel()}
            </Typography>
          )}
          {freightOptions?.tailgate && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {t("tailgate.label")}
              {getTailgateRequired()}
            </Typography>
          )}
        </Box>
      )}
      {freightOptions && getIsEmptyOptions() && (
        <Box mt={customTheme.spacing(1)} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {t("noOptions.label")}
          </Typography>
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

FreightOptionsCard.propTypes = {
  freightOptions: PropTypes.instanceOf(Object),
  setShowRateRequestDrawer: PropTypes.func,
  disable: PropTypes.bool,
  isEditable: PropTypes.bool,
}

FreightOptionsCard.defaultProps = {
  freightOptions: undefined,
  setShowRateRequestDrawer: () => {},
  disable: false,
  isEditable: true,
}

export default FreightOptionsCard
