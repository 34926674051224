// REACT
import PropTypes from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

// Others
import SoeCancelIcon from "../../soe-theme/src/icons/SoeCancelIcon"
import { clearAlert } from "../../reducers/alertReducer"

function CustomAlert({ displayCloseIconButton }) {
  const { showAlert, severity, message } = useSelector((state) => state.alert)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(clearAlert())
  }

  const displayMessage = () => {
    if (typeof message === "string" && message?.includes("contact us for assistance")) {
      const [start, end] = message.split("contact us")
      return (
        <span>
          {start}{" "}
          <a style={{ textDecoration: "underline" }} target="blank" href="https://inex.ca/contact">
            contact us
          </a>{" "}
          {end}
        </span>
      )
    }
    return message
  }

  return (
    <Box component="div">
      <Collapse in={showAlert}>
        {showAlert && (
          <Alert
            severity={severity}
            onClose={handleClose}
            action={
              displayCloseIconButton && (
                <IconButton onClick={handleClose}>
                  <SoeCancelIcon size="medium" />
                </IconButton>
              )
            }
            sx={{ m: 1 }}
          >
            <Typography component="span" variant="bodyMedium400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(14, 24) })}>
              {displayMessage()}
            </Typography>
          </Alert>
        )}
      </Collapse>
    </Box>
  )
}

CustomAlert.defaultProps = {
  displayCloseIconButton: true,
}

CustomAlert.propTypes = {
  displayCloseIconButton: PropTypes.bool,
}

export default CustomAlert
