import restApiSlice from "../../../../../../services/rest-api/slice"

const pickupRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addPickupsSchedule: builder.mutation({
      query: (payload) => ({ url: `/v2/carriers/generic/pickup/schedule`, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
      extraOptions: {
        target: "pickupSchedule",
      },
    }),
    deletePickup: builder.mutation({
      query: (pickupId) => ({ url: `/v2/carriers/generic/pickup/cancel/${pickupId}`, method: "DELETE" }),
      transformResponse: (response) => response.data,
    }),
    getAdditionalFields: builder.query({
      query: (carrierCode) => ({ url: `/v2/carriers/generic/pickup/fields/${carrierCode}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useAddPickupsScheduleMutation, useDeletePickupMutation, useGetAdditionalFieldsQuery } = pickupRestApiSlice

export default pickupRestApiSlice.reducer
