// REACT
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"

function ThirdPartyBillingAccounts({ handleThirdPartyBillingAccountsClick }) {
  const { t } = useTranslation("userProfile")

  const currentCompany = useSelector((state) => state.user?.company)
  const getSummaryStatus = () => SummaryStatusVariantsEnum.success
  const summaryStatusProps = { variant: getSummaryStatus() }

  const capitalizeCarrierCode = (str) => {
    const sanitized = str.replace("-", " ")
    return sanitized.charAt(0).toUpperCase() + sanitized.slice(1)
  }

  return (
    <UserProfileSummaryCard title={t("userProfile.thirdPartyBillingAccountsDrawer.header")} handleClick={handleThirdPartyBillingAccountsClick} summaryStatusProps={summaryStatusProps}>
      {currentCompany?.billing?.accountNumbers && Array.isArray(currentCompany?.billing?.accountNumbers) && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {currentCompany.billing.accountNumbers.map((account, i) => (
            <Typography key={i} variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {account.name} - {capitalizeCarrierCode(account.carrierCode)}
            </Typography>
          ))}
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

ThirdPartyBillingAccounts.propTypes = {
  handleThirdPartyBillingAccountsClick: PropTypes.func,
}

ThirdPartyBillingAccounts.defaultProps = {
  handleThirdPartyBillingAccountsClick: () => {},
}

export default ThirdPartyBillingAccounts
