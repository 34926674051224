export const MESSAGE_TYPE = {
  CONNECTION: "connection",
  RATE_RESPONSE: "rateResponse",
  RATE_STATUS: "rateStatus",
  PAYMENT_STATUS: "paymentStatus",
  CREATE_ORDER_STATUS: "createOrderStatus",
}

export const MESSAGE_STATUS = {
  RECEIVED: "RECEIVED",
  STARTED: "STARTED",
  SUCCEEDED: "SUCCEEDED",
}

export const MESSAGE_ORDER_STATUS = {
  REQUEST_RECEIVED: "request_received",
  REQUEST_PROCESSING: "request_processing",
  PAYMENT_VALIDATING: "payment_validating",
  PAYMENT_VALIDATED: "payment_validated",
  ORDER_VALIDATING: "order_validating",
  ORDER_VALIDATED: "order_validated",
  ORDER_PROCESSING: "order_processing",
  ORDER_PROCESSED: "order_processed",
  COMPLETE: "complete",
}
