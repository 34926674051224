import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

import FullAddressForm from "../../../../../full-address-form"

import { getAddressFormat, isRequiredField } from "../../../../../../services/address-form"
import { AddressTypeEnum } from "../../../../../../constants/quotationsConstants"
import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { updateBuyer } from "../../../../../../reducers/quotationsReducer"
import { useAddAddressBookItemMutation, useEditAddressBookItemMutation } from "../../../../../address-book/slice"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../services/address-form/slice"

function RateRequestBuyer({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { t } = useTranslation("fullAddressForm")
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.user)
  const { buyer } = useSelector((state) => state.quotations)

  const [action, setAction] = useState("")

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()

  const [addAddressBookItem, { data: newAddressBookItem }] = useAddAddressBookItemMutation()
  const [editAddressBookItem, { data: updatedAddressBookItem }] = useEditAddressBookItemMutation()

  useEffect(() => {
    if (newAddressBookItem && newAddressBookItem.data) {
      dispatch(updateBuyer(newAddressBookItem.data))
    }
  }, [newAddressBookItem])

  useEffect(() => {
    if (updatedAddressBookItem && updatedAddressBookItem.data) {
      dispatch(updateBuyer(updatedAddressBookItem.data))
    }
  }, [updatedAddressBookItem])

  const formik = useFormik({
    initialValues: {
      id: buyer?.id || undefined,
      companyName: buyer?.companyName || "",
      personName: buyer?.personName || "",
      addressLine1: buyer?.addressLine1 || "",
      addressLine2: buyer?.addressLine2 || "",
      city: buyer?.city || "",
      provinceName: buyer?.provinceName || "",
      provinceCode: buyer?.provinceCode || "",
      countryCode: buyer?.countryCode || currentUser?.company?.clientAddress?.countryCode || currentUser.clientAddress.countryCode || "CA",
      postalCode: buyer?.postalCode || "",
      residential: buyer?.residential || undefined,
      phoneNumber: buyer?.phoneNumber || "",
      emailAddress: buyer?.emailAddress || "",
      timezone: buyer?.timezone || undefined,
      validationProviderId: buyer?.validationProviderId || undefined,
    },
    validate: async (formValues) => {
      const errors = {}

      if (!formValues.personName.trim()) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.phoneNumber.trim()) {
        errors.phoneNumber = t("fields.phoneNumber.error.required")
      }

      if (!formValues.emailAddress.trim()) {
        errors.emailAddress = t("fields.emailAddress.error.required")
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      formValues = { ...formValues, type: AddressTypeEnum.Buyer }
      if (formValues.id) {
        editAddressBookItem({ id: formValues.id, payload: formValues })
      } else {
        addAddressBookItem({ ...formValues })
      }

      if (action === "continue") {
        wizardProps.nextStep()
      }

      if (action === "saveAndClose") setShowRateRequestDrawer(false)
    },
  })

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  useEffect(() => {
    formik.setErrors({})
    formik.setTouched({})
  }, [formik.values.countryCode])

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.buyer.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent>
        <FullAddressForm formik={formik} displaySearchAddressBar displayEmailAddressField displaySaveAddressCheckbox={false} displaySaveDefaultAddressCheckbox={false} displayResidentialToggle={false} addressType={AddressTypeEnum.Buyer} />
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={[
          {
            action: handleSaveAndCloseClick,
            label: t("drawer.buyer.actions.prevButton.label", { ns: "rateRequest" }),
            variant: "outlined",
          },
        ]}
      />
    </SoeDrawerContainer>
  )
}

RateRequestBuyer.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestBuyer.defaultProps = {}

export default RateRequestBuyer
