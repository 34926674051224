// REACT
import React, { useState } from "react"

// Mui
import Box from "@mui/material/Box"

import ResetPasswordSubmitForm from "./components/reset-password-submit-form"
import ResetPasswordForm from "./components/reset-password-form"

import ResetPasswordHeader from "./components/reset-password-header"
import ResetPasswordSubmitHeader from "./components/reset-password-submit-header"

import { ReactComponent as LeftBackground } from "../../assets/images/log-in-left-backgraound.svg"

import "./style.scss"

function ResetPassword() {
  const [pageState, setPageState] = useState("emailValidation")
  const [resetEmail, setResetEmail] = useState("")

  const handleSendEmail = (email) => {
    setResetEmail(email)
    setPageState("codeValidation")
  }

  return (
    <Box flexDirection="column" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1 })}>
      {pageState === "emailValidation" && (
        <>
          <ResetPasswordHeader />
          <ResetPasswordForm handleSendEmail={handleSendEmail} />
        </>
      )}

      {pageState === "codeValidation" && (
        <>
          <ResetPasswordSubmitHeader resetEmail={resetEmail} />
          <ResetPasswordSubmitForm resetEmail={resetEmail} />
        </>
      )}

      <Box className="left-background" style={{ zIndex: "-2", preserveAspectRatio: "none" }}>
        <LeftBackground />
      </Box>
      <Box className="right-background" style={{ zIndex: "-2", preserveAspectRatio: "none" }}>
        <LeftBackground />
      </Box>
    </Box>
  )
}

export default ResetPassword
