import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "AppInfo", slot: "Root" })(() => ({}))

export const CustomChip = styled(Chip, { label: "CustomChip", slot: "Root" })(({ theme }) => ({
  marginLeft: theme.spacing(1),
  borderColor: theme.palette.inexPrimary.main,
  color: theme.palette.inexPrimary.main,
}))
