// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
// @todo: to remove
import { isDev } from "../../../../../../utils/get-environment"

function GeneralInfoNotificationDrawer({ notificationInfo, onSubmitEvent, setShowGeneralInfoNotificationDrawer }) {
  const customTheme = useTheme()
  const { t } = useTranslation("userProfile")

  const formik = useFormik({
    initialValues: {
      id: notificationInfo?.id || "", // @todo: to adjust
    },
    validate: (formValues) => {
      // @todo: to remove
      if (isDev()) {
        // eslint-disable-next-line no-console
        console.log(formValues)
      }
      // const errors = {}
      // @todo: to adjust
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      // @todo: to remove
      if (isDev()) {
        // eslint-disable-next-line no-console
        console.log(formValues)
      }
      if (notificationInfo) {
        // @todo: to adjust
        // await editUserProfileNotificationInfo({ payload: formValues })
      }
      formik.resetForm()
      setShowGeneralInfoNotificationDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" py={1}>
          <TextField
            id="notificationInfoId"
            name="notificationInfoId"
            inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular } }}
            variant="outlined"
            size="small"
            label={t("userProfile.notificationInfoDrawer.id.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.notificationInfoId || ""}
            error={formik.touched.notificationInfoId && formik.errors.notificationInfoId !== undefined}
            helperText={(formik.touched.notificationInfoId && formik.errors.notificationInfoId) || " "}
            fullWidth
          />
        </Box>
        <Box component="div" p={1} />
      </form>
    </Box>
  )
}

GeneralInfoNotificationDrawer.defaultProps = {
  notificationInfo: undefined,
  onSubmitEvent: undefined,
}

GeneralInfoNotificationDrawer.propTypes = {
  notificationInfo: PropTypes.instanceOf(Object),
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowGeneralInfoNotificationDrawer: PropTypes.func.isRequired,
}

export default GeneralInfoNotificationDrawer
