// REACT
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import Typography from "@mui/material/Typography"

// Custom theme
import { Grid, useTheme } from "@mui/material"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"

// Others
import GeneralInfoEmailDrawer from "./components/general-info-email-drawer"
import GeneralInfoContactCard from "./components/general-info-contact-card"
import GeneralInfoContactDrawer from "./components/general-info-contact-drawer"
import GeneralInfoPasswordCard from "./components/general-info-password-card"
import GeneralInfoPasswordDrawer from "./components/general-info-password-drawer"
import GeneralInfoLanguageCard from "./components/general-info-language-card"
import GeneralInfoLanguageDrawer from "./components/general-info-language-drawer"
import GeneralInfoNotificationCard from "./components/general-info-notification-card"
import GeneralInfoNotificationDrawer from "./components/general-info-notification-drawer"
import RegularlyScheduledPickupsCard from "./components/regularly-scheduled-pickups-card"
import RegularlyScheduledPickupsDrawer from "./components/regularly-scheduled-pickups-drawer"
import ImpersonateService from "../../../../services/impersonate"

function UserProfileGeneralInfo() {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const currentUser = useSelector((state) => state.user)

  const showGeneralInfoNotificationCard = false

  const [successAlert, setSuccessAlert] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [showGeneralInfoContactDrawer, setShowGeneralInfoContactDrawer] = useState(false)
  const [showGeneralInfoPasswordDrawer, setShowGeneralInfoPasswordDrawer] = useState(false)
  const [showGeneralInfoEmailDrawer, setShowGeneralInfoEmailDrawer] = useState(false)
  const [showGeneralInfoLanguageDrawer, setShowGeneralInfoLanguageDrawer] = useState(false)
  const [showGeneralInfoNotificationDrawer, setShowGeneralInfoNotificationDrawer] = useState(false)
  const [regularlyScheduledPickups, setRegularlyScheduledPickupsDrawer] = useState(false)

  const [onSubmitEvent, setSubmitEvent] = useState()

  const handleOpenAlert = () => {
    setSuccessAlert({ ...successAlert, open: true });
  };

  const handleCloseAlert = () => {
    setSuccessAlert({ ...successAlert, open: false });
  };

  const handleGeneralInfoContactCardClick = () => {
    setSubmitEvent()
    setShowGeneralInfoContactDrawer(true)
  }

  const handleGeneralInfoPasswordCardClick = () => {
    setSubmitEvent()
    setShowGeneralInfoPasswordDrawer(true)
  }

  const handleGeneralInfoLanguageCardClick = () => {
    setSubmitEvent()
    setShowGeneralInfoLanguageDrawer(true)
  }

  const handleGeneralInfoNotificationCardClick = () => {
    setSubmitEvent()
    setShowGeneralInfoNotificationDrawer(true)
  }

  const handleRegularlyScheduledPickupsCardClick = () => {
    setSubmitEvent()
    setRegularlyScheduledPickupsDrawer(true)
  }

  return (
    <Box component="div">

      <Snackbar onClose={handleCloseAlert} open={successAlert.open} autoHideDuration={6000} anchorOrigin={{ vertical: successAlert.vertical, horizontal: successAlert.horizontal }} >
        <Alert severity="success" onClose={handleCloseAlert} sx={{ width: '100%' }}>
          You successfuly update your email!
        </Alert>
      </Snackbar>

      <Box display="flex" pt={customTheme.utils.pxToThemeSpacing(20)} pr={customTheme.utils.pxToThemeSpacing(10)} pb={customTheme.utils.pxToThemeSpacing(20)} pl={customTheme.utils.pxToThemeSpacing(10)}>
        <Box component="h5">
          <Typography sx={(theme) => ({ fontSize: customTheme.utils.pxToRem(18), fontWeight: theme.typography.fontWeightBold })}>{t("userProfile.generalInformation.title.label")}</Typography>
        </Box>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <GeneralInfoContactCard handleGeneralInfoContactCardClick={() => handleGeneralInfoContactCardClick()} />
        </Grid>
        {!ImpersonateService.currentImpersonation() && (
          <Grid item xs={3}>
            <GeneralInfoPasswordCard handleGeneralInfoPasswordCardClick={() => handleGeneralInfoPasswordCardClick()} />
          </Grid>
        )}
        <Grid item xs={3}>
          <GeneralInfoLanguageCard handleGeneralInfoLanguageCardClick={() => handleGeneralInfoLanguageCardClick()} />
        </Grid>
        {showGeneralInfoNotificationCard && (
          <Grid item xs={3}>
            <GeneralInfoNotificationCard handleGeneralInfoNotificationCardClick={() => handleGeneralInfoNotificationCardClick()} />
          </Grid>
        )}
        {currentUser.companyRole !== 'BILL' && (
          <Grid item xs={3}>
            <RegularlyScheduledPickupsCard handleRegularlyScheduledPickupsCardClick={() => handleRegularlyScheduledPickupsCardClick()} />
          </Grid>
        )}
      </Grid>
      <SoeDrawer showDrawer={showGeneralInfoContactDrawer} setShowDrawer={setShowGeneralInfoContactDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.generalInfoContactDrawer.title")}
            setShowDrawer={() => {
              setShowGeneralInfoContactDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <GeneralInfoContactDrawer onSubmitEvent={onSubmitEvent} setShowGeneralInfoContactDrawer={setShowGeneralInfoContactDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.generalInfoContactDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showGeneralInfoPasswordDrawer} setShowDrawer={setShowGeneralInfoPasswordDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.generalInfoPasswordDrawer.changePassword.title")}
            setShowDrawer={() => {
              setShowGeneralInfoPasswordDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <GeneralInfoPasswordDrawer onSubmitEvent={onSubmitEvent} setShowGeneralInfoPasswordDrawer={setShowGeneralInfoPasswordDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.generalInfoPasswordDrawer.changePassword.actions.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showGeneralInfoEmailDrawer} setShowDrawer={setShowGeneralInfoEmailDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.generalInfoPasswordDrawer.changePassword.title")}
            setShowDrawer={() => {
              setShowGeneralInfoEmailDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <GeneralInfoEmailDrawer currentUser={currentUser} handleOpenAlert={handleOpenAlert} onSubmitEvent={onSubmitEvent} setShowGeneralInfoEmailDrawer={setShowGeneralInfoEmailDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.generalInfoPasswordDrawer.changePassword.actions.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={showGeneralInfoLanguageDrawer} setShowDrawer={setShowGeneralInfoLanguageDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.generalInfoLanguageDrawer.title")}
            setShowDrawer={() => {
              setShowGeneralInfoLanguageDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <GeneralInfoLanguageDrawer onSubmitEvent={onSubmitEvent} setShowGeneralInfoLanguageDrawer={setShowGeneralInfoLanguageDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.generalInfoLanguageDrawer.actions.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <SoeDrawer showDrawer={showGeneralInfoNotificationDrawer} setShowDrawer={setShowGeneralInfoNotificationDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.generalInfoNotificationDrawer.title")}
            setShowDrawer={() => {
              setShowGeneralInfoNotificationDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <GeneralInfoNotificationDrawer onSubmitEvent={onSubmitEvent} setShowGeneralInfoNotificationDrawer={setShowGeneralInfoNotificationDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.generalInformationDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>

      <SoeDrawer showDrawer={regularlyScheduledPickups} setShowDrawer={setRegularlyScheduledPickupsDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.regularlyScheduledPickupsDrawer.title")}
            setShowDrawer={() => {
              setRegularlyScheduledPickupsDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <RegularlyScheduledPickupsDrawer onSubmitEvent={onSubmitEvent} setRegularlyScheduledPickupsDrawer={setRegularlyScheduledPickupsDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.regularlyScheduledPickupsDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Box>
  )
}

export default UserProfileGeneralInfo
