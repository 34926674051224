import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { MenuItem } from "@mui/material"

import { createAction } from "@reduxjs/toolkit"
import WebSocketService from "../../../../services/websocket"

import { signOut } from"../../../../reducers/authenticatorReducer"
import { setInitialState } from "../../../../reducers/userReducer"

import "./style.scss"
import ImpersonateService from "../../../../services/impersonate"

function SignOut({ setIsMenuOpen }) {
  const { t } = useTranslation("authenticator")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <MenuItem
      className="menuItem"
      onClick={async () => {
        dispatch(setInitialState())
        setIsMenuOpen(false)
        WebSocketService.close()
        dispatch(signOut()).then(() => {
          const resetStore = createAction("RESET_STORE")
          dispatch(resetStore())
        })
        ImpersonateService.unimpersonate()
        navigate("/", { replace: true })
      }}
    >
      {t("signOut.fields.signOutButton.label")}
    </MenuItem>
  )
}

SignOut.propTypes = {
  setIsMenuOpen: PropTypes.func,
}

SignOut.defaultProps = {
  setIsMenuOpen: () => {},
}

export default SignOut
