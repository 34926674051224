import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

import { styled } from "@mui/material/styles"

export const CustomModalContainerBox = styled(Box, { name: "AuthContainerBox", slot: "Root" })(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}))

export const MainCustomButton = styled(Button, { label: "MainCustomButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: theme.palette.inexPrimary.main,
  color: "white",
  textTransform: "uppercase",
  boxShadow: "0px 3px 6px #00000029",
  borderColor: theme.palette.inexPrimary.main,
  width: "150px",
  height: "47.2px",
  "&:hover": {
    backgroundColor: theme.palette.inexSecondary.main,
    borderColor: theme.palette.inexSecondary.main,
    color: "white",
  },
  "&:disabled": {
    opacity: 0.6,
    color: "white",
  },
}))

export const SecondaryCustomButton = styled(Button, { label: "SecondaryCustomButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: "white",
  color: theme.palette.inexPrimary.main,
  textTransform: "uppercase",
  boxShadow: "0px 3px 6px #00000029",
  borderColor: theme.palette.inexPrimary.main,
  width: "150px",
  height: "47.2px",
  "&:hover": {
    backgroundColor: "white",
    borderColor: "white",
    color: theme.palette.inexSecondary.main,
  },
}))

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: theme.utils.pxToRem(20),
  },
  "& .MuiInputBase-root": {
    borderRadius: "3px",
    fontFamily: "Poppins",
    height: theme.utils.pxToRem(55),
  },
  "& label.Mui-focused": {
    color: theme.palette.inexTertiary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.inexTertiary.main,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.inexTertiary.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.inexTertiary.main,
    },
    "&.Mui-focused fieldset": {
      border: `solid 1px ${theme.palette.inexPrimary.main}`,
    },
  },
}))
