// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { FieldArray, FormikProvider, useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AutoCompletePaper from "../../../../../../soe-theme/src/components/auto-complete-paper"
import CardContentNoPaddingBottom from "../../../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import InvoiceForm from "./components/invoice-form"
import SoeDrawerActions from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-actions"
import SoeDrawerContainer from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-container"
import SoeDrawerContent from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-content"
import SoeDrawerHeader from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-header"
import SoeDeleteOutlineIcon from "../../../../../../soe-theme/src/icons/SoeDeleteOutlineIcon"

import { AddressTypeEnum } from "../../../../../../constants/quotationsConstants"
import { updateBuyer, updateCommercialInvoice, updateInternationalOptions, updateShippingOptions, useGetCurrenciesQuery } from "../../../../../../reducers/quotationsReducer"
import { useGetAddressBookQuery } from "../../../../../address-book/slice"
import { IMPERIAL } from "../../../../../../services/units-conversion"
import { isInternationalOptionsComplete, isShippingOptionsComplete } from "../../../../utils"

function RateRequestCommercialInvoice({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { t } = useTranslation(["quotations", "constant"])
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.user)

  const { buyer, commercialInvoice, internationalOptions, shippingOptions, packages } = useSelector((state) => state.quotations)

  const [action, setAction] = useState("")
  const [buyerChecked, setBuyerChecked] = useState(!!buyer)
  const [buyerList, setBuyerList] = useState([])
  const [selectedBuyer, setSelectedBuyer] = useState(buyer || undefined)

  const { data: currencies } = useGetCurrenciesQuery()
  const { data: addressBookItems } = useGetAddressBookQuery()

  const newBuyer = { id: "newBuyer", companyName: "", personName: "" }

  const customTheme = useTheme()

  const SPACING = 10

  useEffect(() => {
    let buyers = [newBuyer]
    if (addressBookItems) {
      buyers = buyers.concat(addressBookItems.filter((addressBookItem) => addressBookItem.type === AddressTypeEnum.Buyer))
    }
    setBuyerList(buyers)
  }, [addressBookItems])

  const emptyInvoice = {
    description: "",
    hsCode: "",
    quantity: 1,
    value: undefined,
    weight: undefined,
    countryOfManufacture: currentUser?.company?.clientAddress?.countryCode || currentUser?.clientAddress?.countryCode || "CA",
  }

  const formik = useFormik({
    initialValues: {
      currency: commercialInvoice?.currency || internationalOptions?.currency || currentUser.billing.currency || "CAD",
      unitOfMeasure: commercialInvoice?.unitOfMeasure || packages?.unitOfMeasure || IMPERIAL,
      invoiceItems: commercialInvoice?.invoiceItems?.length > 0 ? commercialInvoice.invoiceItems : [emptyInvoice],
      customsValue: commercialInvoice?.customsValue || 0,
    },
    validateOnChange: true,
    enableReinitialize: true,
    validate: (formValues) => {
      const errors = {
        invoiceItems: [],
      }
      formValues.invoiceItems.forEach((invoice) => {
        const error = {}

        if (!invoice.description) {
          error.description = true
        }
        if (!invoice.quantity || invoice.quantity < 0) {
          error.quantity = true
        }
        if (!invoice.value || invoice.value < 0) {
          error.value = true
        }

        if (!invoice.weight || invoice.weight < 0) {
          error.weight = true
        }

        errors.invoiceItems.push(error)
      })

      if (buyerChecked && Object.keys(!selectedBuyer ? "" : selectedBuyer).length === 0) {
        errors.selectedBuyer = true
      }

      let isValid = true

      errors.invoiceItems.forEach((err) => {
        if (Object.keys(err).length > 0) {
          isValid = false
        }
      })
      return isValid ? undefined : errors
    },
    onSubmit: (formValues) => {
      if (buyerChecked) formValues = { ...formValues, buyerRequired: true }
      if (isShippingOptionsComplete(shippingOptions) && shippingOptions?.insurance) dispatch(updateShippingOptions({ ...shippingOptions, insurance: { amount: formik.values.customsValue, currency: formik.values.currency } }))
      if (isInternationalOptionsComplete(internationalOptions)) dispatch(updateInternationalOptions({ ...internationalOptions, declaredValue: { amount: formik.values.customsValue, currency: formik.values.currency } }))
      dispatch(updateCommercialInvoice(formValues))
      if (buyerChecked && selectedBuyer?.id !== "newBuyer") {
        dispatch(updateBuyer(selectedBuyer))
      } else {
        dispatch(updateBuyer(undefined))
      }
      if (action === "continue") {
        if (buyerChecked) {
          wizardProps.goToNamedStep("buyer")
          return
        }
      }
      if (action === "saveAndClose") setShowRateRequestDrawer(false)
    },
  })

  useEffect(() => {
    if (buyerChecked) {
      if (selectedBuyer && selectedBuyer?.id !== "newBuyer") setSelectedBuyer(buyer)
      else setSelectedBuyer(newBuyer)
    }
  }, [buyerChecked])

  useEffect(() => {
    if (buyer) setSelectedBuyer(buyer)
    else setBuyerChecked(false)
  }, [buyer])

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  const getInvoiceItemsCustomsValue = () => {
    return formik.values.invoiceItems.reduce((total, invoiceItem) => {
      const invoiceQuantity = invoiceItem.quantity ? invoiceItem.quantity : 0
      const invoiceValue = invoiceItem.value ? invoiceItem.value : 0
      return total + invoiceQuantity * invoiceValue
    }, 0)
  }

  useEffect(() => {
    formik.setFieldValue("customsValue", getInvoiceItemsCustomsValue())
  }, [formik.values.invoiceItems])

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  const { values } = formik

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.invoiceItems.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent>
        <FormikProvider value={formik}>
          <FieldArray
            name="invoiceItems"
            render={(invoiceItems) => (
              <>
                <Card sx={(theme) => ({ mb: theme.utils.pxToThemeSpacing(SPACING) })}>
                  <CardContentNoPaddingBottom
                    sx={(theme) => ({
                      "&:last-child": {
                        pt: theme.spacing(1),
                        px: theme.spacing(1),
                        pb: theme.spacing(0),
                      },
                    })}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box component="div" flex={1}>
                        <Typography variant="bodyMedium400">{t("drawer.invoiceItems.fields.currency.commercialInvoiceCurrencyLabel", { ns: "rateRequest" })}</Typography>
                      </Box>
                      <Box component="div">
                        <TextField id="customsValue" variant="outlined" size="small" disabled sx={{ width: customTheme.utils.pxToRem(90) }} value={formik.values.customsValue.toFixed(2)} />
                      </Box>
                      <Box component="div" ml={customTheme.utils.pxToThemeSpacing(SPACING)}>
                        <FormControl fullWidth>
                          {currencies && (
                            <Select name="currency" onChange={formik.handleChange} value={formik.values.currency} size="small">
                              {currencies.map(({ code }, currencyIndex) => (
                                <MenuItem key={code.concat(currencyIndex)} value={code}>
                                  {code}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                    <Box component="div" mt={customTheme.utils.pxToThemeSpacing(SPACING)} mr={customTheme.utils.pxToThemeSpacing(0)}>
                      <FormControlLabel
                        control={<Checkbox checked={buyerChecked} onChange={(event, value) => setBuyerChecked(value)} />}
                        label={<Typography variant="bodyMedium400">{t("drawer.invoiceItems.fields.buyerCheckbox.label", { ns: "rateRequest" })}</Typography>}
                        size="small"
                        name="buyerChecked"
                      />
                      {buyerChecked && (
                        <Box component="div" mx={customTheme.spacing(4)} mt={customTheme.utils.pxToThemeSpacing(SPACING)}>
                          <Autocomplete
                            value={(buyerList && buyerList.find((element) => selectedBuyer?.id === element.id)) || null}
                            size="small"
                            disablePortal
                            onChange={(_, value) => {
                              setSelectedBuyer(value)
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id
                            }}
                            name="selectedBuyer"
                            onBlur={formik.handleBlur}
                            getOptionLabel={(option) => {
                              if (option.id === "newBuyer") option.companyName = t("drawer.invoiceItems.fields.buyer.newBuyer", { ns: "rateRequest" })
                              return option.companyName || option.personName
                            }}
                            renderOption={(renderOptionProps, option) => {
                              return (
                                <li {...renderOptionProps} key={option.id}>
                                  {`${option.companyName} ${option.personName}`}
                                </li>
                              )
                            }}
                            options={buyerList || []}
                            PaperComponent={AutoCompletePaper}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={selectedBuyer?.id === "newBuyer" ? t("drawer.invoiceItems.fields.buyer.helperText", { ns: "rateRequest" }) : ""}
                                label={t("drawer.invoiceItems.fields.buyer.label", { ns: "rateRequest" })}
                                sx={(theme) => ({ pb: theme.utils.pxToThemeSpacing(4) })}
                              />
                            )}
                            noOptionsText={t("drawer.invoiceItems.fields.buyer.noBuyer", { ns: "rateRequest" })}
                          />
                        </Box>
                      )}
                    </Box>
                  </CardContentNoPaddingBottom>
                </Card>
                <Box component="div">
                  {values.invoiceItems &&
                    values.invoiceItems.length > 0 &&
                    values.invoiceItems.map((invoiceItem, index) => (
                      <Box component="div" key={index.toString()}>
                        <Card sx={(theme) => ({ mb: theme.utils.pxToThemeSpacing(SPACING) })}>
                          <CardContentNoPaddingBottom sx={(theme) => ({ px: theme.spacing(1), pt: theme.spacing(0) })}>
                            <Box
                              component="div"
                              mb={customTheme.utils.pxToThemeSpacing(SPACING)}
                              sx={(theme) => ({
                                height: theme.utils.pxToRem(40),
                                borderRadius: theme.utils.pxToRem(4),
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              })}
                            >
                              <Typography variant="bodyMedium400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                                {`${t("drawer.invoiceItems.invoiceIndex.label", { ns: "rateRequest" })} - ${index + 1}`}
                              </Typography>
                              <Box component="div">
                                <IconButton color="primary" onClick={() => invoiceItems.remove(index)} sx={(theme) => ({ pr: theme.spacing(0) })}>
                                  <SoeDeleteOutlineIcon />
                                </IconButton>
                              </Box>
                            </Box>
                            <InvoiceForm formik={formik} index={index} />
                          </CardContentNoPaddingBottom>
                        </Card>
                      </Box>
                    ))}
                  <Button variant="outlined" size="medium" onClick={() => invoiceItems.push(emptyInvoice)} sx={(theme) => ({ mb: theme.spacing(4), textTransform: "none" })}>
                    {t("drawer.invoiceItems.addAnotherInvoice.label", { ns: "rateRequest" })}
                  </Button>
                </Box>
              </>
            )}
          />
        </FormikProvider>
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          buyerChecked
            ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.invoiceItems.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.invoiceItems.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
            : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.invoiceItems.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
        }
      />
    </SoeDrawerContainer>
  )
}
RateRequestCommercialInvoice.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestCommercialInvoice.defaultProps = {}
export default RateRequestCommercialInvoice
