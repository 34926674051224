import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"

const CustomTextField = styled(TextField, { label: "CustomTextField" })(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingRight: 0,
  },
  "& .MuiInputAdornment-root": {
    width: "60px",
    color: theme.palette.contentNeutralPrimaryReverse.main,
    paddingTop: "18px",
    paddingLeft: "3px",
    paddingBottom: "18px",
    paddingRight: 0,
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
}))

export default CustomTextField
