import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"

const StyledSortButton = styled(Button, { name: "SortButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
  color: theme.palette.primary.dark,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
    color: theme.palette.contentNeutralPrimary.main,
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "none",
  },
}))

export default StyledSortButton
