const roundToNextThirtyMinuteInterval = (date) => {
  const time = new Date(date)
  const minutes = time.getMinutes()
  const hours = time.getHours()

  // Calculate the minutes for the next 30-minute interval
  const nextMinutes = (Math.ceil(minutes / 30) * 30) % 60

  // If nextMinutes is 0, we need to increment the hour by 1
  const nextHours = (nextMinutes === 0) ? (hours + 1) % 24 : hours

  // If the hour rolls over to 00:00, increment the date
  if (nextHours === 0 && nextMinutes === 0) {
    time.setDate(time.getDate() + 1)
  }

  time.setHours(nextHours)
  time.setMinutes(nextMinutes)
  time.setSeconds(0)
  time.setMilliseconds(0)

  return time
}

module.exports = {
  roundToNextThirtyMinuteInterval
}