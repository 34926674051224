// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// Others
import DrawerAlert from "../../../../../drawer-alert"
import TransactionStatus, { TRANSACTION_STATUS_ENUM } from "../../../../../transaction-status"

import { clearDrawerAlert } from "../../../../../../reducers/drawerAlertReducer"
import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { useGetValidationQuery } from "./slice"

function PaymentValidation({ setShowPaymentDrawer, ...wizardProps }) {
  const { t } = useTranslation("payment")
  const dispatch = useDispatch()

  const { quoteInformation } = useSelector((state) => state.quotations)

  const { data: validation, isLoading, isError } = useGetValidationQuery(quoteInformation.rateResponseId)

  const handleCloseClick = () => {
    setShowPaymentDrawer(false)
  }

  useEffect(() => {
    dispatch(clearDrawerAlert())
  }, [])

  useEffect(() => {
    if (validation?.status === TRANSACTION_STATUS_ENUM.SUCCESS) wizardProps.nextStep()
  }, [validation])

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.paymentValidation.title.label")} setShowDrawer={setShowPaymentDrawer} />
      <SoeDrawerContent>
        <DrawerAlert />
        <TransactionStatus transaction={validation} title={t("drawer.paymentValidation.validating.label")} isError={isError} isLoading={isLoading} />
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={[
          {
            action: handleCloseClick,
            label: t("drawer.paymentValidation.actions.closeButton.label"),
            variant: "contained",
          },
        ]}
      />
    </SoeDrawerContainer>
  )
}

PaymentValidation.propTypes = {
  setShowPaymentDrawer: PropTypes.func.isRequired,
}

export default PaymentValidation
