// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"

function GeneralInfoNotificationCard({ handleGeneralInfoNotificationCardClick }) {
  const { t } = useTranslation("userProfile")

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return <UserProfileSummaryCard title={t("userProfile.generalInfoNotificationCard.title")} handleClick={handleGeneralInfoNotificationCardClick} summaryStatusProps={summaryStatusProps} />
}

GeneralInfoNotificationCard.propTypes = {
  handleGeneralInfoNotificationCardClick: PropTypes.func,
}

GeneralInfoNotificationCard.defaultProps = {
  handleGeneralInfoNotificationCardClick: () => {},
}

export default GeneralInfoNotificationCard
