import { createSlice } from "@reduxjs/toolkit"
import { confirmSignUp, forgotPassword, resendConfirmationCode, forgotPasswordSubmit, signIn, signUp } from "./authenticatorReducer"

const initialState = {
  alert: {
    showAlert: false,
    showCompanyExistsModal: false,
    severity: undefined,
    message: undefined,
  },
}
export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    createModalAlert: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "warning"
      state.alert.message = action.payload
    },
    clearModalAlert: (state) => {
      state.alert = {
        showAlert: false,
        severity: undefined,
        message: undefined,
      }
    },
  },
  extraReducers: {
    [signIn.rejected]: (state, action) => {
      if (action.error?.message !== "User is not confirmed.") {
        state.alert.showAlert = true
        state.alert.severity = "error"
        state.alert.message = action.error.message
      }
    },
    [signIn.pending]: (state, action) => {
      state.email = action.meta.arg.email
    },
    [signUp.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"

      if (action.error.message?.startsWith("PreSignUp failed with error ")) {
        state.alert.message = action.error.message?.replace("PreSignUp failed with error ", "")
        state.alert.showCompanyExistsModal = true
      } else {
        state.alert.message = action.error.message
      }
    },
    [confirmSignUp.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    [forgotPassword.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    [resendConfirmationCode.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    [forgotPasswordSubmit.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
  },
})

export const { clearModalAlert, createModalAlert } = modalSlice.actions

export default modalSlice.reducer
