import {
  isValidPhoneNumber as libPhoneNumberIsValid,
} from 'libphonenumber-js'

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const isValidPhoneNumber = (number) => {
  return libPhoneNumberIsValid(number, "CA")
}
