import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"

import { Skeleton, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useGetInvitationsByCompanyQuery } from "../../../../services/invitation/slice"

import InvitationItem from "./invitation-row"
import { MainCustomButton } from "../../../sign-up/components/company-exists/style"

import "./style.scss"

function ManageInvitations({ users, refetchUsers }) {
  const [inviteUserProgess, setInviteUserProgess] = useState("") // show_input

  const { t } = useTranslation("company")
  const currentUser = useSelector((state) => state.user)
  const { data: invitations, isFetching, refetch } = useGetInvitationsByCompanyQuery(currentUser.companyId)

  const headCells = [
    {
      id: "userName",
      disablePadding: false,
      label: t("headers.userName"),
    },
    {
      id: "userEmail",
      disablePadding: false,
      label: t("headers.userEmail"),
    },
    {
      id: "userRole",
      disablePadding: false,
      label: t("headers.userRole"),
    },
    {
      id: "status",
      disablePadding: false,
      label: t("headers.status"),
    },
    {
      id: "date",
      disablePadding: false,
      label: t("headers.date"),
    },
    {
      id: "invitation",
      disablePadding: false,
      label: t("headers.invitation"),
    },
  ]

  const sortByStatus = () => (a, b) => {
    const mapping = {
      requested: 1,
      sent: 2,
      accepted: 3,
      rejected: 4,
    }

    return mapping[a.status?.toLowerCase()] - mapping[b.status?.toLowerCase()]
  }

  return (
    <Box className="manage-invitation-table" style={{ marginBottom: "50px" }}>
      <TableContainer style={{ marginTop: 50 }}>
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: 600,
            fontSize: theme.utils.pxToRem(22),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            width: "35rem",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
            marginBottom: theme.utils.pxToRem(10),
            display: "inline",
            padding: "0px 5px",
          })}
        >
          {t("manageInvitations.title")}
        </Typography>
        <br />
        <MainCustomButton onClick={() => setInviteUserProgess("show_input")} variant="outlined" size="medium" style={{ marginBottom: "10px", marginTop: "10px", width: "auto" }}>
          <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
            {t("manageInvitations.buttonText")}
          </Typography>
        </MainCustomButton>
        <Table
          size="small"
          sx={(theme) => ({
            borderCollapse: "separate",
            borderSpacing: "0px 10px",
            backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
            padding: "20px 50px",
          })}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "white" }}>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? "none" : "normal"}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <>
                {Array(3)
                  .fill()
                  .map((_, index) => {
                    return (
                      <TableRow key={"".concat(index)}>
                        <TableCell colSpan={12}>
                          <Skeleton variant="rectangular" height={50} width="100%" />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </>
            ) : (
              <>
                {Array.isArray(invitations) &&
                  invitations
                    .slice()
                    .filter((invitation) => invitation.status !== "accepted" )
                    .sort(sortByStatus())
                    .map((invitation) => <InvitationItem key={invitation.id} invitation={invitation} refetchUsers={refetchUsers} refetchInvitations={refetch} users={users} companyName={currentUser.company?.companyName} />)}
                {inviteUserProgess && (
                  <InvitationItem
                    key="create request"
                    refetchInvitations={refetch}
                    invitations={invitations}
                    inviteUserProgess={inviteUserProgess}
                    refetchUsers={refetchUsers}
                    users={users}
                    companyName={currentUser.company?.companyName}
                    setInviteUserProgess={setInviteUserProgess}
                  />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

ManageInvitations.defaultProps = {
  users: [],
  refetchUsers: () => {},
}

ManageInvitations.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.arrayOf(PropTypes.object),
  refetchUsers: PropTypes.func,
}

export default ManageInvitations
