import Select from "@mui/material/Select"

import { styled } from "@mui/material/styles"

export const test = 0;

export const CustomSelectField = styled(Select)(({ theme }) => ({
  "&.MuiInputBase-root": {
    border: `solid 1px ${theme.palette.inexTertiary.main}`,
    borderRadius: "5px",
  },
  "& .MuiInputBase-input": {
    fontSize: theme.utils.pxToRem(20),
  },
  ".MuiSelect-select": {
    paddingTop: "8px",
    paddingBottom: "8px", 
  },
  "fieldset": {
    border: "none",
  }
}))

