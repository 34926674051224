import { Alert, Box, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { pxToRem } from "../../../../soe-theme/src/utils"

function NoCostResponse() {
  const { t } = useTranslation("quotations")
  return (
    <Box component="div" sx={{ marginLeft: pxToRem(8) }}>
      <Alert severity="info">
        <Typography component="p" variant="bodyMedium400">
          {t("noCostResponseDescription")}
        </Typography>
      </Alert>
    </Box>
  )
}

export default NoCostResponse
