// REACT
import React from "react"

// Others
import AppConfig from "../../../../utils/app-config"

import { isProd } from "../../../../utils/get-environment"

// Custom style
import { CustomChip, MuiWrapper } from "./style"
import "./style.scss"

function AppInfo({ ...muiBoxProps }) {
  return (
    <MuiWrapper component="div" {...muiBoxProps}>
      {!isProd() && <CustomChip label={`v${AppConfig.app.version}`} size="small" variant="outlined" sx={{ textTransform: "none" }} />}
      <CustomChip label={AppConfig.app.environment} size="small" variant="outlined" />
    </MuiWrapper>
  )
}

export default AppInfo
