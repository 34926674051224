import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import AppConfig from "../../utils/app-config"
import ImpersonateService from "../impersonate"

import { getJwtTokens } from "../../reducers/authenticatorReducer"
import { isDev } from "../../utils/get-environment"

const metaBaseQuery = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: AppConfig.restApi.apiUrl,
    prepareHeaders: async (headers) => {
      try {
        const tokens = await getJwtTokens()
        if (tokens) {
          headers.set("Authorization", `Bearer ${tokens.accessToken}`)
          headers.set("Identity", tokens.idToken)
          if (ImpersonateService.currentImpersonation()) headers.set("IdentityExt", ImpersonateService.currentImpersonation())
        }
        return headers
      } catch (err) {
        // @todo handle error
        if (isDev()) {
          // eslint-disable-next-line no-console
          console.log("[rest-api_createApi]", err)
        }
        return headers
      }
    },
  })(args, api, extraOptions)

  if (baseResult.error) {
    baseResult.error = {
      ...baseResult.error,
      target: extraOptions && extraOptions.target ? extraOptions.target : "global",
    }
  }

  return {
    ...baseResult,
  }
}

const restApiSlice = createApi({
  reducerPath: "restApi",
  baseQuery: metaBaseQuery,
  endpoints: () => ({}),
})

export default restApiSlice
