// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"

// Formik
import { useFormik } from "formik"

// Theme
import { getAddressFormat, isRequiredField } from "../../../../../../services/address-form"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../services/address-form/slice"
import FullAddressForm from "../../../../../full-address-form"
import { useEditUserProfileMutation } from "../../../../../../services/user/slice"

function GeneralInfoContactDrawer({ onSubmitEvent, setShowGeneralInfoContactDrawer }) {
  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()
  const currentUser = useSelector((state) => state.user)
  const [editUserProfile] = useEditUserProfileMutation()

  const { t } = useTranslation("userProfile")

  const clientAddress = currentUser?.company?.clientAddress ? currentUser.company?.clientAddress : currentUser?.clientAddress
  const formik = useFormik({
    initialValues: {
      companyName: clientAddress?.companyName || "",
      personName: clientAddress?.personName || "",
      addressLine1: clientAddress?.addressLine1 || "",
      addressLine2: clientAddress?.addressLine2 || "",
      city: clientAddress?.city || "",
      provinceName: clientAddress?.provinceName || "",
      provinceCode: clientAddress?.provinceCode || "",
      countryCode: clientAddress?.countryCode || "CA",
      postalCode: clientAddress?.postalCode || "",
      phoneNumber: clientAddress?.phoneNumber || "",
    },
    validate: (formValues) => {
      const errors = {}
      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.personName) {
        errors.personName = t("fields.personName.error.required")
      }

      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      editUserProfile({
        address: formValues,
      })
      formik.resetForm()
      setShowGeneralInfoContactDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <FullAddressForm formik={formik} displaySearchAddressBar displayEmailAddressField={false} displaySaveAddressCheckbox={false} displaySaveDefaultAddressCheckbox={false} displayResidentialToggle={false} />
    </Box>
  )
}

GeneralInfoContactDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

GeneralInfoContactDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowGeneralInfoContactDrawer: PropTypes.func.isRequired,
}

export default GeneralInfoContactDrawer
