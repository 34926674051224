import PropTypes from "prop-types"
import React from "react"

import DateHeader from "../date-header"
import GroupedOrder from "../grouped-order"

function GroupedShipment({ groupedShipment, order, orderBy }) {
  return (
    <>
      <DateHeader shippingDate={groupedShipment.shippingDate} />
      {groupedShipment?.orders?.map((groupedShipmentsByAddress, groupedShipmentsByAddressIndex) => {
        return <GroupedOrder key={"".concat(groupedShipmentsByAddressIndex)} groupedShipmentsByAddress={groupedShipmentsByAddress} shippingDate={groupedShipment.shippingDate} order={order} orderBy={orderBy} />
      })}
    </>
  )
}

GroupedShipment.propTypes = {
  groupedShipment: PropTypes.shape({
    shippingDate: PropTypes.string,
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.shape({}),
        orders: PropTypes.arrayOf(
          PropTypes.shape({
            carrierCode: PropTypes.string,
            orders: PropTypes.arrayOf(PropTypes.shape({})),
          })
        ),
      })
    ),
  }).isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
}

export default GroupedShipment
