// REACT
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"

// Others
import LanguageSelector from "../../../../soe-theme/src/components/language-selector"

import { LANGUAGES } from "../../../../utils/constants"

function TermsAndConditions({ setShowTermsAndConditions }) {
  const { i18n, t } = useTranslation("accountConfiguration")
  const customTheme = useTheme()

  return (
    <Box className="termsAndConditions" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Card sx={(theme) => ({ height: theme.utils.pxToRem(768) })}>
          <CardHeader title={t("termsAndConditions.title")} />
          <Divider />
          <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(452) })}>
            <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
              <Box component="div" pt={customTheme.utils.pxToRem(4)}>
                <CardContentNoPaddingBottom sx={{ display: "block", overflowY: "auto", maxHeight: customTheme.utils.pxToRem(536) }}>
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    <Typography sx={(theme) => ({ fontFamily: theme.typography.fontFamily, fontSize: theme.typography.bodyMedium600.fontSize, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>{t("termsAndConditions.text")}</Typography>
                  </pre>
                </CardContentNoPaddingBottom>
              </Box>
              <Box component="div" pt={customTheme.utils.pxToRem(4)}>
                <CardContentNoPaddingBottom sx={{ display: "grid", justifyContent: "center" }}>
                  <Button variant="contained" onClick={() => setShowTermsAndConditions(false)} sx={{ textTransform: "none" }}>
                    {t("termsAndConditions.close")}
                  </Button>
                </CardContentNoPaddingBottom>
              </Box>
              <Box component="div" pt={customTheme.utils.pxToRem(4)}>
                <CardContentNoPaddingBottom sx={{ display: "grid", justifyContent: "center" }}>
                  <LanguageSelector i18n={i18n} languages={LANGUAGES} />
                </CardContentNoPaddingBottom>
              </Box>
            </CardContentNoPaddingBottom>
          </Box>
        </Card>
      </Grid>
    </Box>
  )
}

TermsAndConditions.propTypes = {
  setShowTermsAndConditions: PropTypes.func.isRequired,
}

export default TermsAndConditions
