// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AppConfig from "../../../../utils/app-config"
import EnhancedTableHead from "../../../enhanced-table-head"
import getComparator from "../../../../utils/sorting"
import CompletedShipment from "./components/completed-shipment"
import CustomPagination from "../../../custom-pagination"

import { useGetCompletedShipmentsQuery } from "../../../../services/order/slice"

function CompletedShipments({ dataRangeFilterValues }) {
  const { t, i18n } = useTranslation("shipments")
  const customTheme = useTheme()

  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("modifiedDate")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const { data: completedShipments, isFetching } = useGetCompletedShipmentsQuery({ from: dataRangeFilterValues?.from, to: dataRangeFilterValues?.to }, { skip: !dataRangeFilterValues })

  const headCells = [
    {
      id: `quotation.response.service.shortName.${i18n.language}`,
      align: "center",
      disablePadding: false,
      label: t("completedOrder.carrier.label"),
    },
    ...(AppConfig.features.showOrderTypeColumn ? [{ id: "type", align: "center", disablePadding: false, label: t("completedOrder.type.label") }] : [{ id: "type", hideSortIcon: true }]),
    {
      id: "number",
      align: "center",
      disablePadding: false,
      label: t("completedOrder.orderNumber.label"),
    },
    {
      id: "createdBy",
      disablePadding: false,
      label: t("completedOrder.createdBy.label"),
    },
    {
      id: "quotation.request.shipper.personName",
      disablePadding: false,
      label: t("completedOrder.shipper.label"),
    },
    {
      id: "quotation.request.recipient.personName",
      disablePadding: false,
      label: t("completedOrder.recipient.label"),
    },
    {
      id: "totalPackages",
      disablePadding: false,
      label: t("completedOrder.totalPackages.label"),
    },
    {
      id: "totalWeight",
      disablePadding: false,
      label: t("completedOrder.totalWeight.label"),
    },
    {
      id: "quotation.response.rate.totals.discounted.total.amount",
      disablePadding: false,
      label: t("completedOrder.totalPrice.label"),
    },
    {
      id: "modifiedDate",
      disablePadding: false,
      label: t("completedOrder.latestUpdate.label"),
    },
    {
      id: "invoiceStatus",
      disablePadding: false,
      label: t("completedOrder.invoiceStatus.label"),
    },
    {
      id: "transitStatus",
      disablePadding: false,
      label: t("completedOrder.carrierTracking.label"),
    },
    {
      id: "soeTracking",
      disablePadding: false,
      hideSortIcon: true,
    },
    {
      id: "menu",
      hideSortIcon: true,
    },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Box mx={customTheme.utils.pxToRem(14)} sx={{ width: "auto" }}>
      <Paper sx={{ width: "100%", flexDirection: "column" }}>
        <TableContainer>
          <Table size="small" sx={(theme) => ({ borderCollapse: "separate", borderSpacing: "0px 10px", backgroundColor: theme.palette.backgroundNeutralUnderground1.main })}>
            <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {isFetching ? (
                <>
                  {Array(3)
                    .fill()
                    .map((_, index) => {
                      return (
                        <TableRow key={"".concat(index)}>
                          <TableCell colSpan={12}>
                            <Skeleton variant="rectangular" height={50} width="100%" />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </>
              ) : (
                completedShipments &&
                completedShipments
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((completedShipment) => {
                    return <CompletedShipment key={completedShipment.id} completedShipment={completedShipment} />
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {completedShipments && <CustomPagination count={Math.ceil(completedShipments.length / rowsPerPage)} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />}
      </Paper>
    </Box>
  )
}

CompletedShipments.propTypes = {
  dataRangeFilterValues: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
}

CompletedShipments.defaultProps = {
  dataRangeFilterValues: undefined,
}

export default CompletedShipments
