// REACT
import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { LOCALES } from "../../utils/constants"

function CustomDatePicker(props) {
  const { error } = props
  const { i18n } = useTranslation(["constant", "quotations"])
  const customTheme = useTheme()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={LOCALES[i18n.resolvedLanguage]}>
      <Box component="div">
        <DatePicker
          PaperProps={{
            sx: {
              "&.MuiPaper-root": {
                ml: customTheme.utils.pxToRem(40),
              },
            },
          }}
          renderInput={(params) => <TextField variant="outlined" fullWidth size="small" {...params} error={error} />}
          {...props}
        />
      </Box>
    </LocalizationProvider>
  )
}

CustomDatePicker.defaultProps = {
  error: undefined,
}

CustomDatePicker.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
}

export default CustomDatePicker
