import restApiSlice from "../../services/rest-api/slice"

const rateRequestHistoryRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRateRequestHistory: builder.query({
      query: () => ({ url: "/raterequests/me", method: "GET" }),
      providesTags: ["RateRequestHistory"],
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetRateRequestHistoryQuery } = rateRequestHistoryRestApiSlice

export default rateRequestHistoryRestApiSlice.reducer
