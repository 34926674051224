import PropTypes from "prop-types"

// REACT
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

// MUI
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import ModalAlert from "../../../modal/components/modal-alert"
import NumericBoxInput from "../../../sign-up/components/confirm-sign-up/components/numeric-box-input"
import { SecondaryCustomButton, MainCustomButton, CustomTextField } from "./style"
import { ROUTES } from "../../../../utils/constants"

import { forgotPasswordSubmit } from "../../../../reducers/authenticatorReducer"

import passwordIcon from "../../../../assets/images/padlock.svg"
import passwordEyeIcon from "../../../../assets/images/password-eye.svg"

function ResetPasswordSubmitForm({ resetEmail }) {
  const customTheme = useTheme()
  const { t } = useTranslation("authenticator")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [passwordFieldType, setPasswordFieldType] = useState("password")
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] = useState("password")

  const handleEyeClick = (field, setField) => {
    if (field === "password") {
      setField("text")
    } else {
      setField("password")
    }
  }

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      verificationCode: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.newPassword) {
        errors.newPassword = t("forgotPasswordSubmit.fields.newPassword.error.required")
      }
      if (!formValues.confirmPassword) {
        errors.confirmPassword = t("forgotPasswordSubmit.fields.confirmNewPassword.error.required")
      }
      if (formValues.newPassword !== formValues.confirmPassword) {
        errors.confirmPassword = t("forgotPasswordSubmit.fields.confirmNewPassword.error.passwordMatch")
      }
      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(forgotPasswordSubmit({ ...formValues, email: resetEmail })).then((response) => {
        if (!response?.error) navigate(ROUTES.LOG_IN, { replace: true })
      })
    },
  })

  return (
    <Box className="signUp" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(460) })}>
          <ModalAlert />
          <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
            <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
              <Box>
                <NumericBoxInput id="verificationCode" onChange={formik.setFieldValue} fieldValue="verificationCode" value={formik.values.verificationCode} />
              </Box>
              <Typography sx={(theme) => ({ position: "initial", display: "flex", justifyContent: "center", marginTop: "25px", marginBottom: "25px", fontFamily: "Poppins", color: theme.palette.inexTertiary.main, fontSize: theme.utils.pxToRem(18) })}>{t("forgotPasswordSubmit.separator")}</Typography>
              <CustomTextField
                id="newPassword"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => handleEyeClick(passwordFieldType, setPasswordFieldType)}>
                      <img src={passwordEyeIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("forgotPasswordSubmit.fields.newPassword.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                error={formik.touched.newPassword && formik.errors.newPassword !== undefined}
                helperText={(formik.touched.newPassword && formik.errors.newPassword) || " "}
                fullWidth
                required
                type={passwordFieldType}
                autoComplete="off"
              />
              <CustomTextField
                id="confirmPassword"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => handleEyeClick(confirmPasswordFieldType, setConfirmPasswordFieldType)}>
                      <img src={passwordEyeIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("forgotPasswordSubmit.fields.confirmNewPassword.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={formik.touched.confirmPassword && formik.errors.confirmPassword !== undefined}
                helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || " "}
                fullWidth
                required
                type={confirmPasswordFieldType}
                autoComplete="off"
              />
              <CardContent sx={{ p: customTheme.utils.pxToRem(20) }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <MainCustomButton type="submit" variant="outlined" size="medium">
                  <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                    {t("forgotPasswordSubmit.fields.forgotPasswordSubmitButton.label")}
                  </Typography>
                </MainCustomButton>
                <Divider orientation="vertical" style={{ height: "0px", margin: `0 ${customTheme.utils.pxToRem(10)} 0 ${customTheme.utils.pxToRem(10)}` }} />
                <SecondaryCustomButton onClick={() => navigate(ROUTES.SIGN_UP, { replace: true })} variant="outlined" size="medium">
                  <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                    {t("forgotPasswordSubmit.backtoSignUp")}
                  </Typography>
                </SecondaryCustomButton>
              </Box>
              <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(16) })} />
            </form>
          </CardContentNoPaddingBottom>
        </Box>
      </Grid>
    </Box>
  )
}

ResetPasswordSubmitForm.propTypes = {
  resetEmail: PropTypes.string.isRequired,
}

export default ResetPasswordSubmitForm
