// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

// Components
import UserProfileSummaryCard from "../../../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../../../user-profile-summary-card/components/summary-status"
import SoeDeleteOutlineIcon from "../../../../../../../../soe-theme/src/icons/SoeDeleteOutlineIcon"
import SoeConfirmationDialog from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogContainer from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogHeader from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import SoeConfirmationDialogContent from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogActions from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"

function AccountCard({ account, index, setEditAccount, handleRemoveAccount }) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const { t } = useTranslation("userProfile")

  const capitalizeCarrierCode = (str) => {
    const sanitized = str.replace("-", " ")
    return sanitized.charAt(0).toUpperCase() + sanitized.slice(1)
  }

  const componentTitle = account.name ? `${account.name} - ${capitalizeCarrierCode(account.carrierCode)}` : capitalizeCarrierCode(account.carrierCode)
  return (
    <>
      <UserProfileSummaryCard key={index} title={componentTitle} handleClick={() => setEditAccount(account)} summaryStatusProps={{ variant: SummaryStatusVariantsEnum.success }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {account.thirdPartyShipperNumber}
          </Typography>
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {account.postalCode}
          </Typography>
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {account.countryCode}
          </Typography>
        </Box>
        <Button
          variant="text"
          endIcon={<SoeDeleteOutlineIcon />}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowConfirmationDialog(true)
          }}
        >
          {t("userProfile.thirdPartyBillingAccountsDrawer.actions.remove.label")}
        </Button>
      </UserProfileSummaryCard>

      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title="Remove Account Number"
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("userProfile.thirdPartyBillingAccountsDrawer.alerts.removeAccountNumber")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: () => setShowConfirmationDialog(false),
                label: t("userProfile.thirdPartyBillingAccountsDrawer.actions.close.label"),
                variant: "outlined",
              },
              {
                action: () => handleRemoveAccount(account.id),
                label: t("userProfile.thirdPartyBillingAccountsDrawer.actions.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
    </>
  )
}

AccountCard.propTypes = {
  index: PropTypes.number.isRequired,
  setEditAccount: PropTypes.func.isRequired,
  handleRemoveAccount: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    thirdPartyShipperNumber: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    carrierCode: PropTypes.string.isRequired,
  }).isRequired,
}

export default AccountCard
