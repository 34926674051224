import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

import PrintLabels from "../../../../../print-labels"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"

function PrintLabelsDrawer({ selectedShipment, showPrintLabelsDrawer, setShowPrintLabelsDrawer }) {
  const { t } = useTranslation("shipments")

  const handleClose = () => setShowPrintLabelsDrawer(false)

  return (
    <SoeDrawer showDrawer={showPrintLabelsDrawer} setShowDrawer={setShowPrintLabelsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("printLabelsDrawer.title")}
          setShowDrawer={() => {
            setShowPrintLabelsDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <PrintLabels orderId={selectedShipment.id} />
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleClose,
              label: t("printLabelsDrawer.actions.close"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

PrintLabelsDrawer.propTypes = {
  selectedShipment: PropTypes.instanceOf(Object).isRequired,
  showPrintLabelsDrawer: PropTypes.bool.isRequired,
  setShowPrintLabelsDrawer: PropTypes.func.isRequired,
}

export default PrintLabelsDrawer
