import PropTypes from "prop-types"
import React from "react"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

import DateTimeService from "../../../../../../services/date-time"

const convertStringToDate = (dateString) => {
  if (window.navigator?.userAgent?.includes("Safari") && !window.navigator?.userAgent?.includes("Chrome")) {
    return new Date(dateString.replace(/-/g, "/"))
  }
  return new Date(dateString)
}

function DateHeader({ shippingDate }) {
  return (
    <TableRow component="tr" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1.main })}>
      <TableCell align="left" colSpan={13} sx={{ fontSize: "20px" }}>
        {DateTimeService.toLocaleDateString(convertStringToDate(`${shippingDate} 00:00`))}
      </TableCell>
    </TableRow>
  )
}

DateHeader.propTypes = {
  shippingDate: PropTypes.string.isRequired,
}

export default DateHeader
