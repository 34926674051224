import restApiSlice from "../../services/rest-api/slice"

export const fullAddressFormRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    validateAddress: builder.mutation({
      query: (payload) => ({ url: `/addresses/validate`, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useValidateAddressMutation } = fullAddressFormRestApiSlice
