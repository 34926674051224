import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import DateTimeService from "../../../../../../../../services/date-time"
import RateRequestSummaryCard from "../../../../../../../rate-request-summary-card"

function PickupTimeCard({ pickup, isEditable }) {
  const { t } = useTranslation("shipments")
  const theme = useTheme()

  return (
    <RateRequestSummaryCard title={t("carrierHeader.pickupDetailsDrawer.pickupTimeCard.title")} handleClick={() => {}} showStatus={isEditable} isClickable={isEditable}>
      {pickup && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {pickup.readyTime && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("carrierHeader.pickupDetailsDrawer.pickupTimeCard.pickupDate")}: <b>{DateTimeService.toLocaleDateString(pickup.readyTime, pickup.timezone)}</b>
            </Typography>
          )}
          {pickup.readyTime && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("carrierHeader.pickupDetailsDrawer.pickupTimeCard.readyTime")}: <b>{DateTimeService.toLocaleTimeString(pickup.readyTime, pickup.timezone)}</b>
            </Typography>
          )}
          {pickup.untilTime && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("carrierHeader.pickupDetailsDrawer.pickupTimeCard.untilTime")}: <b>{DateTimeService.toLocaleTimeString(pickup.untilTime, pickup.timezone)}</b>
            </Typography>
          )}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

PickupTimeCard.propTypes = {
  pickup: PropTypes.instanceOf(Object).isRequired,
  isEditable: PropTypes.bool,
}

PickupTimeCard.defaultProps = {
  isEditable: false,
}

export default PickupTimeCard
