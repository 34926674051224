// REACT
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import CardContent from "@mui/material/CardContent"
import Link from "@mui/material/Link"

// Theme
import { useTheme } from "@mui/material/styles"

// Custom
import CardContentNoPaddingBottom from "../../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import CardContentNoPaddingTop from "../../../../../soe-theme/src/components/cardContent-no-padding-top"

import "../../../../../stories/blurred-backdrop.css"
import background from "../../../../../assets/images/confirm-dialog-background.svg"
import { MainCustomButton } from "../../../../sign-up/components/sign-up-form/style"

function AddCreditCardModal({ setIsAddCreditCardOpen, currentUser }) {
  const { t } = useTranslation("quotations")
  const customTheme = useTheme()
  const navigate = useNavigate()

  useEffect(() => {
    const backdrop = Array.from(document.querySelectorAll(".MuiModal-backdrop"))
    if (backdrop.length > 0) {
      const itemToAddBackdrop = backdrop.length > 1 ? backdrop[1] : backdrop[0]
      itemToAddBackdrop.classList.add("blurredBackdrop")
    }
  }, [currentUser])

  const handleConfirmToAddCreditCard = () => {
    navigate("/user-profile", { replace: true })
    setIsAddCreditCardOpen(false)
  }

  const handleCloseClick = () => {
    navigate("/", { replace: true })
    setIsAddCreditCardOpen(false)
  }

  return (
    <Box className="confirmSignUp" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <img src={background} alt="background-icon" style={{ preserveAspectRatio: "none", position: "absolute", top: "0px", right: "0px" }} />
      <Grid container direction="column" alignItems="center">
        <Card my={2} sx={{ overflowY: "auto", borderBottom: "12px solid #A7E6D7", width: customTheme.utils.pxToRem(800), minHeight: "25rem" }}>
          <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(770), marginTop: theme.utils.pxToRem(50) })}>
            <CardContentNoPaddingBottom sx={{ justifyContent: "center", padding: "0" }}>
              <Box component="div">
                <CardContentNoPaddingTop sx={{ display: "grid", justifyContent: "center" }}>
                  <Typography sx={(theme) => ({ marginBottom: theme.utils.pxToRem(28), color: theme.palette.inexTertiary.main, fontFamily: "Poppins", display: "flex", justifyContent: "center", fontSize: theme.utils.pxToRem(24), fontWeight: "600" })}>
                    {["ADMIN", "BILL"].includes(currentUser.companyRole) ? t("addCreditCardModal.headerAdmin") : t("addCreditCardModal.headerShip")}
                  </Typography>

                  <Typography style={{ marginLeft: 100, marginRight: 100, textAlign: "center" }} sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(20), color: theme.palette.inexTertiary.main })}>
                    {["ADMIN", "BILL"].includes(currentUser.companyRole) ? (
                      <>
                        {t("addCreditCardModal.descriptionAdmin1")} <br />
                        {t("addCreditCardModal.descriptionAdmin2")}
                      </>
                    ) : (
                      t("addCreditCardModal.descriptionShip")
                    )}
                    <br />
                    <br />
                  </Typography>
                </CardContentNoPaddingTop>
              </Box>

              <>
                {["ADMIN", "BILL"].includes(currentUser.companyRole) && (
                  <Box component="div">
                    <CardContent sx={{ display: "grid", justifyContent: "center", padding: "0" }}>
                      <MainCustomButton onClick={handleConfirmToAddCreditCard} sx={(theme) => ({ fontFamily: "Poppins", fontSize: theme.utils.pxToRem(18), display: "flex", borderRadius: "2px", textTransform: "uppercase" })}>
                        {t("addCreditCardModal.actions.update")}
                      </MainCustomButton>
                    </CardContent>
                  </Box>
                )}
                <Box component="div">
                  <CardContentNoPaddingBottom
                    sx={(theme) => ({ fontSize: theme.utils.pxToRem(18), width: "auto", padding: "0", marginBottom: theme.utils.pxToRem(50), display: "grid", justifyContent: "center", color: theme.palette.inexPrimary.main })}
                  >
                    <Link onClick={handleCloseClick} sx={(theme) => ({ color: theme.palette.inexPrimary.main })}>
                      {t("addCreditCardModal.actions.close")}
                    </Link>
                  </CardContentNoPaddingBottom>
                </Box>
              </>
            </CardContentNoPaddingBottom>
          </Box>
        </Card>
      </Grid>
    </Box>
  )
}

AddCreditCardModal.propTypes = {
  setIsAddCreditCardOpen: PropTypes.func,
  currentUser: PropTypes.shape({
    companyRole: PropTypes.string,
  }),
}

AddCreditCardModal.defaultProps = {
  setIsAddCreditCardOpen: () => {},
  currentUser: {},
}

export default AddCreditCardModal
