import React from "react"
import PropTypes from "prop-types"

function ClientAutocompleteOption({ renderOptionProps, client }) {
  const clientAddress = client?.company?.companyName ? client.company.clientAddress : client?.clientAddress
  return (
    <div {...renderOptionProps}>
      <div>
        <span style={{ fontWeight: "bold" }}> {clientAddress?.companyName}</span>
        <div>{client?.clientAddress?.personName}</div>
        <div>{client?.emailAddress}</div>
      </div>
    </div>
  )
}

ClientAutocompleteOption.defaultProps = {
  client: undefined,
}

ClientAutocompleteOption.propTypes = {
  renderOptionProps: PropTypes.shape({
    "aria-disabled": PropTypes.bool,
    "aria-selected": PropTypes.bool,
    className: PropTypes.string,
    "data-option-index": PropTypes.number,
    id: PropTypes.string,
    key: PropTypes.string,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onTouchStart: PropTypes.func,
    role: PropTypes.string,
    tabIndex: PropTypes.number,
  }).isRequired,
  client: PropTypes.instanceOf(Object),
}

export default ClientAutocompleteOption
