// REACT
import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

// Mui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import SignUpForm from "./components/log-in-form"

import { ReactComponent as LeftBackground } from "../../assets/images/sign-up-left-backgraound.svg"

import "./style.scss"

function Login() {
  const { t } = useTranslation("authenticator")
  const location = useLocation()

  return (
    <Box flexDirection="column" sx={(theme) => ({ overflow: "hidden", backgroundColor: theme.palette.backgroundNeutralUnderground1 })}>
      {location?.state?.fromSignUp && (
        <Box className="notification" sx={(theme) => ({ backgroundColor: theme.palette.inexSecondary.main, height: theme.utils.pxToRem(60) })}>
          <Typography
            className="primaryLabel"
            component="h4"
            pb={2}
            sx={(theme) => ({
              color: "white",
              fontSize: theme.utils.pxToRem(16),
              fontFamily: "Poppins",
              textAlign: "center",
              margin: "auto",
              height: "100%",
              padding: "0",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            })}
          >
            {t("inexSignIn.confirmNotification")}
          </Typography>
        </Box>
      )}
      <Box className="left-background" style={{ zIndex: "-2", preserveAspectRatio: "none" }}>
        <LeftBackground />
      </Box>
      <Box className="right-background" style={{ zIndex: "-2", preserveAspectRatio: "none" }}>
        <LeftBackground />
      </Box>
      <div style={{ background: "url('../../assets/images/sign-up-left-backgraound.svg')" }} />
      <Typography
        className="primaryLabel"
        component="h2"
        pb={2}
        sx={(theme) => ({
          color: theme.palette.inexTertiary.main,
          fontWeight: 900,
          fontSize: theme.utils.pxToRem(50),
          lineHeight: theme.utils.pxToLineHeight(44, 48),
          fontFamily: "Poppins",
          width: "max-content",
          paddingLeft: "10px",
          paddingRight: "10px",
          textAlign: "center",
          margin: "auto",
          marginTop: theme.utils.pxToRem(70),
          background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, #A7E6D7 50%, #A7E6D7 70%, rgba(255,255,0,0) 70%, rgba(255,255,0,0) 100%)",
        })}
      >
        {t("inexSignIn.title")}
      </Typography>

      <SignUpForm />
    </Box>
  )
}

export default Login
