import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import TextField from "@mui/material/TextField"

import { useFormik } from "formik"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AppConfig from "../../../../../../utils/app-config"
import AutoCompletePaper from "../../../../../../soe-theme/src/components/auto-complete-paper"
import CustomDatePicker from "../../../../../date-picker"
import TranslationService from "../../../../../../services/translation"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { useGetCarriersQuery } from "../../../../../../services/carriers/slice"

function ManifestSearchDrawer({ showManifestSearchDrawer, setShowManifestSearchDrawer }) {
  const { i18n, t } = useTranslation("shipments")
  const customTheme = useTheme()

  const currentUser = useSelector((state) => state.user)

  const { data: carriers } = useGetCarriersQuery()

  const handleClose = () => {
    setShowManifestSearchDrawer(false)
  }

  const formik = useFormik({
    initialValues: {
      carrier: carriers?.[0],
      shippingDate: useMemo(() => new Date(), []),
    },
    validate: () => {},
    validateOnChange: true,
    enableReinitialize: true,
  })

  const handleSubmit = () => {
    window.open(`${AppConfig.restApi.apiUrl}/orders/manifests/print?carrierCode=${formik.values.carrier.code}&shippingDate=${formik.values.shippingDate.toISOString().split("T")[0]}&companyId=${currentUser.companyId}`, "_blank")
    setShowManifestSearchDrawer(false)
  }

  return (
    <SoeDrawer showDrawer={showManifestSearchDrawer} setShowDrawer={setShowManifestSearchDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("manifestSearchDrawer.title")}
          setShowDrawer={() => {
            setShowManifestSearchDrawer(false)
          }}
        />
        <SoeDrawerContent>
          {carriers && (
            <form noValidate>
              <Box component="div" my={customTheme.utils.pxToRem(10)}>
                <Autocomplete
                  id="carrier"
                  name="carrier"
                  options={carriers}
                  getOptionLabel={(option) => {
                    return TranslationService.getLabelFromLocale(i18n.language, option?.fullName) || ""
                  }}
                  PaperComponent={AutoCompletePaper}
                  renderInput={(params) => <TextField {...params} variant="outlined" label={t("manifestSearchDrawer.fields.carrier.label")} />}
                  isOptionEqualToValue={(option, value) => {
                    return option.code === value.code
                  }}
                  onBlur={formik.handleBlur}
                  onChange={(_, value) => {
                    formik.setFieldValue("carrier", value)
                  }}
                  noOptionsText={t("manifestSearchDrawer.fields.noOptionsText.label")}
                  value={formik.values.carrier || ""}
                  fullWidth
                  size="small"
                  clearIcon={false}
                />
              </Box>
              <Box component="div" my={customTheme.utils.pxToRem(10)}>
                <CustomDatePicker
                  name="shippingDate"
                  label={t("manifestSearchDrawer.fields.shippingDate.label")}
                  value={formik.values.shippingDate}
                  onChange={(value) => {
                    formik.setFieldValue("shippingDate", value)
                  }}
                  error={formik.touched.shippingDate && formik.errors.shippingDate !== undefined}
                />
              </Box>
            </form>
          )}
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleClose,
              label: t("manifestSearchDrawer.actions.cancel"),
              variant: "outlined",
            },
            {
              action: handleSubmit,
              label: t("manifestSearchDrawer.actions.confirm"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

ManifestSearchDrawer.propTypes = {
  showManifestSearchDrawer: PropTypes.bool.isRequired,
  setShowManifestSearchDrawer: PropTypes.func.isRequired,
}

export default ManifestSearchDrawer
