import React, { useState } from "react"
import PropTypes from "prop-types"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"

function MoreVertIconMenu({ optionsList }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event) => {
    optionsList[event.target.value].handleClick()
    event.stopPropagation()
    handleClose(event)
  }

  return (
    <span>
      <IconButton onClick={handleClick}>
        <MoreVertIcon color="black" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "auto",
            width: "auto",
            position: "absolute",
          },
        }}
      >
        {optionsList.map((option, index) => (
          <MenuItem key={option.label} value={index} onClick={handleMenuItemClick}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </span>
  )
}
MoreVertIconMenu.propTypes = {
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      handleClick: PropTypes.func,
    })
  ).isRequired,
}

export default MoreVertIconMenu
