// REACT
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"

function MonetaryInfoBillingContactCard({ handleMonetaryInfoBillingContactCardClick }) {
  const { t } = useTranslation("userProfile")

  const currentUser = useSelector((state) => state.user)

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <UserProfileSummaryCard title={t("userProfile.monetaryBillingContactCard.title")} handleClick={handleMonetaryInfoBillingContactCardClick} summaryStatusProps={summaryStatusProps}>
      {currentUser?.billing?.address && Object.keys(currentUser?.billing?.address).length > 0 && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {currentUser?.billing?.address?.companyName && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {currentUser?.billing?.address?.companyName}
            </Typography>
          )}
          {currentUser?.billing?.address?.personName && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {currentUser?.billing?.address?.personName}
            </Typography>
          )}
          {currentUser?.billing?.address?.addressLine1 && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {currentUser?.billing?.address?.addressLine1}
            </Typography>
          )}
          {currentUser?.billing?.address?.addressLine2 && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {currentUser?.billing?.address?.addressLine2}
            </Typography>
          )}
          {(currentUser?.billing?.address?.city || currentUser?.billing?.address?.provinceCode) && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {`${currentUser?.billing?.address?.city || ""}${currentUser?.billing?.address?.city && currentUser?.billing?.address?.provinceCode ? ", " : ""}${currentUser?.billing?.address?.provinceCode || ""}${
                (currentUser?.billing?.address?.city || currentUser?.billing?.address?.provinceCode) && currentUser?.billing?.address?.countryCode ? ", " : ""
              }${currentUser?.billing?.address?.countryCode || ""}`}
            </Typography>
          )}
          {currentUser?.billing?.address?.postalCode && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {currentUser?.billing?.address?.postalCode}
            </Typography>
          )}
          {currentUser?.billing?.address?.phoneNumber && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {currentUser?.billing?.address?.phoneNumber}
            </Typography>
          )}
          {currentUser?.billing?.address?.emailAddress && (
            <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
              {currentUser?.billing?.address?.emailAddress}
            </Typography>
          )}
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

MonetaryInfoBillingContactCard.propTypes = {
  handleMonetaryInfoBillingContactCardClick: PropTypes.func,
}

MonetaryInfoBillingContactCard.defaultProps = {
  handleMonetaryInfoBillingContactCardClick: () => {},
}

export default MonetaryInfoBillingContactCard
