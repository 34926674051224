import isEmpty from "lodash/isEmpty"
import UnitsConversionService, { IMPERIAL, METRIC } from "../../services/units-conversion"

import { totalWeight } from "../../utils/packages"

export const isShipperComplete = (shipper) => {
  if (shipper) return true
  return false
}

export const isRecipientComplete = (recipient) => {
  if (recipient) return true
  return false
}

export const isPackagesComplete = (packages) => {
  if (packages?.pieces?.length > 0) return true
  return false
}

export const isShippingOptionsComplete = (shippingOptions) => {
  if (shippingOptions) return true
  return false
}

export const isBillingOptionsComplete = (billingOptions) => {
  if (billingOptions) return true
  return false
}

export const isFreightOptionsComplete = (freightOptions) => {
  if (freightOptions) return true
  return false
}

export const isInternationalOptionsComplete = (internationalOptions) => {
  if (internationalOptions && !isEmpty(internationalOptions)) return true
  return false
}

export const isBrokerRequired = (broker) => {
  if (broker) return true
  return false
}

export const isBrokerComplete = (broker) => {
  if (broker) return true
  return false
}

export const isBuyerRequired = (buyer) => {
  if (buyer) return true
  return false
}

export const isBuyerComplete = (buyer) => {
  if (buyer && !isEmpty(buyer)) return true
  return false
}

export const isCommercialInvoiceRequired = (commercialInvoice) => {
  if (commercialInvoice) return true
  return false
}

export const isCommercialInvoiceComplete = (commercialInvoice) => {
  if (commercialInvoice?.invoiceItems?.length > 0) return true
  return false
}

export const isFreightOptionsRequired = (packages) => {
  if (packages?.pieces?.length > 0) {
    return packages.pieces.some((piece) => {
      return (
        (packages.unitOfMeasure === IMPERIAL ? piece.length >= 40 : UnitsConversionService.convertDimension(piece.length, METRIC, IMPERIAL) >= 40) ||
        (packages.unitOfMeasure === IMPERIAL ? piece.height >= 40 : UnitsConversionService.convertDimension(piece.height, METRIC, IMPERIAL) >= 40) ||
        (packages.unitOfMeasure === IMPERIAL ? piece.width >= 40 : UnitsConversionService.convertDimension(piece.width, METRIC, IMPERIAL) >= 40) ||
        (packages.unitOfMeasure === IMPERIAL ? piece.weight >= 100 : UnitsConversionService.convertWeight(piece.weight, METRIC, IMPERIAL) >= 100)
      )
    })
  }
  return false
}

export const isInternationalShipment = (shipper, recipient) => {
  return !!(shipper && recipient && shipper.countryCode !== recipient.countryCode)
}

export const getFreightOptionsInitialValues = (freightOptions) => {
  return {
    tailgate: freightOptions?.tailgate || undefined,
    appointment: freightOptions?.appointment || undefined,
    twoPersons: freightOptions?.twoPersons || undefined,
    appointmentDate: freightOptions?.prebookedAppointment?.appointmentDate || undefined,
    appointmentTime: freightOptions?.prebookedAppointment?.appointmentTime || undefined,
    afterHours: freightOptions?.afterHours || undefined,
  }
}

export const isWeightsMatching = (packages, commercialInvoice) => {
  if (!packages?.pieces || !commercialInvoice?.invoiceItems) return true
  return totalWeight(packages.pieces) === totalWeight(commercialInvoice.invoiceItems)
}

// transform billingOptions object to thirdPartyAccount object
export const getThirdPartyBillingDetailsFromBillingForm = (billingOptions) => {
  return {
      carrierCode: billingOptions?.carrierCode || "",
      thirdPartyShipperNumber: billingOptions?.thirdPartyShipperNumber || "",
      name: billingOptions?.name || "",
      companyName: billingOptions?.companyName || "",
      personName: billingOptions?.personName || "",
      addressLine1: billingOptions?.addressLine1 || "",
      addressLine2: billingOptions?.addressLine2 || "",
      city: billingOptions?.city || "",
      provinceName: billingOptions?.provinceName || "",
      provinceCode: billingOptions?.provinceCode || "",
      countryCode: billingOptions?.countryCode || "CA",
      postalCode: billingOptions?.postalCode || "",
      phoneNumber: billingOptions?.phoneNumber || "",
      emailAddress: billingOptions?.emailAddress || "",
  }
}