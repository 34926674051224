import React from "react"
import ReactDOM from "react-dom"

import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import { Provider } from "react-redux"

import AppRoutes from "./routes"

import reportWebVitals from "./reportWebVitals"

import store from "./store"

import "./i18n"
import "./soe-theme/src/scss/_base.scss"

// theme
import ThemeConfig from "./soe-theme/src"

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <ThemeConfig>
      <BrowserRouter>
        <PersistGate loading={undefined} persistor={persistor}>
          <AppRoutes />
        </PersistGate>
      </BrowserRouter>
    </ThemeConfig>
  </Provider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
