// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

// Components
import UserProfileSummaryCard from "../../../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../../../user-profile-summary-card/components/summary-status"
import SoeDeleteOutlineIcon from "../../../../../../../../soe-theme/src/icons/SoeDeleteOutlineIcon"
import SoeConfirmationDialog from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogContainer from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogHeader from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import SoeConfirmationDialogContent from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogActions from "../../../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"

function BoxCard({ box, index, setEditBox, handleRemoveBox }) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const { t } = useTranslation("userProfile")

  const componentTitle = `${box.name} - ${box.height}x${box.width}x${box.length}`
  return (
    <>
      <UserProfileSummaryCard key={index} title={componentTitle} handleClick={() => setEditBox(box)} summaryStatusProps={{ variant: SummaryStatusVariantsEnum.success }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {box.weight} {box.isMetric ? "kg" : "lbs"}
          </Typography>
        </Box>
        <Button
          variant="text"
          endIcon={<SoeDeleteOutlineIcon />}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowConfirmationDialog(true)
          }}
        >
          {t("userProfile.boxDimensionDrawer.actions.remove.label")}
        </Button>
      </UserProfileSummaryCard>

      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title="Remove Box Dimension"
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("userProfile.boxDimensionDrawer.alerts.removeBoxDimension")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: () => setShowConfirmationDialog(false),
                label: t("userProfile.boxDimensionDrawer.actions.close.label"),
                variant: "outlined",
              },
              {
                action: () => handleRemoveBox(box.id),
                label: t("userProfile.boxDimensionDrawer.actions.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
    </>
  )
}

BoxCard.propTypes = {
  index: PropTypes.number.isRequired,
  setEditBox: PropTypes.func.isRequired,
  handleRemoveBox: PropTypes.func.isRequired,
  box: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    isMetric: PropTypes.bool.isRequired,
  }).isRequired,
}

export default BoxCard
