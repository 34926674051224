import { useTranslation } from "react-i18next"
import DateFnsTz from "date-fns-tz"

const DateTimeService = {
  newDateToDateString() {
    return new Date().toDateString()
  },
  toLocaleDateString(date, timezone) {
    const { i18n } = useTranslation()
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: timezone || undefined,
    }
    return new Date(date).toLocaleDateString(i18n.language, options)
  },
  toLocaleTimeString(date, timezone) {
    const { i18n } = useTranslation()
    const options = {
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
      timeZone: timezone || undefined,
    }
    return new Date(date).toLocaleTimeString(i18n.language, options)
  },
  getDatePartsInTimezone(date, timezone) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: timezone,
      hour12: false,
      timeZoneName: "short",
    }
    return Intl.DateTimeFormat("en-CA", options).formatToParts(new Date(date))
  },
  getTimezoneName(date, timezone) {
    const parts = this.getDatePartsInTimezone(date, timezone)
    return parts?.find((part) => part.type === "timeZoneName")?.value.replace(/−/g, "-")
  },
  getZonedEndOfDayToUtc(date, timezone) {
    let dateString
    if (typeof date === "string") {
      dateString = date
    } else {
      dateString = date.toLocaleDateString("sv-SE", { timeZone: timezone })
    }
    return DateFnsTz.zonedTimeToUtc(`${dateString.substring(0, 10)} 23:59:59`, timezone)
  },
  getZonedDateString(date, timezone) {
    if (typeof date === "string") {
      return date
    }
    return date.toLocaleDateString("sv-SE", { timeZone: timezone }).split(" ")[0]
  },
  addHours(date, hours) {
    const result = new Date(date)
    result.setHours(result.getHours() + hours)
    return result
  },
  toLocaleTimeStringWithoutOptions(date) {
    const { i18n } = useTranslation()
    return new Date(date).toLocaleTimeString(i18n.language)
  },
}

export default DateTimeService
