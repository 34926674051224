// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Others
import DrawerAlert from "../../../../../drawer-alert"

import { changePassword } from "../../../../../../reducers/paymentMethodReducer"
import { clearDrawerAlert } from "../../../../../../reducers/drawerAlertReducer"

function GeneralInfoPasswordDrawer({ onSubmitEvent, setShowGeneralInfoPasswordDrawer }) {
  const { t } = useTranslation("userProfile")

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.oldPassword) {
        errors.oldPassword = t("userProfile.generalInfoPasswordDrawer.changePassword.fields.oldPassword.error.required")
      }
      if (!formValues.newPassword) {
        errors.newPassword = t("userProfile.generalInfoPasswordDrawer.changePassword.fields.newPassword.error.required")
      }
      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      dispatch(changePassword(formValues)).then((response) => {
        if (!response.error) setShowGeneralInfoPasswordDrawer(false)
      })
    },
  })

  useEffect(() => {
    dispatch(clearDrawerAlert())
  }, [])

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <DrawerAlert />
        <Typography variant="bodyMedium600" pb={1} gutterBottom sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {t("userProfile.generalInfoPasswordDrawer.changePassword.fields.accountPassword.label")}
        </Typography>
        <Box component="div" py={2}>
          <TextField
            id="oldPassword"
            label={t("userProfile.generalInfoPasswordDrawer.changePassword.fields.oldPassword.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
            error={formik.touched.oldPassword && formik.errors.oldPassword !== undefined}
            helperText={(formik.touched.oldPassword && formik.errors.oldPassword) || " "}
            variant="outlined"
            size="small"
            type="password"
            required
          />
        </Box>
        <Box component="div">
          <TextField
            id="newPassword"
            label={t("userProfile.generalInfoPasswordDrawer.changePassword.fields.newPassword.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            error={formik.touched.newPassword && formik.errors.newPassword !== undefined}
            helperText={(formik.touched.newPassword && formik.errors.newPassword) || " "}
            variant="outlined"
            size="small"
            type="password"
            required
          />
        </Box>
      </form>
    </Box>
  )
}

GeneralInfoPasswordDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

GeneralInfoPasswordDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowGeneralInfoPasswordDrawer: PropTypes.func.isRequired,
}

export default GeneralInfoPasswordDrawer
