/* eslint-disable no-console */
/* eslint-disable  import/prefer-default-export */

import { isDev } from "../../../../../../../../utils/get-environment"

/**
 * *** FOR [Payment Element](https://stripe.com/docs/js/element/payment_element) ***
 *
 * Use `stripe.confirmPayment` to confirm a PaymentIntent using data collected by the [Payment Element](https://stripe.com/docs/js/element/payment_element).
 * When called, `stripe.confirmPayment` will attempt to complete any [required actions](https://stripe.com/docs/payments/intents), such as authenticating your user by displaying a 3DS dialog or redirecting them to a bank authorization page.
 * Your user will be redirected to the return_url you pass once the confirmation is complete.
 *
 * By default, stripe.`confirmPayment` will always redirect to your return_url after a successful confirmation.
 * If you set `redirect: "if_required"`, then `stripe.confirmPayment` will only redirect if your user chooses a redirect-based payment method.
 * Setting `if_required` requires that you handle successful confirmations for redirect-based and non-redirect based payment methods separately.
 *
 * Note: Setting if_required requires that you handle successful confirmations for redirect-based and non-redirect based payment methods separately.
 * When a non-redirect based payment method is successfully confirmed, stripe.confirmPayment will resolve with a {paymentIntent} object.
 *
 * @docs https://stripe.com/docs/js/payment_intents/confirm_payment
 */
export const handleStripeConfirmPayment = async ({ stripe, elements }) => {
  console.log("handleStripeConfirmPayment", { stripe, elements })
  const confirmParams = {
    return_url: `${window.location.origin}/shipments`, // @todo: share path in a const, add params for quotationId
    shipping: undefined,
    payment_method_data: undefined,
  }

  const { paymentIntent, error } = await stripe.confirmPayment({
    elements, // `Elements` instance that was used to create the Payment Element
    redirect: "if_required", // handle if no redirection. If redirection, we will have to load the correct wizard-step and open the Drawer before rendering
    confirmParams, // Parameters that will be passed on to the Stripe API to confirm the PaymentIntent.
  })

  // Handle the paymentIntent or error
  if (isDev() && paymentIntent) console.log("handleStripeConfirmPayment paymentIntent : ", paymentIntent)
  if (isDev() && error) console.log("handleStripeConfirmPayment error : ", error)

  return { paymentIntent, error }
}

export const handleStripeSetupPayment = async ({ stripe, elements }) => {
  console.log("handleStripeSetupPayment", { stripe, elements })
  const confirmParams = {
    return_url: `${window.location.origin}/user-profile`, // @todo: share path in a const, add params for quotationId
    shipping: undefined,
    payment_method_data: undefined,
  }

  const { paymentIntent, error } = await stripe.confirmSetup({
    elements, // `Elements` instance that was used to create the Payment Element
    redirect: "if_required", // handle if no redirection. If redirection, we will have to load the correct wizard-step and open the Drawer before rendering
    confirmParams, // Parameters that will be passed on to the Stripe API to confirm the PaymentIntent.
  })

  // Handle the paymentIntent or error
  if (isDev() && paymentIntent) console.log("handleStripeSetupPayment paymentIntent : ", paymentIntent)
  if (isDev() && error) console.log("handleStripeSetupPayment error : ", error)

  return { paymentIntent, error }
}
