// REACT
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

// Mui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CardContent from "@mui/material/CardContent"

// Custom
import { MainCustomButton } from "./style"

import leftBackground from "../../assets/images/home-page-left-background.png"
import rightBackground from "../../assets/images/home-page-right-background.png"
import planeBackground from "../../assets/images/plane-image.png"
import shipBackground from "../../assets/images/ship-image.png"

import { ROUTES } from "../../utils/constants"

function Home() {
  const { t } = useTranslation("home")

  const navigate = useNavigate()

  const handleNavigateQuotation = () => {
    navigate(ROUTES.QUOTATIONS)
  }

  const handleClickShipNow = () => {
    handleNavigateQuotation()
  }

  return (
    <Box flexDirection="column" sx={(theme) => ({ backgroundColor: theme.palette.backgroundNeutralUnderground1, marginTop: theme.utils.pxToRem(220), position: "relative" })}>
      <img src={leftBackground} alt="background-icon" style={{ zIndex: "-2", preserveAspectRatio: "none", position: "absolute", top: "0px", left: "0px" }} />
      <img src={rightBackground} alt="background-icon" style={{ zIndex: "-2", preserveAspectRatio: "none", position: "absolute", top: "-150px", right: "0px" }} />
      <img src={shipBackground} alt="background-icon" style={{ zIndex: "-1", preserveAspectRatio: "none", position: "absolute", top: "40px", left: "-100px" }} />
      <img src={planeBackground} alt="background-icon" style={{ zIndex: "-1", preserveAspectRatio: "none", position: "absolute", top: "40px", right: "-30px" }} />
      <Box
        className="primaryLabel"
        pb={2}
        sx={(theme) => ({
          width: "50rem",
          textAlign: "center",
          margin: "auto",
          marginTop: theme.utils.pxToRem(70),
        })}
      >
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: "700",
            fontSize: theme.utils.pxToRem(50),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
          })}
        >
          {t("inexHomeInformation.primaryTitle.label")}
        </Typography>
        <Typography
          className="primaryLabel"
          component="span"
          pb={2}
          sx={(theme) => ({
            color: theme.palette.inexTertiary.main,
            fontWeight: "700",
            fontSize: theme.utils.pxToRem(50),
            lineHeight: theme.utils.pxToLineHeight(44, 48),
            fontFamily: "Poppins",
            textAlign: "center",
            margin: "auto",
            marginTop: theme.utils.pxToRem(70),
            display: "inline",
            padding: "0 5px",
            background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, #A7E6D7 60%, #A7E6D7 80%, rgba(255,255,0,0) 80%, rgba(255,255,0,0) 100%)",
          })}
        >
          {t("inexHomeInformation.primaryTitle.underlined")}
        </Typography>
      </Box>
      <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(10) })} />
      <Typography
        className="secondaryLabel"
        component="h4"
        pb={2}
        sx={(theme) => ({
          color: theme.palette.inexTertiary.main,
          fontSize: theme.utils.pxToRem(24),
          lineHeight: theme.utils.pxToLineHeight(20, 24),
          justifyContent: "center",
          fontFamily: "Poppins",
          width: "35rem",
          textAlign: "center",
          margin: "auto",
        })}
      >
        {t("inexHomeInformation.secondaryTitle.label")}
      </Typography>
      <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(20) })} />
      <MainCustomButton onClick={() => handleClickShipNow()} variant="outlined" size="medium">
        <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
          {t("inexHomeInformation.newShipment.label")}
        </Typography>
      </MainCustomButton>
    </Box>
  )
}

export default Home
