// REACT
import { useTheme } from "@emotion/react"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"

function GeneralInfoContactCard({ handleGeneralInfoContactCardClick }) {
  const { t } = useTranslation("userProfile")
  const currentUser = useSelector((state) => state.user)
  const theme = useTheme()

  const clientAddress = currentUser?.company?.clientAddress ? currentUser.company?.clientAddress : currentUser?.clientAddress
  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <UserProfileSummaryCard title={t("userProfile.generalInfoContactCard.title.label")} handleClick={handleGeneralInfoContactCardClick} summaryStatusProps={summaryStatusProps}>
      {clientAddress && Object.keys(clientAddress).length > 0 && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {clientAddress?.companyName && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {clientAddress?.companyName}
            </Typography>
          )}
          {clientAddress?.personName && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {clientAddress?.personName}
            </Typography>
          )}
          {clientAddress?.addressLine1 && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {clientAddress?.addressLine1}
            </Typography>
          )}
          {clientAddress?.addressLine2 && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {clientAddress?.addressLine2}
            </Typography>
          )}
          {(clientAddress?.city || clientAddress?.provinceCode) && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {`${clientAddress?.city || ""}${clientAddress?.city && clientAddress?.provinceCode ? ", " : ""}${clientAddress?.provinceCode || ""}${(clientAddress?.city || clientAddress?.provinceCode) && clientAddress?.countryCode ? ", " : ""}${
                clientAddress?.countryCode || ""
              }`}
            </Typography>
          )}
          {clientAddress?.postalCode && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {clientAddress?.postalCode}
            </Typography>
          )}
          {clientAddress?.phoneNumber && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {clientAddress?.phoneNumber}
            </Typography>
          )}
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

GeneralInfoContactCard.propTypes = {
  handleGeneralInfoContactCardClick: PropTypes.func,
}

GeneralInfoContactCard.defaultProps = {
  handleGeneralInfoContactCardClick: () => {},
}

export default GeneralInfoContactCard
