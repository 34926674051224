import PropTypes from "prop-types"
import React from "react"
import SoeDrawerWizard from "../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-wizard"
import PaymentDetails from "./components/payment-details"
import PaymentMethod from "./components/payment-method"
import PaymentTransaction from "./components/payment-transaction"
import PaymentValidation from "./components/payment-validation"
import { PAYMENT_WIZARD_STEPS_ENUM } from "../../../../utils/constants"

function Payment({ setShowPaymentDrawer, setPaymentStepWizardInstance }) {
  return (
    <SoeDrawerWizard setStepWizardInstance={setPaymentStepWizardInstance} isLazyMount>
      <PaymentDetails stepName={PAYMENT_WIZARD_STEPS_ENUM.PAYMENT_DETAILS} setShowPaymentDrawer={setShowPaymentDrawer} />
      <PaymentValidation stepName={PAYMENT_WIZARD_STEPS_ENUM.PAYMENT_VALIDATION} setShowPaymentDrawer={setShowPaymentDrawer} />
      <PaymentMethod stepName={PAYMENT_WIZARD_STEPS_ENUM.PAYMENT_METHOD} setShowPaymentDrawer={setShowPaymentDrawer} />
      <PaymentTransaction stepName={PAYMENT_WIZARD_STEPS_ENUM.PAYMENT_TRANSACTION} setShowPaymentDrawer={setShowPaymentDrawer} />
    </SoeDrawerWizard>
  )
}

Payment.propTypes = {
  setShowPaymentDrawer: PropTypes.func.isRequired,
  setPaymentStepWizardInstance: PropTypes.func.isRequired,
}

export default Payment
