import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import React, { useEffect, useState } from "react"
import Skeleton from "@mui/material/Skeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import AddressBookDrawer from "./components/address-drawer"
import AddressBookItem from "./components/address-book-item"
import CustomPagination from "../custom-pagination"
import EnhancedTableHead from "../enhanced-table-head"
import getComparator from "../../utils/sorting"
import SearchInput from "../search-input"
import SoeConfirmationDialog from "../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogActions from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import SoeConfirmationDialogContainer from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogContent from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogHeader from "../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"

import { useDeleteAddressBookItemMutation, useGetAddressBookQuery } from "./slice"

function AddressBook() {
  const { t } = useTranslation("addressBook")
  const theme = useTheme()

  const { data: addressBookItems, isFetching } = useGetAddressBookQuery()
  const [deleteAddressBookItem, { isSuccess }] = useDeleteAddressBookItemMutation()

  const [showAddressBookDrawer, setShowAddressBookDrawer] = useState(false)
  const [selectedAddressBookItem, setSelectedAddressBookItem] = useState()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("personName")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [filteredAddressBook, setFilteredAddressBook] = useState()

  useEffect(() => {
    setFilteredAddressBook(addressBookItems)
  }, [addressBookItems])

  const headCells = [
    {
      id: "companyName",
      disablePadding: false,
      label: t("headers.companyName"),
    },
    {
      id: "personName",
      disablePadding: false,
      label: t("headers.personName"),
    },
    {
      id: "addressLine1",
      disablePadding: false,
      label: t("headers.addressLine1"),
    },
    {
      id: "addressLine2",
      disablePadding: false,
      label: t("headers.addressLine2"),
    },
    {
      id: "city",
      disablePadding: false,
      label: t("headers.city"),
    },
    {
      id: "provinceCode",
      disablePadding: false,
      label: t("headers.provinceCode"),
    },
    {
      id: "countryCode",
      disablePadding: false,
      label: t("headers.countryCode"),
    },
    {
      id: "postalCode",
      disablePadding: false,
      label: t("headers.postalCode"),
    },
    {
      id: "type",
      disablePadding: false,
      label: t("headers.type"),
    },
    {
      id: "menu",
    },
  ]

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleClose = () => {
    setShowConfirmationDialog(false)
  }

  useEffect(() => {
    if (isSuccess) setPage(1)
  }, [isSuccess])

  const handleConfirm = () => {
    setShowConfirmationDialog(false)
    deleteAddressBookItem(selectedAddressBookItem.id)
  }

  return (
    <>
      <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
        <SoeConfirmationDialogContainer>
          <SoeConfirmationDialogHeader
            title={t("confirmationDialog.title")}
            setShowConfirmationDialog={() => {
              setShowConfirmationDialog(false)
            }}
          />
          <SoeConfirmationDialogContent>
            <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
              {t("confirmationDialog.content.label")}
            </Typography>
          </SoeConfirmationDialogContent>
          <SoeConfirmationDialogActions
            buttons={[
              {
                action: handleClose,
                label: t("confirmationDialog.action.cancel.label"),
                variant: "outlined",
              },
              {
                action: handleConfirm,
                label: t("confirmationDialog.action.confirm.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeConfirmationDialogContainer>
      </SoeConfirmationDialog>
      <AddressBookDrawer selectedAddressBookItem={selectedAddressBookItem} showAddressBookDrawer={showAddressBookDrawer} setShowAddressBookDrawer={setShowAddressBookDrawer} />
      <Card variant="standard" square sx={{ height: "auto" }}>
        <CardHeader
          title={t("title")}
          action={
            <Button
              variant="contained"
              className="button-primary"
              color="primary"
              onClick={() => {
                setShowAddressBookDrawer(true)
                setSelectedAddressBookItem(undefined)
              }}
              sx={{ mt: theme.utils.pxToThemeSpacing(2), mr: theme.utils.pxToThemeSpacing(22) }}
            >
              {t("addButton")}
            </Button>
          }
          style={{ pl: theme.spacing(4), pb: theme.spacing(2) }}
        />
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box mt={theme.utils.pxToRem(20)} mb={theme.utils.pxToRem(20)} ml={theme.utils.pxToRem(16)}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              {addressBookItems && <SearchInput data={addressBookItems} setFilteredData={setFilteredAddressBook} />}
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card variant="standard" square sx={{ height: "auto" }}>
        <Box sx={{ width: "auto" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table size="small" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
                <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {isFetching ? (
                    <>
                      {Array(3)
                        .fill()
                        .map((_, index) => {
                          return (
                            <TableRow key={"".concat(index)}>
                              <TableCell colSpan={12}>
                                <Skeleton variant="rectangular" height={50} width="100%" />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </>
                  ) : (
                    filteredAddressBook &&
                    filteredAddressBook
                      .slice()
                      .sort(getComparator(order, orderBy))
                      .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                      .map((addressBookItem, index) => {
                        return (
                          <AddressBookItem
                            key={"".concat(index)}
                            addressBookItem={addressBookItem}
                            setShowAddressBookDrawer={setShowAddressBookDrawer}
                            setSelectedAddressBookItem={setSelectedAddressBookItem}
                            setShowConfirmationDialog={setShowConfirmationDialog}
                          />
                        )
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {filteredAddressBook && <CustomPagination count={Math.ceil(filteredAddressBook.length / rowsPerPage)} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} showFirstButton showLastButton />}
          </Paper>
        </Box>
      </Card>
    </>
  )
}

export default AddressBook
