// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

// Custom
import CardContentNoPaddingBottom from "../../soe-theme/src/components/cardContent-no-padding-bottom"

function TrackingNumberCard({ trackingNumber }) {
  const { t } = useTranslation("trackingNumberCard")

  return (
    <Card
      sx={(theme) => ({
        mb: theme.spacing(1),
      })}
    >
      <CardContentNoPaddingBottom
        sx={(theme) => ({
          "&:last-child": {
            pt: 0,
            my: theme.utils.pxToRem(12),
          },
        })}
      >
        <Grid container justifyContent="center" alignItems="center" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          <Grid item xs={3} textAlign="left">
            <Typography component="div" variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(12, 12) })}>
              {t("trackingNumber.part1.label")}
            </Typography>
            <Typography component="div" variant="bodySmall400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(12, 12) })}>
              {t("trackingNumber.part2.label")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div" variant="h6" display="flex" justifyContent="flex-start" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
              {trackingNumber}
            </Typography>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </CardContentNoPaddingBottom>
    </Card>
  )
}

TrackingNumberCard.propTypes = {
  trackingNumber: PropTypes.string.isRequired,
}

export default TrackingNumberCard
