// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

import { useEditUserProfileMutation } from "../../../../../../services/user/slice"
import { useGetCurrenciesQuery } from "../../../../../../reducers/quotationsReducer"

function MonteraryInfoBillingPref({ onSubmitEvent, setShowMonetaryInfoBillingPrefDrawer }) {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const currentUser = useSelector((state) => state.user)

  const [editUserProfile] = useEditUserProfileMutation()
  const { data: currencies } = useGetCurrenciesQuery()

  const formik = useFormik({
    initialValues: {
      currency: currentUser?.billing?.currency || "CAD",
    },
    validate: () => {},
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      editUserProfile({
        billing: { currency: formValues.currency },
      })
      formik.resetForm()
      setShowMonetaryInfoBillingPrefDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" my={customTheme.utils.pxToRem(10)}>
          {currencies && (
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="currencyInputLabel">{t("userProfile.monetaryBillingPrefInfoDrawer.fields.currency.label")}</InputLabel>
              <Select
                labelId="currencyLabel"
                id="currency"
                name="currency"
                label={t("userProfile.monetaryBillingPrefInfoDrawer.fields.currency.label")}
                onChange={formik.handleChange}
                value={formik.values.currency}
                SelectDisplayProps={{ style: { height: 0 } }}
              >
                {currencies
                  ?.filter((currency) => currency.code === "CAD" || currency.code === "USD")
                  .map((currency, index) => {
                    return (
                      // <MenuItem key={currency.code.concat(index)} value={currency.code}>
                      <MenuItem key={index.toString()} value={currency.code}>
                        {currency.code}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          )}
        </Box>
      </form>
    </Box>
  )
}

MonteraryInfoBillingPref.defaultProps = {
  onSubmitEvent: undefined,
}

MonteraryInfoBillingPref.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowMonetaryInfoBillingPrefDrawer: PropTypes.func.isRequired,
}

export default MonteraryInfoBillingPref
