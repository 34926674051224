import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

function Clock({ options }) {
  const { i18n } = useTranslation()
  const [currentDate, setCurrentDate] = useState()

  useEffect(() => {
    const secTimer = setInterval(() => {
      setCurrentDate(Intl.DateTimeFormat(i18n.language, options).format(new Date()))
    }, 1000)
    return () => clearInterval(secTimer)
  }, [options])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{currentDate}</>
}

Clock.defaultProps = {
  options: undefined,
}
Clock.propTypes = {
  options: PropTypes.shape({}),
}

export default Clock
