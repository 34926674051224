import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

import { useTranslation } from "react-i18next"

import AppConfig from "../../../../utils/app-config"
import Currency from "../../../currency"
import DateTimeService from "../../../../services/date-time"
import MoreMenu from "../../../more-menu"

function Invoice({ invoice }) {
  const { t } = useTranslation("invoices")

  const handlePrintClick = () => {
    window.open(`${AppConfig.restApi.apiUrl}/invoices/print/${invoice.id}`, "_blank")
  }

  const handleExportClick = () => {
    window.open(`${AppConfig.restApi.apiUrl}/invoices/export/${invoice.id}`, "_blank")
  }

  const sideMenuOptionList = [
    {
      label: t("sideMenuOptionList.print.label"),
      handleClick: handlePrintClick,
    },
    {
      label: t("sideMenuOptionList.export.label"),
      handleClick: handleExportClick,
    },
  ]

  return (
    <TableRow hover component="tr" sx={{ backgroundColor: "white" }}>
      <TableCell component="td" scope="row">
        <Box component="div">{invoice.invoiceNumber}</Box>
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">{DateTimeService.toLocaleDateString(invoice.createdDate)}</Box>
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">{DateTimeService.toLocaleDateString(invoice.dueDate)}</Box>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
          {invoice.summary.invoice.total && <Currency amount={invoice.summary.invoice.total} currency={invoice.currency} currencyPosition="right" showSymbol />}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
          {invoice.summary?.amountPaid !== 0 && <Currency amount={invoice.summary.amountPaid} currency={invoice.currency} currencyPosition="right" showSymbol />}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, fontSize: theme.utils.pxToRem(20) })}>
          {invoice.summary?.amountOutstanding !== 0 && <Currency amount={invoice.summary.amountOutstanding} currency={invoice.currency} currencyPosition="right" showSymbol />}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Box component="div">{invoice.paymentStatus && t(`paymentStatus.${invoice.paymentStatus}`)}</Box>
      </TableCell>
      <TableCell component="td" scope="row" align="right">
        <MoreMenu optionsList={sideMenuOptionList} />
      </TableCell>
    </TableRow>
  )
}

Invoice.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string,
    invoiceNumber: PropTypes.string,
    createdDate: PropTypes.string,
    dueDate: PropTypes.string,
    currency: PropTypes.string,
    summary: PropTypes.shape({
      invoice: PropTypes.shape({
        total: PropTypes.number,
      }),
      amountPaid: PropTypes.number,
      amountOutstanding: PropTypes.number,
    }),
    paymentStatus: PropTypes.string,
  }).isRequired,
}

export default Invoice
