import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Typography from "@mui/material/Typography"

import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import CarrierServiceLogo from "../../../../../carrier-service-logo"
import PickupAdditionalFieldsCard from "./components/pickup-additional-fields-card"
import PickupConfirmationNumberCard from "./components/pickup-confirmation-number-card"
import PickupTimeCard from "./components/pickup-time-card"
import restApiSlice from "../../../../../../services/rest-api/slice"
import SoeConfirmationDialog from "../../../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogContainer from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogHeader from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import SoeConfirmationDialogContent from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogActions from "../../../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import TransactionStatus, { TRANSACTION_STATUS_ENUM } from "../../../../../transaction-status"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { pxToThemeSpacing } from "../../../../../../soe-theme/src/utils"
import { useDeletePickupMutation } from "../schedule-pickup-drawer/slice"

function PickupDetailsDrawer({ carrier, pickup, showPickupDetailsDrawer, setShowPickupDetailsDrawer }) {
  const { t } = useTranslation("shipments")
  const dispatch = useDispatch()

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const [deletePickup, { data: deletePickupResponse, isLoading, isError }] = useDeletePickupMutation()

  const handleClose = () => {
    if (showTransactionStatus && deletePickupResponse?.status === TRANSACTION_STATUS_ENUM.SUCCESS) dispatch(restApiSlice.util.invalidateTags(["PendingShipments"]))
    setShowPickupDetailsDrawer(false)
  }

  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false)
  }

  const handleConfirmationDialogConfirm = () => {
    deletePickup(pickup.id)
    setShowTransactionStatus(true)
    setShowConfirmationDialog(false)
  }

  return (
    <SoeDrawer showDrawer={showPickupDetailsDrawer} setShowDrawer={setShowPickupDetailsDrawer} anchor="right" keepMounted={false} preventClosing={isLoading}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("carrierHeader.pickupDetailsDrawer.title")}
          setShowDrawer={() => {
            setShowPickupDetailsDrawer(false)
          }}
          preventClosing={isLoading}
        />
        <SoeDrawerContent drawerScrollToTop={showTransactionStatus}>
          {showTransactionStatus && <TransactionStatus transaction={deletePickupResponse} title={t("carrierHeader.pickupDetailsDrawer.cancel.label")} isError={isError} isLoading={isLoading} />}
          <Card>
            <CardContent
              sx={(theme) => ({
                "&:last-child": {
                  paddingBottom: theme.spacing(2),
                },
              })}
            >
              <CarrierServiceLogo code={carrier.code} logo={carrier.logo} align="left" />
            </CardContent>
          </Card>
          <Box mt={pxToThemeSpacing(10)}>
            <PickupConfirmationNumberCard confirmationNumber={pickup.confirmationNumber} />
          </Box>
          <Box mt={pxToThemeSpacing(10)}>
            <PickupTimeCard pickup={pickup} />
          </Box>
          <Box mt={pxToThemeSpacing(10)}>
            <PickupAdditionalFieldsCard pickup={pickup} />
          </Box>
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={
            !(deletePickupResponse?.status === TRANSACTION_STATUS_ENUM.SUCCESS)
              ? [
                  {
                    action: () => setShowConfirmationDialog(true),
                    label: t("carrierHeader.pickupDetailsDrawer.actions.delete.label"),
                    variant: "outlined",
                    color: "error",
                    loading: isLoading,
                  },
                  {
                    action: handleClose,
                    label: t("carrierHeader.pickupDetailsDrawer.actions.close.label"),
                    variant: "contained",
                    disabled: isLoading,
                  },
                ]
              : [
                  {
                    action: handleClose,
                    label: t("carrierHeader.pickupDetailsDrawer.actions.close.label"),
                    variant: "contained",
                    disabled: isLoading,
                  },
                ]
          }
        />
      </SoeDrawerContainer>
      {showConfirmationDialog && (
        <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
          <SoeConfirmationDialogContainer>
            <SoeConfirmationDialogHeader
              title={t("carrierHeader.pickupDetailsDrawer.confirmationDialog.title")}
              setShowConfirmationDialog={() => {
                setShowConfirmationDialog(false)
              }}
            />
            <SoeConfirmationDialogContent>
              <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
                {t("carrierHeader.pickupDetailsDrawer.confirmationDialog.content.label")}
              </Typography>
            </SoeConfirmationDialogContent>
            <SoeConfirmationDialogActions
              buttons={[
                {
                  action: handleConfirmationDialogClose,
                  label: t("carrierHeader.pickupDetailsDrawer.confirmationDialog.actions.cancel.label"),
                  variant: "outlined",
                },
                {
                  action: handleConfirmationDialogConfirm,
                  label: t("carrierHeader.pickupDetailsDrawer.confirmationDialog.actions.confirm.label"),
                  variant: "contained",
                },
              ]}
            />
          </SoeConfirmationDialogContainer>
        </SoeConfirmationDialog>
      )}
    </SoeDrawer>
  )
}

PickupDetailsDrawer.propTypes = {
  carrier: PropTypes.instanceOf(Object).isRequired,
  pickup: PropTypes.instanceOf(Object).isRequired,
  showPickupDetailsDrawer: PropTypes.bool.isRequired,
  setShowPickupDetailsDrawer: PropTypes.func.isRequired,
}

export default PickupDetailsDrawer
