const ImpersonateService = {
  impersonate(clientId) {
    window.sessionStorage.setItem("IdentityExt", clientId)
  },
  currentImpersonation() {
    return window.sessionStorage.getItem("IdentityExt")
  },
  unimpersonate() {
    window.sessionStorage.removeItem("IdentityExt")
  },
}

export default ImpersonateService
