// REACT
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

function AddressSummary({ addressSummary }) {
  return (
    <>
      <Box component="div">
        <Typography variant="bodySmall400" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {addressSummary?.companyName || addressSummary?.personName}
        </Typography>
      </Box>
      <Box component="div">
        <Typography variant="bodySmall400">{addressSummary?.personName || addressSummary?.companyName}</Typography>
      </Box>
      <Box component="div">
        <Typography variant="bodySmall400">{addressSummary?.addressLine1}</Typography>
      </Box>
      <Box component="div">
        <Typography variant="bodySmall400" gutterBottom>
          {`${addressSummary.city ? `${addressSummary.city}, ` : ""}${addressSummary.provinceCode ? `${addressSummary.provinceCode}, ` : addressSummary.provinceName ? `${addressSummary.provinceName}, ` : ""}${addressSummary.countryCode}`}
        </Typography>
      </Box>
    </>
  )
}
AddressSummary.propTypes = {
  addressSummary: PropTypes.shape({
    companyName: PropTypes.string,
    personName: PropTypes.string,
    addressLine1: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    provinceCode: PropTypes.string,
    provinceName: PropTypes.string,
    countryCode: PropTypes.string.isRequired,
  }).isRequired,
}

export default AddressSummary
