import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import AppConfig from "../../utils/app-config"

const metaBaseQuery = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: AppConfig.aws.cognito.hostedUiUrl,
  })(args, api, extraOptions)

  if (baseResult.error) {
    baseResult.error = {
      ...baseResult.error,
      target: extraOptions && extraOptions.target ? extraOptions.target : "global",
    }
  }

  return {
    ...baseResult,
  }
}

const authApiSlice = createApi({
  reducerPath: "authApi",
  baseQuery: metaBaseQuery,
  endpoints: () => ({}),
})

export default authApiSlice
