import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Chip from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "Quote", slot: "Root" })(() => ({}))

export const CustomChip = styled(Chip, { label: "CustomChip" })(({ theme }) => ({
  backgroundColor: theme.palette.contentAlertPositive.main,
  color: theme.palette.backgroundNeutralGround.main,
  textTransform: "capitalize",
  borderRadius: "8px",
  marginRight: theme.spacing(theme.utils.pxToThemeSpacing(8)),
  marginTop: theme.spacing(theme.utils.pxToThemeSpacing(6)),
}))

export const CustomCard = styled(Card, { name: "CustomCard", slot: "Root" })(({ theme, disabled }) => ({
  color: disabled && theme.palette.contentNeutralTertiary.main,
  backgroundColor: disabled && theme.palette.backgroundNeutralTransparent.main,
  cursor: disabled ? "not-allowed" : "pointer",
}))

export const CustomCardContent = styled(CardContent, { name: "CustomCardContent", slot: "Root" })(({ theme }) => ({
  padding: theme.spacing(2),
  "&:last-child": {
    padding: theme.spacing(2),
  },
}))
