// REACT
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

// Others
import AutoCompletePaper from "../../../../../../../../../../soe-theme/src/components/auto-complete-paper"
import TranslationService from "../../../../../../../../../../services/translation"

import { useGetCountriesQuery } from "../../../../../../../../../../services/address-form/slice"

function Country({ formik, index }) {
  const { i18n, t } = useTranslation(["country, rateRequest"])

  const { data: countries } = useGetCountriesQuery()

  return (
    <Box component="div">
      <Autocomplete
        id="countryOfManufacture"
        disablePortal
        value={countries?.find((country) => country?.code === formik.values?.invoiceItems[index]?.countryOfManufacture) || null}
        onChange={(_, value) => {
          formik.setFieldValue(`invoiceItems[${index}].countryOfManufacture`, value ? value.code : undefined)
        }}
        onBlur={formik.handleBlur}
        getOptionLabel={(option) => TranslationService.getLabelFromLocale(i18n.language, option.name)}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        options={countries || []}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.code}>
              {TranslationService.getLabelFromLocale(i18n.language, option.name)}
            </li>
          )
        }}
        PaperComponent={AutoCompletePaper}
        renderInput={(params) => <TextField {...params} label={t("drawer.invoiceItems.fields.countryOfManufacture.label", { ns: "rateRequest" })} variant="outlined" error={formik.touched.countryCode && formik.errors.countryCode !== undefined} />}
        noOptionsText={t("fields.country.noOptionsText", { ns: "country" })}
        disableClearable
        fullWidth
        size="small"
      />
    </Box>
  )
}

Country.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
}

export default Country
