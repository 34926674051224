// REACT
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Typography from "@mui/material/Typography"

import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

// Others
import { parseISO } from "date-fns"
import Clock from "../clock"
import CardContentNoPaddingBottom from "../../soe-theme/src/components/cardContent-no-padding-bottom"
import CustomDatePicker from "../date-picker"
import convertTZ from "../../utils/convert-timezone"
import DateTimeService from "../../services/date-time"
import CustomTimePicker from "./components/CustomTimePicker"
import { roundToNextThirtyMinuteInterval } from "./utils"

function TimeZoneDateTimeSelector({ shipperTimeZone, displayShippingDate, displayTimeZone, displayPickupDate, displayReadyTime, displayUntilTime, formik }) {
  const { t } = useTranslation("timezoneDatetimeSelector")

  const getCurrentTimeZone = () => {
    if (formik.values.timezone) return formik.values.timezone
    if (formik.values.shippingDateTimezone) return formik.values.shippingDateTimezone
    return Intl.DateTimeFormat().resolvedOptions()?.timeZone
  }

  const [clockOptions, setClockOptions] = useState({
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    timeZone: getCurrentTimeZone(),
  })

  useEffect(() => {
    setClockOptions({
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
      timeZone: getCurrentTimeZone(),
    })
  }, [getCurrentTimeZone()])

  const SPACING = 12

  return (
    <Box component="div">
      {displayTimeZone && (
        <Box component="div">
          <Card sx={(theme) => ({ mb: theme.spacing(2), pt: theme.utils.pxToRem(SPACING), pb: theme.spacing(0) })}>
            <CardContentNoPaddingBottom
              sx={{
                "&:last-child": {
                  pt: 0,
                },
              }}
            >
              <Box component="div">
                <Typography variant="bodyMedium400">{t("timezoneDatetimeSelector.pickUpTimeZoneCard.title")}</Typography>
                <RadioGroup
                  row
                  name={(formik.values.timezone && "timezone") || (formik.values.shippingDateTimezone && "shippingDateTimezone")}
                  value={formik.values.timezone || formik.values.shippingDateTimezone}
                  onChange={(event) => {
                    setClockOptions({ ...clockOptions, timeZone: event.target.value })
                    // Update multiple fields
                    formik.setValues({
                      ...formik.values,
                      ...(formik.values.timezone && { timezone: event.target.value }),
                      ...(formik.values.shippingDateTimezone && { shippingDateTimezone: event.target.value }),
                      ...(displayPickupDate && { pickupDate: new Date(convertTZ(new Date(), event.target.value)) }),
                      ...(displayReadyTime && { readyTime: roundToNextThirtyMinuteInterval(new Date(convertTZ(new Date(), event.target.value))) }),
                      ...(displayUntilTime && { untilTime: roundToNextThirtyMinuteInterval(new Date(convertTZ(DateTimeService.addHours(new Date(), 2), event.target.value))) }),
                      ...(displayShippingDate && { shippingDate: new Date(convertTZ(new Date(), event.target.value)) }),
                    })
                  }}
                  sx={(theme) => ({ pb: theme.utils.pxToRem(6) })}
                >
                  <FormControlLabel value={Intl.DateTimeFormat().resolvedOptions().timeZone} control={<Radio sx={(theme) => ({ py: theme.utils.pxToRem(6) })} />} label={t("timezoneDatetimeSelector.pickUpTimeZoneCard.yourTimeZone")} />
                  <FormControlLabel value={shipperTimeZone} control={<Radio sx={(theme) => ({ py: theme.utils.pxToRem(6) })} />} label={t("timezoneDatetimeSelector.pickUpTimeZoneCard.shipperTimeZone")} />
                </RadioGroup>
              </Box>
            </CardContentNoPaddingBottom>
          </Card>
          <Card sx={(theme) => ({ mb: theme.spacing(2), pt: theme.utils.pxToRem(SPACING), pb: theme.spacing(0) })}>
            <CardContentNoPaddingBottom
              sx={{
                "&:last-child": {
                  pt: 0,
                },
              }}
            >
              <Box component="div" sx={(theme) => ({ pb: theme.utils.pxToRem(SPACING) })}>
                <Box component="div" sx={(theme) => ({ pb: theme.utils.pxToRem(4) })}>
                  <Typography variant="bodyMedium400">{t("timezoneDatetimeSelector.clockCard.title")}</Typography>
                </Box>
                <Clock options={clockOptions} />
              </Box>
            </CardContentNoPaddingBottom>
          </Card>
        </Box>
      )}
      <Card sx={(theme) => ({ mb: theme.spacing(2), pt: theme.spacing(1), pb: theme.spacing(0) })}>
        <CardContentNoPaddingBottom
          sx={(theme) => ({
            "&:last-child": {
              pt: theme.utils.pxToRem(SPACING),
              pb: theme.spacing(2),
            },
          })}
        >
          <Grid container spacing={2}>
            {displayShippingDate && (
              <Grid item xs={12}>
                <CustomDatePicker
                  value={parseISO(formik.values.shippingDate)}
                  onChange={(value) => {
                    const shippingDate = new Date(value).toLocaleString("sv-SE")
                    formik.setFieldValue("shippingDate", shippingDate)
                  }}
                  error={formik.touched.shippingDate && formik.errors.shippingDate !== undefined}
                  label={t("timezoneDatetimeSelector.fields.shippingDate.label")}
                  name="shippingDate"
                  fullWidth
                />
              </Grid>
            )}
            {displayPickupDate && (
              <Grid item xs={12}>
                <CustomDatePicker
                  value={formik.values.pickupDate}
                  onChange={(value) => {
                    // formik.setFieldValue("pickupDate", value)
                    const { readyTime } = formik.values
                    readyTime.setFullYear(value.getFullYear())
                    readyTime.setMonth(value.getMonth())
                    readyTime.setDate(value.getDate())
                    // formik.setFieldValue("readyTime", readyTime)

                    const { untilTime } = formik.values
                    untilTime.setFullYear(value.getFullYear())
                    untilTime.setMonth(value.getMonth())
                    untilTime.setDate(value.getDate())
                    // formik.setFieldValue("untilTime", untilTime)
                    formik.setValues({
                      ...formik.values,
                      ...(formik.values.pickupDate && { pickupDate: value }),
                      ...(formik.values.readyTime && { readyTime }),
                      ...(formik.values.untilTime && { untilTime }),
                    })
                  }}
                  error={formik.touched.pickupDate && formik.errors.pickupDate !== undefined}
                  label={t("timezoneDatetimeSelector.fields.pickupDate.label")}
                  name="pickupDate"
                />
              </Grid>
            )}
            {displayReadyTime && (
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CustomTimePicker
                    value={formik.values.readyTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
                    label={t("timezoneDatetimeSelector.fields.readyTime.label")}
                    error={formik.errors.readyTime}
                    onChange={(value) => {
                      const { pickupDate } = formik.values
                      const readyTime = new Date(pickupDate)
                      const [hours, minutes] = value.split(":").map(Number)
                      readyTime.setHours(hours, minutes, 0, 0)

                      formik.setValues({
                        ...formik.values,
                        ...(formik.values.readyTime && { readyTime }),
                      })
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {displayUntilTime && (
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CustomTimePicker
                    value={formik.values.untilTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
                    label={t("timezoneDatetimeSelector.fields.untilTime.label")}
                    error={formik.errors.untilTime}
                    onChange={(value) => {
                      const { pickupDate } = formik.values
                      const untilTime = new Date(pickupDate)
                      const [hours, minutes] = value.split(":").map(Number)
                      untilTime.setHours(hours, minutes, 0, 0)
                      formik.setValues({
                        ...formik.values,
                        ...(formik.values.untilTime && { untilTime }),
                      })
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            )}
          </Grid>
        </CardContentNoPaddingBottom>
      </Card>
    </Box>
  )
}
TimeZoneDateTimeSelector.defaultProps = {
  displayPickupDate: false,
  displayReadyTime: false,
  displayUntilTime: false,
  displayTimeZone: false,
  displayShippingDate: false,
  shipperTimeZone: "",
}

TimeZoneDateTimeSelector.propTypes = {
  shipperTimeZone: PropTypes.string,
  displayPickupDate: PropTypes.bool,
  displayReadyTime: PropTypes.bool,
  displayUntilTime: PropTypes.bool,
  formik: PropTypes.instanceOf(Object).isRequired,
  displayTimeZone: PropTypes.bool,
  displayShippingDate: PropTypes.bool,
}

export default TimeZoneDateTimeSelector
