// REACT
import React, { useState } from "react"

// MUI
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

// Others
import DateFnsTz from "date-fns-tz"
import DateTimeService from "../../../../services/date-time"
import RateRequestDetailsDrawer from "./components/rate-request-details-drawer"
import RateRequestProps from "../../../../models/rate-request"
import AddressSummary from "../../../address-summary"

import { IMPERIAL, METRIC } from "../../../../services/units-conversion"
import { totalPackages, totalWeight } from "../../../../utils/packages"

function RateRequest({ rateRequest }) {
  const [showRateRequestDetailsDrawer, setShowRateRequestDetailsDrawer] = useState(false)

  const handleRateRequestClick = () => {
    setShowRateRequestDetailsDrawer(true)
  }

  return (
    <>
      <TableRow hover component="tr" onClick={handleRateRequestClick} sx={{ backgroundColor: "white" }}>
        <TableCell component="td" scope="row">
          <AddressSummary addressSummary={rateRequest?.shipper} />
        </TableCell>
        <TableCell component="td" scope="row">
          <AddressSummary addressSummary={rateRequest?.recipient} />
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">{totalPackages(rateRequest?.packages?.pieces)}</Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">
            {totalWeight(rateRequest?.packages?.pieces).toFixed(1)} {rateRequest?.packages?.unitOfMeasure === IMPERIAL && "lb"}
            {rateRequest?.packages?.unitOfMeasure === METRIC && "kg"}
          </Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">{DateTimeService.toLocaleDateString(rateRequest.createdDate)}</Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">{DateTimeService.toLocaleDateString(DateFnsTz.zonedTimeToUtc(`${rateRequest.shippingOptions.shippingDate}`, rateRequest.shippingOptions?.shippingDateTimezone))}</Box>
        </TableCell>
      </TableRow>
      {showRateRequestDetailsDrawer && <RateRequestDetailsDrawer selectedRateRequest={rateRequest} showRateRequestDetailsDrawer={showRateRequestDetailsDrawer} setShowRateRequestDetailsDrawer={setShowRateRequestDetailsDrawer} />}
    </>
  )
}

RateRequest.propTypes = {
  rateRequest: RateRequestProps.isRequired,
}

export default RateRequest
