import FormControlLabel from "@mui/material/FormControlLabel"
import PropTypes from "prop-types"
import Radio from "@mui/material/Radio"
import React from "react"

import SummaryCard from "../../../../../../../summary-card"
import CreditCard from "../credit-card"

function CreditCardList({ activePaymentMethods }) {
  return (
    <SummaryCard title="Use a saved payment method:" isClickable={false}>
      {activePaymentMethods.map((paymentMethod) => {
        return <FormControlLabel key={paymentMethod.id} value={paymentMethod?.id} control={<Radio />} label={<CreditCard paymentMethod={paymentMethod} />} />
      })}
    </SummaryCard>
  )
}

CreditCardList.propTypes = {
  activePaymentMethods: PropTypes.arrayOf(Object).isRequired,
}

export default CreditCardList
