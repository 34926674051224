import Typography from "@mui/material/Typography"
import React from "react"
import PropTypes from "prop-types"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

export const variants = {
  success: "success",
  warning: "warning",
}

function SummaryStatus({ variant, text, sx }) {
  const theme = useTheme()
  const { t } = useTranslation("quotations")

  if (!Object.values(variants).includes(variant)) throw new Error("Invalid variant")

  let color
  let tempText

  if (variant === variants.success) {
    color = theme.palette.contentAlertPositive.main
    tempText = text || t("completed")
  }
  if (variant === variants.warning) {
    color = theme.palette.contentAlertWarning.main
    tempText = text || t("toComplete")
  }
  return (
    <Typography variant="bodySmall600" sx={{ color, ...sx }}>
      {tempText}
    </Typography>
  )
}

SummaryStatus.propTypes = {
  variant: PropTypes.string.isRequired,
  text: PropTypes.string,
  sx: PropTypes.instanceOf(Object),
}

SummaryStatus.defaultProps = {
  text: undefined,
  sx: undefined,
}

export default SummaryStatus
