// REACT
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import Button from "@mui/material/Button"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import ConfirmModal from "../../../../../../../confirm-modal"

import { CustomDialogActions, CustomDialogContent, CustomLink } from "./style"

function TermsAndConditions() {
  const { t } = useTranslation("termsAndConditions")
  const customTheme = useTheme()

  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const modalContent = (
    <CustomDialogContent>
      <Typography variant="bodyMedium400">
        {t("termsAndConditions.contentText")
          .split("\n")
          .map((c, index) => (
            <p key={index.toString()}> {c} </p>
          ))}
      </Typography>
    </CustomDialogContent>
  )

  const modalActions = (
    <CustomDialogActions>
      <Button className="button cancelButton" onClick={() => handleClose()}>
        {t("termsAndConditions.closeButton.label")}
      </Button>
    </CustomDialogActions>
  )

  return (
    <Box component="div" mt={customTheme.spacing(1)}>
      <ConfirmModal open={open} handleClose={handleClose} title={t("termsAndConditions.title")} modalContent={modalContent} modalActions={modalActions} />
      <Alert icon={false} severity="info" sx={(theme) => ({ pl: theme.spacing(2), pr: theme.spacing(1) })}>
        <Typography component="p" variant="bodyMedium400">
          {t("termsAndConditions.termsAndConditionsDescription")}
          <CustomLink to="#" onClick={handleOpen}>
            <strong> {t("termsAndConditions.termsAndConditionsLink")}</strong>
          </CustomLink>
        </Typography>
      </Alert>
    </Box>
  )
}

export default TermsAndConditions
